import flatten from "lodash/flatten";
import flattenDeep from "lodash/flattenDeep";
import cloneDeep from "lodash/cloneDeep";

import { AppUtils } from "utils/AppUtils";
import { ServerClock } from "utils/ServerClock";

import {
  Data__SB_BetMarket,
  Data__SB_BetProposal,
  Data__SB_SportEvent,
  TBetMarket,
  TProposalsMap,
} from "server/legacyCore/data/objects";
import {
  betProposalLocalDiff,
  Data__SB_ShoppingCart,
  Data__SB_ShoppingCartProposalItem,
  Data__SB_SuperMarket,
  IBetMarketLocal,
  TCellRenderProposalLocal,
  TMainProposalLocal,
} from "server/legacyCore/data/objects_local";
import {
  PickType,
  TBetOrBetGroupStatus,
  TEventStatus,
  MarketChangesTimestamps,
  MarketOfferSelectionChangeType,
  BetProposalTypes,
} from "server/legacyCore/data/constants";

class ProposalUtils {
  public toMainLocalProposal(
    serverProposal: Data__SB_BetProposal,
  ): TMainProposalLocal {
    const copy: Data__SB_BetProposal = cloneDeep(serverProposal);
    betProposalLocalDiff.forEach(key => {
      delete copy[key];
    });

    return copy as TMainProposalLocal;
  }

  public toCellLocalProposal(
    serverProposal: Data__SB_BetProposal,
  ): TCellRenderProposalLocal {
    return {
      proposal_fkey: serverProposal.proposal_fkey,
      t_151_cell_text_1: serverProposal.t_151_cell_text_1,
      type: serverProposal.type,
      t_132_market_note: serverProposal.t_132_market_note,
      t_201_sponsor_name: serverProposal.t_201_sponsor_name,
      t_301_user_profile_fkey: serverProposal.t_301_user_profile_fkey,
      t_141_selection_name: serverProposal.t_141_selection_name,
      t_131_market_name: serverProposal.t_131_market_name,
      t_302_boosted_note: serverProposal.t_302_boosted_note,
      t_202_sponsor_logo_url: serverProposal.t_202_sponsor_logo_url,
      prev_coeff: serverProposal.prev_coeff,
    };
  }

  public toBetLocalMarkets(serverMarkets: TBetMarket[]): IBetMarketLocal[] {
    return serverMarkets.map(this.toBetLocalMarket);
  }

  public toBetLocalMarket = (serverMarket: TBetMarket): IBetMarketLocal => {
    const nextLocalBetMarket = cloneDeep(serverMarket) as IBetMarketLocal;

    if (serverMarket.deactivate_reason !== 0) {
      return nextLocalBetMarket;
    }
    serverMarket.groups.forEach((group, groupIndex) => {
      group.proposals.forEach((proposal, proposalIndex) => {
        nextLocalBetMarket.groups[groupIndex].proposals[proposalIndex] =
          this.toCellLocalProposal(proposal);
      });
    });

    return nextLocalBetMarket;
  };

  public getBetProposalByProposalFkey(
    proposalFkey: string | undefined,
    conflictFkey: string | undefined,
    proposalMap: TProposalsMap,
    betSupermarket: Data__SB_SuperMarket["shelves_by_conflict_fkey"],
  ): TMainProposalLocal | null {
    if (!conflictFkey || !proposalFkey) {
      return null;
    }
    const cellRenderProposal = this.getBetProposalByConflictFkey(
      conflictFkey,
      proposalFkey,
      betSupermarket,
    );
    if (!cellRenderProposal) {
      return null;
    }
    const mainBetProposal = proposalMap[proposalFkey];
    if (!mainBetProposal) {
      return null;
    }
    if (cellRenderProposal.proposal_fkey !== mainBetProposal.proposal_fkey) {
      return null;
    }
    return mainBetProposal;
  }

  public getBetProposalByConflictFkey(
    conflictFkey: string,
    proposalFkey: string,
    betSupermarket: Data__SB_SuperMarket["shelves_by_conflict_fkey"],
  ): TCellRenderProposalLocal | null | undefined {
    if (!(conflictFkey in betSupermarket)) {
      return null;
    }
    const markets = betSupermarket[conflictFkey].markets;
    const proposals = this.getProposalsFromMarkets(markets);

    return proposals.find(el => el.proposal_fkey === proposalFkey);
  }

  public areAllMarketsLoaded = (
    betSupermarket: Data__SB_SuperMarket["shelves_by_conflict_fkey"],
    event: Data__SB_SportEvent | null,
  ): boolean => {
    if (!event) {
      return false;
    }
    if (!betSupermarket[event.conflict_fkey]) {
      return false;
    }

    return !betSupermarket[event.conflict_fkey].markets.every(
      this.isMarketShowcase,
    );
  };

  public isProposalOTB = (
    proposalStatus: TBetOrBetGroupStatus | undefined,
    eventStatus: TEventStatus | undefined,
  ): boolean =>
    proposalStatus === TBetOrBetGroupStatus.CONST_748_SUSPENDED ||
    eventStatus === TEventStatus.CONST_748_SUSPENDED;

  public isSelectionUnavailable = (
    selection: Data__SB_ShoppingCartProposalItem,
    supermarketBetProposal: TMainProposalLocal | null,
    isBettingEventAvailable: boolean,
  ) =>
    !supermarketBetProposal ||
    !isBettingEventAvailable ||
    this.isProposalOTB(
      supermarketBetProposal.status,
      selection.event.live_status,
    );

  public detectChanges(
    cartItems: Data__SB_ShoppingCart["items"],
    proposalsMap: TProposalsMap,
    betSupermarket: Data__SB_SuperMarket["shelves_by_conflict_fkey"],
    events: Data__SB_SportEvent[],
    pickType: PickType,
  ) {
    let hasChange = false;
    Object.values(cartItems).forEach(cartItem => {
      const proposal = this.getBetProposalByProposalFkey(
        cartItem.proposal_fkey,
        cartItem.event.conflict_fkey,
        proposalsMap,
        betSupermarket,
      );
      const eventByFkey = AppUtils.getEventByConflictKey(
        events,
        cartItem.event.conflict_fkey,
      );
      if (
        this._detectChangesProposalItems(
          proposal,
          cartItem,
          eventByFkey,
          pickType,
        )
      ) {
        // CHANGE TO TRUE WHEN SERVER RESPONDS CORRECT
        hasChange = true;
      }
    });
    return hasChange;
  }

  public groupProposalsByParlayId = (
    proposals: Data__SB_ShoppingCartProposalItem[],
  ) =>
    proposals.reduce<Data__SB_ShoppingCartProposalItem[][]>((acc, curr) => {
      const index = acc.findIndex(
        (item: Data__SB_ShoppingCartProposalItem[]) =>
          item[0].parlay_id === curr.parlay_id,
      );
      if (index === -1) {
        acc.push([curr]);
      } else {
        acc[index].push(curr);
      }
      return acc;
    }, []);

  public getProposalsFromSingleMarket = <
    Market extends Data__SB_BetMarket | IBetMarketLocal,
  >(
    market: Market,
  ): Market["groups"][number]["proposals"][number][] =>
    flatten(market.groups.map(({ proposals }) => proposals));

  // This filter:
  // We skip the proposals' updates, which move in down direction from Herald/Polling during ticket processing.
  // This will reduce the amount of "Accept Changes" during ticket processing for in-play games.
  // Note: this applies only to the Main proposals. Not to the Supermarket, which handles UI only, but not the actual data.
  // Since this is only about to Not merge proposals for proposals which go in Down direction during processing.
  // FC-1289 Fliff Core
  public staleProposalsUpdates(
    proposals: Data__SB_BetProposal[],
    cartItems: Data__SB_ShoppingCart["items"],
    isTicketProcessing: boolean,
  ): Data__SB_BetProposal[] {
    return proposals.filter(nextProposal => {
      const cartItem = cartItems[nextProposal.global_radio_set_check__fkey];
      if (
        isTicketProcessing &&
        cartItem &&
        nextProposal.revision_id > cartItem.revisionId
      ) {
        const isCoeffDown = cartItem.coeff >= nextProposal.coeff;
        return !isCoeffDown;
      }

      return true;
    });
  }

  public isMarketShowcase = (market: IBetMarketLocal): boolean =>
    market.subfeed_code > 3000000;

  public getMoneylineProposal = (
    proposals: TCellRenderProposalLocal[],
  ): TCellRenderProposalLocal | undefined =>
    proposals.find(({ type }) => type === BetProposalTypes.CONST_7791);

  public isMarketUp(
    state: MarketOfferSelectionChangeType | undefined,
    lastUpdate: number | undefined,
  ): boolean {
    if (!state) {
      return false;
    }
    if (
      state === MarketOfferSelectionChangeType.CONST_131_OUTCOME_PARAM_1_UP ||
      state === MarketOfferSelectionChangeType.CONST_135_COEFF_UP
    ) {
      const ageOfCoeffUpdate =
        ServerClock.latestKnownServerUtcStampMillis - (lastUpdate || 0);

      return (
        ageOfCoeffUpdate < MarketChangesTimestamps.CONST_CHANGE_TIME_DIFFERENCE
      );
    }
    return false;
  }

  public isMarketDown(
    state: MarketOfferSelectionChangeType | undefined,
    lastUpdate: number | undefined,
  ): boolean {
    if (!state) {
      return false;
    }
    if (
      state === MarketOfferSelectionChangeType.CONST_132_OUTCOME_PARAM_1_DOWN ||
      state === MarketOfferSelectionChangeType.CONST_136_COEFF_DOWN
    ) {
      const ageOfCoeffUpdate =
        ServerClock.latestKnownServerUtcStampMillis - (lastUpdate || 0);

      return (
        ageOfCoeffUpdate < MarketChangesTimestamps.CONST_CHANGE_TIME_DIFFERENCE
      );
    }
    return false;
  }

  public getMarketByFkey(
    markets: IBetMarketLocal[] | undefined,
    marketFkey: string | undefined,
  ): IBetMarketLocal | null {
    if (!marketFkey || !markets) {
      return null;
    }

    return markets.find(market => market.market_fkey === marketFkey) ?? null;
  }

  public getProposalsFromMarkets = (
    markets: IBetMarketLocal[],
  ): TCellRenderProposalLocal[] =>
    flattenDeep<TCellRenderProposalLocal[]>(
      markets.map(({ groups }) => groups.map(({ proposals }) => proposals)),
    );

  public makePartiallyValidProposal(
    unavailableProposal: Partial<TMainProposalLocal>,
  ): TMainProposalLocal {
    const partiallyValidProposal: Partial<TMainProposalLocal> = {
      ...unavailableProposal,
    };
    if (!unavailableProposal.t_131_market_name) {
      partiallyValidProposal.t_131_market_name = "N/A";
    }
    if (!unavailableProposal.coeff) {
      partiallyValidProposal.coeff = 100;
    }
    if (!unavailableProposal.t_141_selection_name) {
      partiallyValidProposal.t_141_selection_name = "N/A";
    }
    if (!unavailableProposal.market_fkey) {
      partiallyValidProposal.market_fkey = "";
    }
    if (!unavailableProposal.t_121_event_info) {
      partiallyValidProposal.t_121_event_info = "N/A";
    }

    return partiallyValidProposal as TMainProposalLocal;
  }

  private _detectChangesProposalItems(
    proposal: TMainProposalLocal | undefined | null,
    cartItem: Data__SB_ShoppingCartProposalItem,
    event: Data__SB_SportEvent | null,
    pickType: PickType,
  ): boolean {
    if (!proposal) {
      return true;
    }

    if (cartItem.isReplaced) {
      return true;
    }

    if (this.isProposalOTB(proposal.status, event?.live_status)) {
      return true;
    }

    if (
      proposal.coeff !== cartItem.coeff &&
      pickType !== PickType.CONST_83_SAME_GAME_PARLAY
    ) {
      return true;
    }

    return proposal.t_141_selection_name !== cartItem.t_141_selection_name;
  }
}

export default new ProposalUtils();
