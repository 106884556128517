import HeraldHealthMonitor, {
  THealthState,
} from "server/herald/HeraldHealthMonitor";
import { FliffWebSocket } from "server/herald/fliffSocketImpl/FliffWebSocket";
import { IStringMap } from "src/types";
import Logger from "utils/Logger";

import { MessageFactory } from "../MessageFactory";
import { IEndpoint, IOnIncomingMessageCallBack } from "../types";
import FliffSocketBase from "./FliffSocketBase";
import SharedCoreUtils from "server/sharedCore/SharedCoreUtils";
import { AppUtils } from "utils/AppUtils";

export class FliffYSub extends FliffSocketBase {
  // 2022-01-03 / Ivan / keep map of active subscriptions - topic -> serialized request
  // this is used by unsubscribe method to send identical binary messages
  private _activeSubscriptions: IStringMap<string> = {};

  constructor(callback: IOnIncomingMessageCallBack) {
    super(callback);
    this.enableDebugLog = false;
  }

  public get endpoint(): null | IEndpoint {
    if (this.endpoints.length > 1) {
      throw new Error("bad endpoints.length: " + this.endpoints.length);
    }

    if (this.endpoints.length > 0) {
      return this.endpoints[0];
    }

    return null;
  }

  public get activeSubscriptionsTopics(): string[] {
    return Object.keys(this._activeSubscriptions);
  }

  public get activeSubscriptionsMessages(): string[] {
    return Object.values(this._activeSubscriptions);
  }

  public getMessagesOnConnect(endpoint: IEndpoint): string[] {
    return [
      this._createMessageDeclareConnect(endpoint),
      ...this.activeSubscriptionsMessages,
    ];
  }

  public connect(address: string): void {
    const nextAddress = `${address}?${new URLSearchParams(
      MessageFactory._requestHeader as unknown as Record<string, string>,
    ).toString()}`;

    super.connect(nextAddress);
  }

  public subscribe(topic: string): void {
    console.log("[fliff_subscribe] topic:", topic);
    if (topic in this._activeSubscriptions) {
      Logger.warnMessage(
        "warning [fliff_subscribe] - duplicate subscription - topic: " +
          topic +
          ", active_subscriptions.keys: " +
          Object.keys(this._activeSubscriptions),
      );
      return;
    }

    const request = MessageFactory.subscribeRequest(topic);
    const serializedRequest = JSON.stringify(request);

    this._activeSubscriptions[topic] = serializedRequest;

    this.sendMessage(serializedRequest);
  }

  public unsubscribe(topic: string) {
    console.log("[fliff_unsubscribe] topic:", topic);

    if (!(topic in this._activeSubscriptions)) {
      Logger.warnMessage(
        "warning [fliff_unsubscribe] - missing subscription - topic: " +
          topic +
          ", active_subscriptions.keys: " +
          Object.keys(this._activeSubscriptions),
      );
      return;
    }

    delete this._activeSubscriptions[topic];

    // 2022-01-19 / Ivan / we have full control over the data flow, so we don't need to resend the 'exact' message
    const request = MessageFactory.unsubscribeRequest(topic);
    const serializedRequest = JSON.stringify(request);

    this.sendMessage(serializedRequest);
  }

  public fliffMassUnsubscribe(topics: string[]) {
    for (const n in topics) {
      this.unsubscribe(topics[n]);
    }
  }

  public fliffMassSubscribe(topics: string[]) {
    for (const n in topics) {
      this.subscribe(topics[n]);
    }
  }

  public heartbeat() {
    try {
      const endpoint = this.endpoint;
      if (AppUtils.isNullable(endpoint)) {
        return;
      }
      const wstats = endpoint.stats;
      const activeSubscriptionsCount = this.activeSubscriptionsMessages.length;

      const request = MessageFactory.clientHeartbeatRequest(
        wstats,
        activeSubscriptionsCount,
      );
      const serializedRequest = JSON.stringify(request);

      console.log(" * fliff_heartbeat - send");
      this.sendMessage(serializedRequest);
    } catch (error) {
      Logger.warnAny("  x in FliffYSub/fliff_heartbeat", error);
    }
  }

  protected logg(st: string) {
    if (!this.enableDebugLog) {
      return;
    }
    console.log("[herald]", st);
  }

  protected attachEndpoint(endpoint: IEndpoint) {
    this.logg("attachEndpoint");

    super.attachEndpoint(endpoint);
    HeraldHealthMonitor.handleHealthChange(
      THealthState.CONST_CONNECTED,
      endpoint.stats,
    );

    this.sendActiveSubscriptions(endpoint);
  }

  protected hiccuped(endpoint: IEndpoint) {
    this.logg("\nhiccuped\n");
    super.hiccuped(endpoint);
    HeraldHealthMonitor.handleHealthChange(
      THealthState.CONST_CONNECTED,
      endpoint.stats,
    );

    this.sendActiveSubscriptions(endpoint);
  }

  protected sendActiveSubscriptions(endpoint: IEndpoint) {
    const serializedMessages = Object.values(this._activeSubscriptions);
    this.logg(
      `\nsend_active_subscriptions.length: ${serializedMessages.length}\nsend_active_subscriptions:${serializedMessages}`,
    );
    serializedMessages.forEach(mes => {
      endpoint.send(mes);
    });
  }

  protected reconnecting(endpoint: IEndpoint) {
    HeraldHealthMonitor.handleHealthChange(
      THealthState.CONST_RECONNECTING,
      endpoint.stats,
    );
    return super.reconnecting(endpoint);
  }

  protected endpointTerminated(endpoint: IEndpoint) {
    this.logg("endpointTerminated");
    HeraldHealthMonitor.handleHealthChange(
      THealthState.CONST_DISCONNECTED,
      endpoint.stats,
    );
    return super.endpointTerminated(endpoint);
  }

  protected xrecv(_websocket: FliffWebSocket, message: string) {
    try {
      this.logg("xrecv");
      const serializedMessageData = message;
      this.logg("serialized_message_data: " + serializedMessageData);
      this.callback.onIncomingMessage(serializedMessageData);
    } catch (error) {
      Logger.warnAny("  x in FliffXSub/xrecv", error);
    }
  }

  protected sendMessage(message: string) {
    this.sendToAll(message);
  }

  protected sendToAll(message: string) {
    this.endpoints.forEach(endpoint => {
      endpoint.send(message);
    });
  }

  private _createMessageDeclareConnect(endpoint: IEndpoint): string {
    const wstats = endpoint.stats;
    const activeSubscriptionsCount = this.activeSubscriptionsMessages.length;
    const feedMeta = SharedCoreUtils.buildFeedMeta();
    const request = MessageFactory.declareConnectRequest(
      wstats,
      activeSubscriptionsCount,
      feedMeta.packed_subfeed_revisions,
    );
    return JSON.stringify(request);
  }
}
