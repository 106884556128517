import { TAnyAlias } from "src/types";
import Logger from "utils/Logger";

import { FliffException } from "./FliffException";

export class ErrorHelper {
  // 2019-11-18 / Ivan / extract info from response.errors attribute in new game_protocol
  public static operationResultCodeToDDException(
    operationResultCode: number,
    operationResultMessage: string,
    errors: TAnyAlias,
  ): FliffException {
    const errorCode =
      FliffException.ERROR_30000__SERVER_OPERATION__BASE_ERROR_CODE +
      operationResultCode;
    const errorDetails = ErrorHelper._buildErrorFromXProtocolErrors(errors);

    let errorMessage: string;

    if (errorDetails) {
      if (errorDetails.length > 1) {
        errorMessage = " * " + errorDetails.join("\n * ");
      } else {
        errorMessage = errorDetails[0];
      }
    } else {
      errorMessage = "Server error message: " + operationResultMessage;
    }

    return new FliffException(errorCode, errorMessage);
  }

  // 2019-11-09 / Ivan / new approach for extracting server error messages - as discussed with Asen
  public static buildErrorFromJangoErrorStructure(
    response: TAnyAlias,
  ): string[] {
    const errors: string[] = [];

    if (Array.isArray(response)) {
      [].push.apply(errors, response as TAnyAlias);
    } else if (typeof response === "string") {
      errors.push(response);
    } else if (typeof response === "object") {
      for (const key in response) {
        const arr: TAnyAlias = ErrorHelper.buildErrorFromJangoErrorStructure(
          response[key],
        );
        [].push.apply(errors, arr);
      }
    }

    return errors;
  }

  public static apiErrorToDDException(error: TAnyAlias): FliffException {
    if (error instanceof FliffException) {
      return error;
    }

    // see https://github.com/axios/axios#handling-errors
    if (error.response) {
      // check if all standard attributes for Axios error are there
      if (
        error.config &&
        error.response &&
        error.response.config &&
        error.response.headers &&
        error.response.status &&
        "" + Number(error.response.status) === "" + error.response.status
      ) {
        // check if this error was generated by our server
        const axiosHttpResponse = error.response;

        if (axiosHttpResponse.data) {
          // 2023-08-25 / Ivan / add patch for low level system errors
          // which used to be automatically decoded to legacyCore axios config
          // but are now received as string in core v2 protocol
          let axiosHttpResponseData = axiosHttpResponse.data;
          if (typeof axiosHttpResponseData === "string") {
            try {
              axiosHttpResponseData = JSON.parse(axiosHttpResponseData);
            } catch (nextError) {
              // log warning, should never happen
              Logger.warnHandledAny(
                `x in JSON.parse/axiosHttpResponse_data ${axiosHttpResponseData}`,
                nextError,
              );
            }
          }

          // case 1 - own protocol error
          if (
            axiosHttpResponseData &&
            axiosHttpResponseData.status &&
            axiosHttpResponseData.status_text &&
            "" + Number(axiosHttpResponseData.status) ===
              "" + axiosHttpResponseData.status
          ) {
            return ErrorHelper._ownProtocolErrorToException(
              error,
              axiosHttpResponseData,
            );
          }
        }

        // case 2 - http error response
        return ErrorHelper._httpErrorWithResponseToException(error);
      }

      // unexpected case - some attributes are missing in the response object
      return new FliffException(
        FliffException.ERROR_9001__UNEXPECTED_ERROR__UNKNOWN_AXIOS_ERROR_WITH_RESPONSE,
        "unexpected axios error (with response)",
      );
    }

    if (error.request) {
      // case 3 - http error response
      // The request was made but no response was received
      return ErrorHelper._httpErrorWithRequestToException(error);
    }

    return new FliffException(
      FliffException.ERROR_9003__UNEXPECTED_ERROR__UNKNOWN_AXIOS_ERROR_NO_REQUEST_AND_RESPONSE,
      "unexpected axios error (no request/response)",
    );
  }

  private static _extractHttpResponseCode(error: TAnyAlias): number {
    const httpResponse = error.response;
    return Number(httpResponse.status);
  }

  private static _extractHttpResponseDataError(error: TAnyAlias): TAnyAlias {
    const httpResponse = error.response;
    return httpResponse.data && httpResponse.data.error
      ? httpResponse.data.error
      : null;
  }

  private static _extractHttpResponseDataErrorDescription(
    error: TAnyAlias,
  ): TAnyAlias {
    const httpResponse = error.response;
    return httpResponse.data && httpResponse.data.error_description
      ? httpResponse.data.error_description
      : null;
  }

  private static _extractXDDRequestCode(error: TAnyAlias): TAnyAlias {
    return error.config && error.config.headers
      ? error.config.headers["x-dd-request-code"]
      : null;
  }

  private static _extractConfigHeader(
    error: TAnyAlias,
    headerName: string,
  ): TAnyAlias {
    return error.config && error.config.headers
      ? error.config.headers[headerName]
      : null;
  }

  private static _httpErrorWithRequestToException(
    error: TAnyAlias,
  ): FliffException {
    const debugErrorSource = error.debug_error_source;

    if (error.name && error.name === "Error" && error.message) {
      const errorCodePrefix = error.code ? " / " + error.code : "";
      const message = "" + error.name + errorCodePrefix + " / " + error.message;
      return new FliffException(
        FliffException.ERROR_1001__COMMON_NETWORK_ERROR,
        message,
        debugErrorSource,
      );
    }

    // 2023-04-27 / Ivan / added additional case to handle new kind of network error reporting
    // probably caused by upgrade of Axios library
    if (error.name && error.name === "AxiosError" && error.message) {
      return new FliffException(
        FliffException.ERROR_1001__COMMON_NETWORK_ERROR,
        error.message,
        debugErrorSource,
      );
    }

    return new FliffException(
      FliffException.ERROR_9002__UNEXPECTED_ERROR__UNKNOWN_AXIOS_ERROR_WITH_REQUEST,
      "unexpected axios error (with request)",
    );
  }

  private static _httpErrorWithResponseToException(
    error: TAnyAlias,
  ): FliffException {
    // we already know that axios_http_error has all the needed properties
    const axiosHttpStatusCode = ErrorHelper._extractHttpResponseCode(error);
    const requestCode = ErrorHelper._extractXDDRequestCode(error);
    const debugErrorSource = error.debug_error_source;

    if (requestCode === "refresh_token") {
      const refreshToken = ErrorHelper._extractConfigHeader(
        error,
        "x-dd-refresh-token",
      );
      return ErrorHelper._httpErrorWithResponseToExceptionForRefreshToken(
        error,
        refreshToken,
      );
    }

    if (requestCode === "account_login") {
      return ErrorHelper._httpErrorWithResponseToExceptionForAccountSignIn(
        error,
      );
    }

    if (requestCode === "social_login") {
      return ErrorHelper._httpErrorWithResponseToExceptionForSocialSignIn(
        error,
      );
    }

    const errorCode = 10000 + axiosHttpStatusCode;
    const errorMessage = "HTTP response code [" + axiosHttpStatusCode + "]";
    return new FliffException(errorCode, errorMessage, debugErrorSource);
  }

  private static _httpErrorWithResponseToExceptionForAccountSignIn(
    error: TAnyAlias,
  ): FliffException {
    const axiosHttpStatusCode = ErrorHelper._extractHttpResponseCode(error);
    const axiosHttpResponseDataError =
      ErrorHelper._extractHttpResponseDataError(error);
    const axiosHttpResponseDataErrorDescription =
      ErrorHelper._extractHttpResponseDataErrorDescription(error);
    const requestCode = ErrorHelper._extractXDDRequestCode(error);
    const debugErrorSource = error.debug_error_source;

    if (
      axiosHttpStatusCode === 400 &&
      axiosHttpResponseDataError === "invalid_grant"
    ) {
      const errorCode =
        FliffException.ERROR_11090__ACCOUNT_LOGIN__BAD_CREDENTIALS;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " (" +
        axiosHttpResponseDataErrorDescription +
        ") for " +
        requestCode +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    if (
      axiosHttpStatusCode === 401 &&
      axiosHttpResponseDataError === "invalid_client"
    ) {
      const errorCode =
        FliffException.ERROR_11001__ACCOUNT_LOGIN__BAD_CONFIG_INVALID_CLIENT_ID_OR_SECRET;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " for " +
        requestCode +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    const errorCode =
      FliffException.ERROR_11000__ACCOUNT_LOGIN__BASE_ERROR_CODE +
      axiosHttpStatusCode;
    const errorMessage = "HTTP response code [" + axiosHttpStatusCode + "]";
    return new FliffException(errorCode, errorMessage, debugErrorSource);
  }

  private static _httpErrorWithResponseToExceptionForSocialSignIn(
    error: TAnyAlias,
  ): FliffException {
    const axiosHttpStatusCode = ErrorHelper._extractHttpResponseCode(error);
    const axiosHttpResponseDataError =
      ErrorHelper._extractHttpResponseDataError(error);
    const axiosHttpResponseDataErrorDescription =
      ErrorHelper._extractHttpResponseDataErrorDescription(error);
    const requestCode = ErrorHelper._extractXDDRequestCode(error);
    const debugErrorSource = error.debug_error_source;

    if (
      axiosHttpStatusCode === 401 &&
      axiosHttpResponseDataError === "invalid_client"
    ) {
      const errorCode =
        FliffException.ERROR_12001__SOCIAL_LOGIN__BAD_CONFIG_INVALID_CLIENT_ID_OR_SECRET;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " for " +
        requestCode +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    if (
      axiosHttpStatusCode === 400 &&
      axiosHttpResponseDataError === "invalid_request" &&
      axiosHttpResponseDataErrorDescription &&
      axiosHttpResponseDataErrorDescription.indexOf("client_id parameter") !==
        -1
    ) {
      const errorCode =
        FliffException.ERROR_12002__SOCIAL_LOGIN__BAD_CONFIG_ALT_INVALID_CLIENT_ID_OR_SECRET;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " (" +
        axiosHttpResponseDataErrorDescription +
        ") for " +
        requestCode +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    // handle bad backend param
    if (
      axiosHttpStatusCode === 400 &&
      axiosHttpResponseDataError === "invalid_request" &&
      axiosHttpResponseDataErrorDescription &&
      axiosHttpResponseDataErrorDescription.indexOf("backend parameter") !== -1
    ) {
      const errorCode =
        FliffException.ERROR_12003__SOCIAL_LOGIN__BAD_CONFIG_INVALID_REQUEST_BAD_BACKEND_PARAMETER;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " (" +
        axiosHttpResponseDataErrorDescription +
        ") for " +
        requestCode +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    // handle other bad params
    if (
      axiosHttpStatusCode === 400 &&
      axiosHttpResponseDataError === "invalid_request" &&
      axiosHttpResponseDataErrorDescription &&
      axiosHttpResponseDataErrorDescription.indexOf("access token") !== -1
    ) {
      const errorCode =
        FliffException.ERROR_12004__SOCIAL_LOGIN__BAD_CONFIG_INVALID_REQUEST_BAD_ACCESS_TOKEN;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " (" +
        axiosHttpResponseDataErrorDescription +
        ") for " +
        requestCode +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    // handle general invalid_request response
    if (
      axiosHttpStatusCode === 400 &&
      axiosHttpResponseDataError === "invalid_request"
    ) {
      const errorCode =
        FliffException.ERROR_12019__SOCIAL_LOGIN__BAD_CONFIG_INVALID_REQUEST_OTHER;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " (" +
        axiosHttpResponseDataErrorDescription +
        ") for " +
        requestCode +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    // handle bad token param
    if (
      axiosHttpStatusCode === 400 &&
      axiosHttpResponseDataError === "access_denied"
    ) {
      const errorCode = FliffException.ERROR_12020__SOCIAL_LOGIN__ACCESS_DENIED;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " (" +
        axiosHttpResponseDataErrorDescription +
        ") for " +
        requestCode +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    const errorCode =
      FliffException.ERROR_12000__SOCIAL_LOGIN__BASE_ERROR_CODE +
      axiosHttpStatusCode;
    const errorMessage = "HTTP response code [" + axiosHttpStatusCode + "]";
    return new FliffException(errorCode, errorMessage, debugErrorSource);
  }

  private static _httpErrorWithResponseToExceptionForRefreshToken(
    error: TAnyAlias,
    refreshToken: string,
  ): FliffException {
    const axiosHttpStatusCode = ErrorHelper._extractHttpResponseCode(error);
    const axiosHttpResponseDataError =
      ErrorHelper._extractHttpResponseDataError(error);

    const requestCode = ErrorHelper._extractXDDRequestCode(error);
    const debugErrorSource = error.debug_error_source;

    // refresh token is no longer valid
    if (
      axiosHttpStatusCode === 400 &&
      axiosHttpResponseDataError === "invalid_grant"
    ) {
      const errorCode =
        FliffException.ERROR_13092__REFRESH_TOKEN__INVALID_OR_EXPIRED_REFRESH_TOKEN;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " for " +
        requestCode +
        "/" +
        refreshToken +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    // bad client_id / client_secret config for raw OAUTH2 API
    if (
      axiosHttpStatusCode === 401 &&
      axiosHttpResponseDataError === "invalid_client"
    ) {
      const errorCode =
        FliffException.ERROR_13001__REFRESH_TOKEN__BAD_CONFIG_INVALID_CLIENT_ID_OR_SECRET;
      const errorMessage =
        "details [got " +
        axiosHttpResponseDataError +
        " for " +
        requestCode +
        "/" +
        refreshToken +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    const errorCode =
      FliffException.ERROR_13000__REFRESH_TOKEN__BASE_ERROR_CODE +
      axiosHttpStatusCode;
    const errorMessage = "HTTP response code [" + axiosHttpStatusCode + "]";
    return new FliffException(errorCode, errorMessage, debugErrorSource);
  }

  private static _ownProtocolErrorToException(
    error: TAnyAlias,
    axiosHttpResponseData: TAnyAlias,
  ): FliffException {
    const axiosHttpResponse = error.response;
    const debugErrorSource = error.debug_error_source;
    const axiosHttpStatusCode = Number(axiosHttpResponse.status);
    const ownApiResponseStatusCode = Number(axiosHttpResponseData.status);
    const ownApiResponseStatusMessage = axiosHttpResponseData.status_text;

    let ownApiErrorDetails = null;
    let ownApiResponseDetailMessage = null;
    let ownApiResponse = null;

    if (axiosHttpResponseData.detail) {
      ownApiErrorDetails = ErrorHelper.buildErrorFromJangoErrorStructure(
        axiosHttpResponseData.detail,
      );
    }

    if (
      axiosHttpResponseData.response &&
      axiosHttpResponseData.response.detail
    ) {
      ownApiResponseDetailMessage = axiosHttpResponseData.response.detail;
    }

    if (
      axiosHttpResponseData.response &&
      Array.isArray(axiosHttpResponseData.response) &&
      axiosHttpResponseData.response.length > 0
    ) {
      ownApiResponse = axiosHttpResponseData.response[0];
    }

    // expired access token - need to refresh ?
    if (
      axiosHttpStatusCode === 401 &&
      ownApiResponseStatusCode === 3 &&
      ownApiResponseDetailMessage ===
        "Authentication credentials were not provided."
    ) {
      const errorCode =
        FliffException.ERROR_13091__REFRESH_TOKEN__INVALID_OR_EXPIRED_ACCESS_TOKEN;
      const errorMessage =
        "Server error message [" +
        axiosHttpStatusCode +
        " / " +
        ownApiResponseStatusCode +
        " / " +
        ownApiResponseDetailMessage +
        "]";
      return new FliffException(errorCode, errorMessage, debugErrorSource);
    }

    // 2019-10-26 / Ivan / for the time being - convert server error codes to 20xxx so that they can be easily recognized
    // server returns errorCodes = 1 ... 5, need to review server docs

    let errorCode;
    let message;

    // 2019-11-04 / Ivan / unfortunately we dont have formal protocol for communicating server errors
    // lets use different codes to distinguish between different fields which communicate different errors
    if (ownApiResponse) {
      errorCode = 21000 + ownApiResponseStatusCode;
      message = ownApiResponse;
    } else if (ownApiResponseDetailMessage) {
      errorCode = 22000 + ownApiResponseStatusCode;
      message = ownApiResponseDetailMessage;
    } else {
      errorCode = 23000 + ownApiResponseStatusCode;
      message = ownApiResponseStatusMessage;
    }

    // 2019-12-22 / Ivan - introduce special error code for this case
    // 5 - ProfileNotCompletedError
    if (ownApiResponseStatusCode === 5) {
      if (!message) {
        message = "(internal) The user profile is not completed";
      }
      return new FliffException(
        FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED,
        message,
        debugErrorSource,
      );
    }

    // 2019-12-22 / Ivan / temp solution for testing, need to coordinate with server guys the error code
    if (ownApiResponseStatusCode === 9) {
      message =
        "App version too old - please upgrade to the latest version available in the app store.";
      return new FliffException(
        FliffException.ERROR_1904__SESSION_EXPIRED__APP_VERSION_TOO_OLD,
        message,
        debugErrorSource,
      );
    }

    let errorMessage;

    if (ownApiErrorDetails) {
      if (ownApiErrorDetails.length > 1) {
        errorMessage = " * " + ownApiErrorDetails.join("\n * ");
      } else {
        errorMessage = ownApiErrorDetails[0];
      }
    } else {
      errorMessage = "Server error message: " + message;
    }

    return new FliffException(errorCode, errorMessage, debugErrorSource);
  }

  // 2019-11-09 / Ivan / new approach for extracting server error messages - as discussed with Asen
  private static _buildErrorFromXProtocolErrors(
    responseErrors: TAnyAlias,
  ): string[] {
    const errors: string[] = [];

    try {
      if (!Array.isArray(responseErrors)) {
        errors.push("Unknown server error (response.errors not an array)");
        return errors;
      }

      if (responseErrors.length === 0) {
        errors.push("Unknown server error (empty response.errors array)");
        return errors;
      }

      for (const err of responseErrors) {
        for (const key in err) {
          // 2019-11-24 / Ivan / err[key] may be an array of messages ?
          if (Array.isArray(err[key])) {
            for (const errobj of err[key]) {
              if (errobj.message) {
                errors.push(errobj.message);
              }
            }
          } else {
            if (err[key].message) {
              errors.push(err[key].message);
            }
          }
        }
      }
    } catch (error) {
      console.warn(" * error in buildErrorFromXProtocolErrors", error);
    }

    return errors;
  }
}
