import { WITHDRAWAL_METHODS } from "constants/WITHDRAWAL";
import groupBy from "lodash/groupBy";
import { TGlobalAppState } from "reduxLocal/rootReducer";
import { createSelector } from "reselect";
import * as s from "selectors/index";
import createDeepEqualSelector from "selectors/utils/createDeepEqualSelector";
import { TransactionType } from "server/core/data/constants";
import { WithdrawalPaymentInstrumentType } from "server/core/data/objects";
import { OwnershipAttributeCode } from "server/legacyCore/data/constants";
import { IPlayThroughBundle } from "server/legacyCore/data/objects";
import { TWithdrawProviders } from "src/types";
import AppUIModesManager from "utils/AppUIModesManager";
import { AppUtils } from "utils/AppUtils";
import ChannelUtils from "utils/ChannelUtils";
import { TimeUtils } from "utils/TimeUtils";
import { CashAmountFormatters } from "utils/UIAmountsFormatters";

class CoreSelectors {
  public get Slide() {
    return {
      cardBrands: this._slideCardBrands,
      popularBrands: createSelector(this._slideCardBrands, brands =>
        brands.filter(brand => brand.is_popular),
      ),
      categories: createSelector(this._slideCardBrands, brands => [
        ...new Set(brands.map(brand => brand.v_category)),
      ]),
      groupedBrandsByCategory: createSelector(this._slideCardBrands, brands =>
        groupBy(brands, brand => brand.v_category),
      ),
      isGiftCardsEnabled: this._isGiftCardsEnabledAccessor,
    };
  }

  public get Challenges() {
    return {
      todayChallengesReport: this._todayChallengesReport,
      availableTodayToEarnXP: createSelector(
        this._publicChallengesReportConfig.totalTodayChallenges,
        this._publicChallengesReportConfig.completedTodayChallenges,
        (totalChallenges, completedChallenges) =>
          (totalChallenges - completedChallenges) * 2,
      ),
      XPPerChallengeConfig: createSelector(
        this._configAccessor,
        ({
          v3_xpoints_for_complete_all_daily_challenges: forAll,
          v3_xpoints_for_complete_single_challenge: perSingle,
        }) => ({ perSingle, forAll }),
      ),
      ...this._publicChallengesReportConfig,
    };
  }

  public get Channels() {
    return {
      sortedChannels: createDeepEqualSelector(
        this._configAccessor,
        ({ channels }) =>
          ChannelUtils.sortChannelByDashPos(
            channels.filter(channel => channel.dash_pos > 0),
          ),
      ),
      allChannels: createSelector(this._configAccessor, ({ channels }) =>
        ChannelUtils.sortChannelsByAllSportsPos(channels, "asc"),
      ),
      allSportsChannels: createDeepEqualSelector(
        this._configAccessor,
        ({ channels }) =>
          ChannelUtils.sortChannelsByAllSportsPos(
            channels.filter(channel => channel.allsports_pos > 0),
            "desc",
          ),
      ),
    };
  }

  public get CoinBundles() {
    return {
      activeFliffCashBundles: this._activeFliffCashBundles,
      selectedBundleVerifyFactory: (
        bundle: IPlayThroughBundle | null | undefined,
      ) =>
        createSelector(this._activeFliffCashBundles, fliffCashBundles => {
          if (!bundle) {
            return false;
          }

          return fliffCashBundles.find(
            ({ bundle_code: bundleCode }) => bundleCode === bundle.bundle_code,
          );
        }),
      freeBundle: createSelector(
        this._permissionsAndDataAccessor,
        ({ play_through_data: { data } }) => {
          const freeBundle =
            data.find(bundle => bundle.price_in_cents === 0) ?? null;

          if (freeBundle) {
            return {
              bundle: freeBundle,
              nextClaimStamp: freeBundle.min_next_obtain_stamp_sec
                ? TimeUtils.fromSecondsToMills(
                    freeBundle.min_next_obtain_stamp_sec,
                  )
                : 0,
            };
          }

          return { bundle: null, nextClaimStamp: 0 };
        },
      ),
    };
  }

  public get Attributes() {
    return {
      emailValue: createSelector(
        this._emailAttribute,
        primaryEmailAttribute => {
          if (!primaryEmailAttribute) {
            return "";
          }
          return primaryEmailAttribute.value;
        },
      ),
      emailMinClaimStamp: createSelector(
        this._emailAttribute,
        primaryEmailAttribute => {
          if (!primaryEmailAttribute) {
            return 0;
          }
          return primaryEmailAttribute.min_claim_stamp_seconds_utc;
        },
      ),
      emailState: createSelector(
        this._emailAttribute,
        primaryEmailAttribute => {
          if (!primaryEmailAttribute) {
            return -1;
          }
          return primaryEmailAttribute.state;
        },
      ),
      phoneState: createSelector(
        this._phoneAttribute,
        primaryPhoneNumberAttribute => {
          if (!primaryPhoneNumberAttribute) {
            return -1;
          }
          return primaryPhoneNumberAttribute.state;
        },
      ),
      phoneValue: createSelector(
        this._phoneAttribute,
        primaryPhoneNumberAttribute => {
          if (!primaryPhoneNumberAttribute) {
            return "";
          }
          return primaryPhoneNumberAttribute.value;
        },
      ),
      phoneNextStamp: createSelector(
        this._phoneAttribute,
        primaryPhoneNumberAttribute => {
          if (!primaryPhoneNumberAttribute) {
            return 0;
          }
          return primaryPhoneNumberAttribute.min_claim_stamp_seconds_utc;
        },
      ),
    };
  }

  public get Balances() {
    return {
      playableFliffCash: this._playableFliffCashBalance,
      redeemableFliffCash: this._redeemableFliffCash,
      totalFliffCashBalance: this._totalFliffCashBalance,
      playableTotalFliffCashBalance: (state: TGlobalAppState) => {
        const isPlayableTotalBalanceRestricted =
          this.Miscellaneous.isPlayableTotalBalanceRestricted(state);

        if (isPlayableTotalBalanceRestricted) {
          return this._playableFliffCashBalance(state);
        }

        return this._totalFliffCashBalance(state);
      },
    };
  }

  public get Transactions() {
    return {
      lastTransactionsReport: this._lastTransactionsReportAccessor,
      lastVisualTransactions: createDeepEqualSelector(
        this._lastTransactionsReportAccessor,
        lastTransactionsReport =>
          AppUtils.mapTransactionsDataToVisualList({
            transactions: lastTransactionsReport.transactions,
            unfinished_orders: lastTransactionsReport.unfinishedOrders,
          }),
      ),
      lastKnownFailedMazoomaWithdrawalTransaction: createSelector(
        this._firstFromKnownTransaction,
        transaction => {
          if (!transaction) {
            return null;
          }
          if (
            transaction.type !==
            TransactionType.CONST_4142_MAZOOMA_TRANSFER_FAILED
          ) {
            return null;
          }
          return transaction;
        },
      ),
    };
  }

  public get Withdrawal() {
    return {
      withdrawalLimitsConfig: this._withdrawalLimitsConfig,
      withdrawalLimitSingleConfigFactory: createSelector(
        this._withdrawalLimitsConfig,
        (
          _state: TGlobalAppState,
          withdrawalProvider: Exclude<TWithdrawProviders, "">,
        ) => ({ withdrawalProvider }),
        (limitsConfig, { withdrawalProvider }) =>
          limitsConfig[withdrawalProvider],
      ),
      nextWithdrawDaysLeft: this._nextWithdrawDaysLeft,
      lastWithdrawVisualDate: this._lastWithdrawVisualDate,
      nextWithdrawMinVisualDate: this._nextWithdrawMinVisualDate,
      daysPerWithdraw: this._daysPerWithdraw,
      maxWithdrawProcessingDays: this._maxWithdrawProcessingDays,
      withdrawLockedAmount: this._withdrawLockedAmount,
      withdrawLockedCount: this._withdrawLockedCount,
      availableAmountToWithdraw: this._availableAmountToWithdraw,
      prevWithdrawalsTotalAmount: this._prevWithdrawalsTotalAmount,
      isWithdrawalAvailable: createSelector(
        this._nextWithdrawDaysLeft,
        this._withdrawLockedCount,
        (nextWithdrawDaysLeft, withdrawLockedCount) =>
          nextWithdrawDaysLeft === 0 && withdrawLockedCount === 0,
      ),
      minWithdrawalAmount: this._minWithdrawalAmountAccessor,
      withdrawalAvailableMethods: createSelector(
        this._unavailableWithdrawalMethods,
        unavailableWithdrawMethods =>
          WITHDRAWAL_METHODS.filter(
            ({ value }) => !unavailableWithdrawMethods.includes(value),
          ),
      ),
    };
  }

  public get ServerAppUIModes() {
    return {
      isFullEnabled: createSelector(
        this._serverAppUIMode,
        AppUIModesManager.isServerFullUIModeEnabled,
      ),
      isLightEnabled: createSelector(
        this._serverAppUIMode,
        AppUIModesManager.isServerLightModeEnabled,
      ),
      isLightIOSEnabled: createSelector(
        this._serverAppUIMode,
        AppUIModesManager.isServerIOSLightUIMode,
      ),
      isAndroidLightModeEnabled: createSelector(
        this._serverAppUIMode,
        AppUIModesManager.isServerAndroidModeEnabled,
      ),
    };
  }

  public get Miscellaneous() {
    return {
      mazoomaTokens: createSelector(
        this._paymentTokensAccessor,
        paymentTokens =>
          paymentTokens.filter(
            ({ instrument_type }) =>
              instrument_type ===
              WithdrawalPaymentInstrumentType.CONST_8101_MAZOOMA_DEFAULT,
          ),
      ),
      paymentTokens: this._paymentTokensAccessor,
      cashierBannerConfigFactory: (screen: "cash" | "coins") =>
        createSelector(this._permissionsAndDataAccessor, permissionsAndData => {
          if (screen === "cash") {
            return {
              skinId: permissionsAndData.cashier_cash_banner_skin,
              title: permissionsAndData.cashier_cash_banner_title,
              description: permissionsAndData.cashier_cash_banner_text,
              url: permissionsAndData.cashier_cash_banner_url,
            };
          }
          return {
            skinId: permissionsAndData.cashier_social_banner_skin,
            title: permissionsAndData.cashier_social_banner_title,
            description: permissionsAndData.cashier_social_banner_text,
            url: permissionsAndData.cashier_social_banner_url,
          };
        }),
      isFCCashierEnabled: createSelector(
        this.ServerAppUIModes.isAndroidLightModeEnabled,
        isAndroidLightModeEnabled => !isAndroidLightModeEnabled,
      ),
      globalBannerConfig: createSelector(
        this._permissionsAndDataAccessor,
        ({
          global_banner_image_url: bannerImgUrl,
          global_banner_click_url: redirectUrl,
        }) => ({ bannerImgUrl, redirectUrl }),
      ),
      activityFeedArenas: createSelector(
        this._permissionsAndDataAccessor,
        ({ active_feed_arenas: activityFeedArenas }) => activityFeedArenas,
      ),
      leaderboardArenas: createSelector(
        this._permissionsAndDataAccessor,
        ({ leaderboard_arenas: leaderboardArenas }) => leaderboardArenas,
      ),
      whatsNewBoxVisibilityState: this._whatsNewBoxVisibilityState,
      isTicketProcessing: this._ticketProcessingStateAccessor,
      minUserIdThreshold: (state: TGlobalAppState) =>
        this._configAccessor(state)
          .min_user_profile_id_which_requires_kyc_before_place_pick,
      isPlayableTotalBalanceRestricted: (state: TGlobalAppState) =>
        AppUtils.isPlayableTotalBalanceRestricted(
          this._profileAccessor(state).place_pick_play_through_mode,
        ),
    };
  }

  public get events() {
    return createSelector(this._eventsAccessor, events => {
      const eventsCopy = [...events];
      eventsCopy.sort((a, b) => {
        const firstStampInMinutes = TimeUtils.fromMillsToMinutesStamp(
          a.event_start_timestamp_utc,
        );
        const secondsStampInMinutes = TimeUtils.fromMillsToMinutesStamp(
          b.event_start_timestamp_utc,
        );
        if (a.weight === b.weight) {
          if (firstStampInMinutes === secondsStampInMinutes) {
            return (a.awayTeamName + a.homeTeamName).toLowerCase() >
              (b.awayTeamName + b.homeTeamName).toLowerCase()
              ? 1
              : -1;
          }
          return firstStampInMinutes - secondsStampInMinutes;
        }
        return a.weight - b.weight;
      });

      return eventsCopy;
    });
  }

  public get pointsTransactionsReport() {
    return createSelector(this._pointsTransactionsReportAccessor, report => {
      const copy = { ...report };
      copy.data.sort((a, b) => b.id - a.id);

      return copy;
    });
  }

  private get _withdrawalLimitsConfig() {
    return createSelector(
      this._profileAccessor,
      this._minWithdrawalAmountAccessor,
      (profile, minWithdrawalAmount) =>
        ({
          cashApp: {
            min: CashAmountFormatters.prepareCentsInDollars(
              minWithdrawalAmount,
            ),
            max: CashAmountFormatters.prepareCentsInDollars(
              profile.withdraw_7303__CASH_APP__max_amount,
            ),
          },
          bankWire: {
            min: CashAmountFormatters.prepareCentsInDollars(
              minWithdrawalAmount,
            ),
            max: CashAmountFormatters.prepareCentsInDollars(
              profile.withdraw_7307__MAZOOMA_INSTANT__max_amount,
            ),
          },
          bitcoin: {
            min: CashAmountFormatters.prepareCentsInDollars(
              minWithdrawalAmount,
            ),
            max: CashAmountFormatters.prepareCentsInDollars(
              profile.withdraw_7305__BITCOIN__max_amount,
            ),
          },
          skrill: {
            min: CashAmountFormatters.prepareCentsInDollars(
              minWithdrawalAmount,
            ),
            max: CashAmountFormatters.prepareCentsInDollars(
              profile.withdraw_7304__SKRILL__max_amount,
            ),
          },
          mazooma: {
            min: CashAmountFormatters.prepareCentsInDollars(
              minWithdrawalAmount,
            ),
            max: CashAmountFormatters.prepareCentsInDollars(
              profile.withdraw_7306__MAZOOMA_VERIFIED__max_amount,
            ),
          },
          fiservBank: {
            min: CashAmountFormatters.prepareCentsInDollars(
              minWithdrawalAmount,
            ),
            max: CashAmountFormatters.prepareCentsInDollars(
              profile.withdraw_7308__FISERV_VERIFIED__max_amount,
            ),
          },
          fiservCard: {
            min: CashAmountFormatters.prepareCentsInDollars(
              minWithdrawalAmount,
            ),
            max: CashAmountFormatters.prepareCentsInDollars(
              profile.withdraw_7308__FISERV_VERIFIED__max_amount,
            ),
          },
        } as const),
    );
  }

  private get _totalFliffCashBalance() {
    return (state: TGlobalAppState) =>
      this._playableFliffCashBalance(state) + this._redeemableFliffCash(state);
  }

  private get _playableFliffCashBalance() {
    return (state: TGlobalAppState) =>
      state.sportsBook.profile.r_5011_v5_playable_fliff_cash;
  }

  private get _redeemableFliffCash() {
    return (state: TGlobalAppState) =>
      state.sportsBook.profile.r_5013_v5_redeemable_fliff_cash;
  }

  private get _firstFromKnownTransaction() {
    return createDeepEqualSelector(
      this._lastTransactionsReportAccessor,
      s.d_51221_last_v2_transaction_id_s,
      ({ transactions: lastTransactions }, lastKnownTransactionId) => {
        if (!lastTransactions) {
          return null;
        }
        const transaction = lastTransactions.find(
          ({ id }) => lastKnownTransactionId === id,
        );
        if (transaction) {
          return transaction;
        }
        return null;
      },
    );
  }

  private get _phoneAttribute() {
    return createSelector(
      this._profileAccessor,
      profile =>
        profile.attributes.find(
          attribute =>
            attribute.code ===
            OwnershipAttributeCode.CONST_9121_PRIMARY_PHONE_NUMBER,
        ) || null,
    );
  }

  private get _emailAttribute() {
    return createSelector(
      this._profileAccessor,
      profile =>
        profile.attributes.find(
          attribute =>
            attribute.code === OwnershipAttributeCode.CONST_9122_PRIMARY_EMAIL,
        ) || null,
    );
  }

  private get _activeFliffCashBundles() {
    return createSelector(this._activeBundles, bundleOffers =>
      bundleOffers.filter(bundle => bundle.amount_fliff_cash > 0),
    );
  }

  private get _activeBundles() {
    return createSelector(
      this._permissionsAndDataAccessor,
      ({ play_through_data: { data } }) =>
        data.filter(bundle => bundle.price_in_cents > 0),
    );
  }

  private get _publicChallengesReportConfig() {
    return {
      completedTodayChallenges: createSelector(
        this._todayChallengesReport,
        ({ data: challenges }) =>
          challenges.filter(el => el.is_completed).length,
      ),
      totalTodayChallenges: createSelector(
        this._todayChallengesReport,
        ({ data: challenges }) => challenges.length,
      ),
    };
  }

  private get _todayChallengesReport() {
    return createSelector(
      this._todayChallengesReportAccessor,
      todayChallengesReport => {
        const copy = { ...todayChallengesReport };
        copy.data.sort((a, b) => +b.is_completed - +a.is_completed);

        return copy;
      },
    );
  }

  private get _slideCardBrands() {
    return createSelector(this._slideAccessor, ({ brands }) => {
      const copy = [...brands];
      copy.sort((a, b) => (a.b_name > b.b_name ? 1 : -1));
      return copy;
    });
  }

  private get _unavailableWithdrawalMethods() {
    return createSelector(this._withdrawalLimitsConfig, config => {
      const unavailableWithdrawMethods: TWithdrawProviders[] = [];
      let provider: Exclude<TWithdrawProviders, "">;

      for (provider in config) {
        if (config[provider].max <= 0) {
          unavailableWithdrawMethods.push(provider);
        }
      }

      return unavailableWithdrawMethods;
    });
  }

  public userId = (state: TGlobalAppState) => {
    return this._profileAccessor(state).user_id;
  };

  private _isGiftCardsEnabledAccessor = (state: TGlobalAppState) => {
    return this._permissionsAndDataAccessor(state).enable_gift_cards;
  };

  private _paymentTokensAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).paymentTokens;
  };

  public makeSelectEventStats() {
    return createSelector(
      this._eventStatsAccessor,
      (_: TGlobalAppState, eventFKey?: string) => eventFKey,
      (eventStats, eventFKey) => (eventFKey ? eventStats[eventFKey] : null),
    );
  }

  private _ticketProcessingStateAccessor = (state: TGlobalAppState) => {
    return this._shoppingCartUIAccessor(state).isTicketProcessing;
  };

  private _minWithdrawalAmountAccessor = (state: TGlobalAppState) => {
    return this._configAccessor(state).min_amount_for_withdraw_in_cents;
  };

  private _whatsNewBoxVisibilityState = (state: TGlobalAppState) => {
    return this._permissionsAndDataAccessor(state)
      .we_are_under_construction_skin;
  };

  private _serverAppUIMode = (state: TGlobalAppState) => {
    return this._permissionsAndDataAccessor(state).ui_mode;
  };

  private _nextWithdrawDaysLeft = (state: TGlobalAppState) => {
    return this._profileAccessor(state).next_withdraw_days_left;
  };

  private _lastWithdrawVisualDate = (state: TGlobalAppState) => {
    return this._profileAccessor(state).last_withdraw_visual_date;
  };

  private _nextWithdrawMinVisualDate = (state: TGlobalAppState) => {
    return this._profileAccessor(state).next_withdraw_min_visual_date;
  };

  private _daysPerWithdraw = (state: TGlobalAppState) => {
    return this._profileAccessor(state).withdraw_min_days_between_withdraws;
  };

  private _maxWithdrawProcessingDays = (state: TGlobalAppState) => {
    return this._profileAccessor(state).withdraw_max_days_to_process_withdraw;
  };

  private _withdrawLockedAmount = (state: TGlobalAppState) => {
    return this._profileAccessor(state).d_51071_withdraw__locked_amount;
  };

  private _withdrawLockedCount = (state: TGlobalAppState) => {
    return this._profileAccessor(state).d_51072_withdraw__locked_count;
  };

  private _availableAmountToWithdraw = (state: TGlobalAppState) => {
    return this._profileAccessor(state).v2_withdraw_limits_allowed_amount;
  };

  private _prevWithdrawalsTotalAmount = (state: TGlobalAppState) => {
    return this._profileAccessor(state).d_51073_withdraw__total_approved_amount;
  };

  private _lastTransactionsReportAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).lastTransactionsReport;
  };

  private _eventsAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).events;
  };

  private _pointsTransactionsReportAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).points_transaction_report;
  };

  private _profileAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).profile;
  };

  private _permissionsAndDataAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).pdata;
  };

  private _configAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).config;
  };

  private _todayChallengesReportAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).today_challenges_report;
  };

  private _slideAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).slide_card_brands;
  };

  private _eventStatsAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).eventStats;
  };

  private _shoppingCartUIAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).shopping_cart_ui;
  };

  private _accessor({ sportsBook }: TGlobalAppState) {
    return sportsBook;
  }
}

export default new CoreSelectors();
