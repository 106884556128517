import { createSelector } from "reselect";

import CoreSelectors from "reduxLocal/core/core.selectors";
import { TGlobalAppState } from "reduxLocal/rootReducer";
import { PickType, UIConstants } from "server/legacyCore/data/constants";
import { Data__SB_Conflict_Class_Def } from "server/legacyCore/data/objects";
import {
  Data__SB_ShoppingCartProposalItem,
  IBetMarketLocal,
} from "server/legacyCore/data/objects_local";
import { IStringMap, TAnyAlias } from "src/types";
import AppUIModesManager from "utils/AppUIModesManager";
import PickUtils from "utils/PickUtils";
import ProposalUtils from "utils/ProposalUtils";
import {
  CashAmountFormatters,
  CoinsAmountFormatters,
} from "utils/UIAmountsFormatters";
import { AppUtils } from "utils/AppUtils";
import { TimeUtils } from "utils/TimeUtils";

import AppSelectors from "reduxLocal/app/app.selectors";
import UIPickUtils from "utils/UIPickUtils";
import createDeepEqualSelector from "selectors/utils/createDeepEqualSelector";
import * as s from "./index";

export const getSortedChannels = createDeepEqualSelector(
  s.sports_book_channels_s,
  channels => {
    const sortedChannels = channels.filter(channel => channel.dash_pos > 0);
    sortedChannels.sort((a, b) => a.dash_pos - b.dash_pos);
    return sortedChannels;
  },
);

export const getPointsTransactionsReport = createSelector(
  s.points_transaction_report_s,
  transactionsReport => {
    const copy = [...transactionsReport.data];
    copy.sort((a, b) => b.id - a.id);

    return copy;
  },
);

export const getEvents = createSelector(s.sports_book_events_s, events => {
  const eventsCopy = [...events];
  eventsCopy.sort((a, b) => {
    const firstStampInMinutes = TimeUtils.fromMillsToMinutesStamp(
      a.event_start_timestamp_utc,
    );
    const secondsStampInMinutes = TimeUtils.fromMillsToMinutesStamp(
      b.event_start_timestamp_utc,
    );
    if (firstStampInMinutes === secondsStampInMinutes) {
      return (a.awayTeamName + a.homeTeamName).toLowerCase() >
        (b.awayTeamName + b.homeTeamName).toLowerCase()
        ? 1
        : -1;
    }
    return firstStampInMinutes - secondsStampInMinutes;
  });

  return eventsCopy;
});

export const getCustomerProfile = createDeepEqualSelector(
  s.sports_book_profile_s,
  profile => profile,
);

export const getXPPerSingleChallenge = createSelector(
  s.xpoints_for_complete_single_challenge,
  XPPerChallenge => XPPerChallenge,
);

export const getUnclaimedPicksPointsAmount = createSelector(
  getCustomerProfile,
  profile => profile.amount_max_v3_xpoints_for_place_pick,
);

export const getTodayChallengesReport = createDeepEqualSelector(
  s.today_challenges_report_s,
  todayChallengesReport => todayChallengesReport,
);

export const getTodayChallengesReportData = createDeepEqualSelector(
  getTodayChallengesReport,
  ({ data }) => {
    const copy = [...data];
    copy.sort((a, b) => +b.is_completed - +a.is_completed);
    return copy;
  },
);

export const getTotalTodayChallengesCount = createSelector(
  getTodayChallengesReportData,
  challenges => {
    if (challenges.length === 0) {
      return 0;
    }
    return challenges.length;
  },
);

export const getCompletedTodayChallengesCount = createSelector(
  getTodayChallengesReportData,
  challenges => {
    if (challenges.length === 0) {
      return 0;
    }
    return challenges.filter(el => el.is_completed).length;
  },
);

export const getXPBalance = createSelector(
  getCustomerProfile,
  profile => profile.amount_v3_xpoints,
);

export const getUnclaimedChallengesPointsAmount = createSelector(
  getTotalTodayChallengesCount,
  getCompletedTodayChallengesCount,
  (totalChallenges, completedChallenges) => {
    const incompleteChallengesCount = totalChallenges - completedChallenges;
    return incompleteChallengesCount * 2;
  },
);

export const getAllSportsChannels = createDeepEqualSelector(
  s.sports_book_channels_s,
  channels => {
    const sortedChannels = channels.filter(
      channel => channel.allsports_pos > 0,
    );
    sortedChannels.sort((a, b) => a.allsports_pos - b.allsports_pos);
    return sortedChannels;
  },
);

export const makeIsCartItemsIncludesProposalFactory = () =>
  createSelector(
    getCartItems,
    (_: TAnyAlias, props: { global_radio_set_check__fkey: string }) =>
      props.global_radio_set_check__fkey,
    (cartItems, global_radio_set_check__fkey) =>
      !!cartItems[global_radio_set_check__fkey],
  );

export const getCartPickType = (state: TGlobalAppState) =>
  s.shopping_cart_s(state).pickType;

export const getCurrencyMode = createSelector(
  s.ui_currency_mode_s,
  currencyMode => currencyMode,
);

export const getAllChannels = createDeepEqualSelector(
  s.sports_book_channels_s,
  channels => channels,
);

export const getBetSupermarket = s.bet_supermarket_s;

// Caution, use this in rare cases !!!
// Supermarket is very deep entity, such check could negative affect in performance.
export const getBetSupermarketDeepEqual = createDeepEqualSelector(
  s.bet_supermarket_s,
  sup => sup,
);

export const getProposalsMap = (state: TGlobalAppState) =>
  state.sportsBook.proposals;

export const getCartItems = createDeepEqualSelector(
  s.cart_items_s,
  cartItems => cartItems,
);

export const getIsParlayAllowedToSubmit = createSelector(
  getCartItems,
  PickUtils.isParlayAllowed,
);

export const getCartItemsCount = createSelector(
  getCartItems,
  cartItems => Object.keys(cartItems).length,
);

export const getIsMinimizedTicketTotalCoeffVisible = createSelector(
  getCartPickType,
  getIsParlayAllowedToSubmit,
  (pickType, isParlayAllowed) => {
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      return true;
    }
    return isParlayAllowed;
  },
);

export const getMaxTotalNumberOfSelections = (state: TGlobalAppState) =>
  getSportsBookConfig(state).max_number_of_selections_total;

export const getIsBetsCountOver = createSelector(
  getCartItems,
  cartItems => Object.keys(cartItems).length >= 12,
);

export const getGoldCoinsBalance = createSelector(
  s.coins_balance_s,
  coinsBalance => coinsBalance,
);

export const getPendingPicksCount = createSelector(
  getCurrencyMode,
  getCustomerProfile,
  (currencyMode, profile) => {
    if (UIConstants.isModeCash(currencyMode)) {
      return profile.d_51004_fliff_cash_active_picks_count;
    }
    return profile.d_51002_tokens_active_picks_count;
  },
);

export const getSportsBookConfig = createDeepEqualSelector(
  s.sports_book_config_s,
  config => config,
);

export const getShoppingCart = createDeepEqualSelector(
  s.shopping_cart_s,
  shoppingCart => shoppingCart,
);

export const getPointsForCompletingAllChallenges =
  s.points_for_complete_all_daily_challenges_s;

export const getDisableIapUiUnfinishedPurchaseInProgress = createSelector(
  getCustomerProfile,
  profile => profile.open_iap_purchases_count > 0,
);

export const get_d_51251_last_transaction__4116_pending_fc_credit =
  createDeepEqualSelector(
    s.d_51251_last_transaction__4116_pending_fc_credit_s,
    d_51251_last_transaction__4116_pending_fc_credit =>
      d_51251_last_transaction__4116_pending_fc_credit,
  );

export const get_d_51250_last_transaction__4115_mail_in_entry =
  createDeepEqualSelector(
    s.d_51250_last_transaction__4115_mail_in_entry_s,
    d_51250_last_transaction__4115_mail_in_entry =>
      d_51250_last_transaction__4115_mail_in_entry,
  );
export const get_d_51252_last_transaction__4117_cleared_fc_credit =
  createDeepEqualSelector(
    s.d_51252_last_transaction__4117_cleared_fc_credit_s,
    d_51252_last_transaction__4117_cleared_fc_credit =>
      d_51252_last_transaction__4117_cleared_fc_credit,
  );

export const getCurrentWeekSpentTokens = s.this_week_spent_tokens_s;

export const getCurrentWeekWonCredits = s.this_week_won_credits_s;

export const getPrevWeekSpentTokens = s.prev_week_spent_tokens_s;

export const getPrevWeekWonCredits = s.prev_week_won_credits_s;

export const makeMarketsMapByClassCode = (conflictKey: string | undefined) =>
  createSelector(
    (state: TGlobalAppState) =>
      getBetSupermarket(state)[conflictKey ?? ""]?.markets,
    markets => {
      if (!markets) {
        return {};
      }
      const marketsMap: IStringMap<IBetMarketLocal[]> = {};
      markets.forEach(market => {
        market.market_class_codes.forEach(classCode => {
          if (!marketsMap[classCode]) {
            marketsMap[classCode] = [];
          }
          marketsMap[classCode].push(market);
        });
      });
      return marketsMap;
    },
  );

export const get_d_51202_last_v2_weekly_leaderboard_bonus =
  createDeepEqualSelector(
    s.d_51202_last_v2_weekly_leaderboard_bonus_s,
    d_51202_last_v2_weekly_leaderboard_bonus_s =>
      d_51202_last_v2_weekly_leaderboard_bonus_s,
  );

export const get_d_51207_last_v4_xpoints_bonus = createDeepEqualSelector(
  s.d_51207_last_v4_xpoints_bonus_s,
  d_51207_last_v4_xpoints_bonus_s => d_51207_last_v4_xpoints_bonus_s,
);

export const get_d_51206_last_v2_completed_daily_challenge =
  createDeepEqualSelector(
    s.d_51206_last_v2_completed_daily_challenge_s,
    d_51206_last_v2_completed_daily_challenge_s =>
      d_51206_last_v2_completed_daily_challenge_s,
  );

export const get_d_51231_last_v3_purchase_error_order = createDeepEqualSelector(
  s.d_51231_last_v3_purchase_error_order_s,
  d_51231_last_v3_purchase_error_order_s =>
    d_51231_last_v3_purchase_error_order_s,
);

export const get_d_51232_last_v3_purchase_success_transaction =
  createDeepEqualSelector(
    s.d_51232_last_v3_purchase_success_transaction_s,
    d_51232_last_v3_purchase_success_transaction_s =>
      d_51232_last_v3_purchase_success_transaction_s,
  );

export const getTotalParlayOddsCount = createSelector(
  s.shopping_cart_s,
  PickUtils.getParlayOddsCoeff,
);

export const makeConflictClassByCodeFactory = (classCode: number | undefined) =>
  createSelector(getSportsBookConfig, config => {
    const conflictClass = config.conflict_classes.find(
      input => input.code === classCode,
    );
    if (!conflictClass) {
      return null;
    }
    const copy: Data__SB_Conflict_Class_Def = {
      ...conflictClass,
      market_classes: [...conflictClass.market_classes].filter(
        el => el.tab_pos > 0,
      ),
    };
    copy.market_classes.sort((a, b) => a.tab_pos - b.tab_pos);

    return copy;
  });

export const getBalanceInSelectedCurrency = createSelector(
  getCurrencyMode,
  getCustomerProfile,
  CoreSelectors.Balances.playableTotalFliffCashBalance,
  (currencyMode, profile, playableTotalFliffCashBalance) => {
    const isModeCash = UIConstants.isModeCash(currencyMode);
    if (isModeCash) {
      return playableTotalFliffCashBalance;
    }
    return profile.amount_v2_tokens;
  },
);

export const getCartRawTotalByPickType = createSelector(
  getShoppingCart,
  getCurrencyMode,
  getCartPickType,
  (shoppingCart, currencyMode, pickType) =>
    PickUtils.calcRawAmount(
      PickUtils.calcRawTotalBetAmountImpl(shoppingCart, currencyMode, pickType),
      1,
    ),
);

export const getIsCartBalanceExceededByPickType = createSelector(
  getBalanceInSelectedCurrency,
  getCartRawTotalByPickType,
  (balanceInCurrency, rawCartTotal) => rawCartTotal > balanceInCurrency,
);

export const makeIsEventBettingAvailable = (conflictFkey: string) =>
  createSelector(getEvents, events =>
    Boolean(AppUtils.getEventByConflictKey(events, conflictFkey)),
  );

export const isShoppingCartUnavailable = createSelector(
  s.shopping_cart_s,
  CoreSelectors.events,
  getProposalsMap,
  s.bet_supermarket_s,
  (cartItems, events, proposalsMap, betSupermarket) => {
    return Object.values(cartItems.items).every(cartItem => {
      const isBettingEventAvailable = Boolean(
        AppUtils.getEventByConflictKey(events, cartItem.event.conflict_fkey),
      );
      const supermarketBetProposal = ProposalUtils.getBetProposalByProposalFkey(
        cartItem.proposal_fkey,
        cartItem.event.conflict_fkey,
        proposalsMap,
        betSupermarket,
      );

      return ProposalUtils.isSelectionUnavailable(
        cartItem,
        supermarketBetProposal,
        isBettingEventAvailable,
      );
    });
  },
);

export const makeIsSelectionUnavailableForBetting = (
  selection: Data__SB_ShoppingCartProposalItem,
) =>
  createSelector(
    makeIsEventBettingAvailable(selection.event.conflict_fkey),
    makeMainBetProposal(selection.proposal_fkey, selection.event.conflict_fkey),
    (isBettingEventAvailable, supermarketBetProposal) =>
      ProposalUtils.isSelectionUnavailable(
        selection,
        supermarketBetProposal,
        isBettingEventAvailable,
      ),
  );

export const makeSelectionCoeffConfig = (
  cartItem: Data__SB_ShoppingCartProposalItem,
) =>
  createSelector(
    makeIsSelectionUnavailableForBetting(cartItem),
    makeMainBetProposal(cartItem.proposal_fkey, cartItem.event.conflict_fkey),
    (isSelectionUnavailableForBetting, mainBetProposal) => {
      if (isSelectionUnavailableForBetting) {
        return { isChanged: true, coeff: "N/A" };
      }
      if (cartItem.coeff !== mainBetProposal?.coeff) {
        return {
          isChanged: true,
          coeff: UIPickUtils.getPickCoefficient(
            mainBetProposal?.coeff || 0,
            cartItem.t_142_selection_param_1,
          ),
        };
      }
      if (cartItem.isReplaced) {
        return {
          isChanged: true,
          coeff: UIPickUtils.getPickCoefficient(
            cartItem.coeff,
            cartItem.t_142_selection_param_1,
          ),
        };
      }

      return {
        isChanged: false,
        coeff: UIPickUtils.getPickCoefficient(
          cartItem.coeff,
          cartItem.t_142_selection_param_1,
        ),
      };
    },
  );

export const makeSelectionForReplacement = (
  cartItem: Data__SB_ShoppingCartProposalItem,
) =>
  createDeepEqualSelector(
    CoreSelectors.events,
    getProposalsMap,
    getBetSupermarketDeepEqual,
    makeIsSelectionUnavailableForBetting(cartItem),
    (events, proposalsMap, betSupermarket, isSelectionUnavailable) => {
      if (isSelectionUnavailable) {
        return PickUtils.getCartItemForReplacement(
          betSupermarket,
          proposalsMap,
          cartItem,
          AppUtils.getEventByConflictKey(events, cartItem.event.conflict_fkey),
        );
      }
      return null;
    },
  );

export const makeIsSingleSelectionInvalid = (
  selection: Data__SB_ShoppingCartProposalItem,
) =>
  createSelector(getCartItems, getCartPickType, (cartItems, pickType) =>
    PickUtils.isSingleSelectionInvalid(selection, cartItems, pickType),
  );

export const makeMainBetProposal = (
  proposalFkey: string | undefined,
  conflictFkey: string | undefined,
) =>
  createSelector(
    getProposalsMap,
    getBetSupermarket,
    (proposalsMap, betSupermarket) =>
      ProposalUtils.getBetProposalByProposalFkey(
        proposalFkey,
        conflictFkey,
        proposalsMap,
        betSupermarket,
      ),
  );

export const isOptionSelectedFactory = () =>
  createSelector(
    getCartItems,
    (_: TAnyAlias, proposalKey: string, groupId: string) => ({
      proposalKey,
      groupId,
    }),
    (cartItems, { proposalKey, groupId }) => {
      if (
        !groupId ||
        !cartItems[groupId] ||
        !cartItems[groupId].proposal_fkey
      ) {
        return false;
      }
      return cartItems[groupId].proposal_fkey === proposalKey;
    },
  );

export const makeSelectionNameChangeConfig = (
  selection: Data__SB_ShoppingCartProposalItem,
) =>
  createDeepEqualSelector(
    makeIsSelectionUnavailableForBetting(selection),
    makeMainBetProposal(selection.proposal_fkey, selection.event.conflict_fkey),
    (isSelectionUnavailableForBetting, mainBetProposal) => {
      if (isSelectionUnavailableForBetting) {
        return {
          isChanged: true,
          selectionName: selection.t_141_selection_name,
        };
      }
      if (
        selection.t_141_selection_name !== mainBetProposal?.t_141_selection_name
      ) {
        return {
          isChanged: true,
          selectionName: mainBetProposal?.t_141_selection_name,
        };
      }
      if (selection.isReplaced) {
        return {
          isChanged: true,
          selectionName: selection.t_141_selection_name,
        };
      }

      return {
        isChanged: false,
        selectionName: selection.t_141_selection_name,
      };
    },
  );

export const makeStraightSelectionRiskAmount = (key: string) =>
  createSelector(getCartItems, getCurrencyMode, (cartItems, currencyMode) => {
    if (UIConstants.isModeCash(currencyMode)) {
      return cartItems[key].placed_amount__fliff_cash;
    }
    return cartItems[key].placed_amount__gold_coins;
  });

export const getFCMinPickAmountByPickType = createSelector(
  getSportsBookConfig,
  getCartPickType,
  (config, pickType) => {
    if (
      pickType === PickType.CONST_82_PARLAY ||
      pickType === PickType.CONST_83_SAME_GAME_PARLAY
    ) {
      return config.fliff_cash_parlay__min_pick_amount_in_cents;
    }
    return config.fliff_cash_straight__min_pick_amount_in_cents;
  },
);
export const makeStraightSelectionPayoutAmount = (key: string) =>
  createSelector(
    getCartItems,
    makeStraightSelectionRiskAmount(key),
    (cartItems, selectionPlacedAmount) =>
      PickUtils.calcRawAmount(
        selectionPlacedAmount,
        PickUtils.usaCoeffToEuCoeff(cartItems[key].coeff),
      ),
  );

export const makeIsInputPayoutMaxAmountExceeded = (payoutAmount: number) =>
  createSelector(
    getSportsBookConfig,
    getCurrencyMode,
    getCartPickType,
    (config, currencyMode, pickType) => {
      if (UIConstants.isModeCash(currencyMode)) {
        if (pickType === PickType.CONST_81_STRAIGHT) {
          return payoutAmount > config.fliff_cash_straight__max_payout_in_cents;
        }
        return payoutAmount > config.fliff_cash_parlay__max_payout_in_cents;
      }
      if (pickType === PickType.CONST_81_STRAIGHT) {
        return payoutAmount > config.credits_straight__max_payout_in_cents;
      }
      return payoutAmount > config.credits_parlay__max_payout_in_cents;
    },
  );

export const makeSelectionFCLimitValueByKey = (
  selectionKey: string | undefined,
) =>
  createSelector(getCustomerProfile, getCartItems, (profile, cartItems) => {
    if (!selectionKey) {
      return null;
    }
    const selection = cartItems[selectionKey].max_risk_limit;

    const limit = AppUtils.extractFliffCashLimit(profile.fc_limits, selection);
    if (limit) {
      return limit.max_amount_in_cents;
    }
    return null;
  });

export const getGCMinPickAmountByPickType = createSelector(
  getSportsBookConfig,
  getCartPickType,
  (config, pickType) => {
    if (
      pickType === PickType.CONST_82_PARLAY ||
      pickType === PickType.CONST_83_SAME_GAME_PARLAY
    ) {
      return config.credits_parlay__min_pick_amount_in_cents;
    }
    return config.credits_straight__min_pick_amount_in_cents;
  },
);

export const getStraightCartTotalCartPayout = createSelector(
  getShoppingCart,
  getCurrencyMode,
  (shoppingCart, currencyMode) => {
    const rawPayout = Object.values(shoppingCart.items).reduce(
      (acc, curr) =>
        acc +
        PickUtils.calcRawPayoutAmountImpl(curr, shoppingCart, currencyMode),
      0,
    );
    return PickUtils.calcRawAmount(rawPayout, 1);
  },
);

export const getIsStraightAnyPickPayoutExceeded = createSelector(
  getCartItems,
  getSportsBookConfig,
  getCurrencyMode,
  getCustomerProfile,
  (cartItems, config, currencyMode, profile) => {
    const values = Object.values(cartItems);
    if (UIConstants.isModeCash(currencyMode)) {
      return values.some(cartItem => {
        const isPayoutExceeded =
          PickUtils.recalcRawAmountForCoeff(
            cartItem.placed_amount__fliff_cash,
            PickUtils.usaCoeffToEuCoeff(cartItem.coeff),
          ) > config.fliff_cash_straight__max_payout_in_cents;
        const limit = AppUtils.extractFliffCashLimit(
          profile.fc_limits,
          cartItem.max_risk_limit,
        );
        const isLimitExceeded = limit
          ? cartItem.placed_amount__fliff_cash > limit.max_amount_in_cents
          : false;
        return isLimitExceeded || isPayoutExceeded;
      });
    }
    return values.some(
      cartItem =>
        PickUtils.recalcRawAmountForCoeff(
          cartItem.placed_amount__gold_coins,
          PickUtils.usaCoeffToEuCoeff(cartItem.coeff),
        ) > config.credits_straight__max_payout_in_cents,
    );
  },
);

export const getParlayPickTotalRiskAmount = createSelector(
  getCurrencyMode,
  getShoppingCart,
  (currencyMode, shoppingCart) => {
    if (UIConstants.isModeCash(currencyMode)) {
      return shoppingCart.totalPlacedFliffCashAmount;
    }
    return shoppingCart.totalPlacedGoldCoinsAmount;
  },
);

export const getMaxPayoutByPickType = createSelector(
  getCartPickType,
  getSportsBookConfig,
  getCurrencyMode,
  (pickType, config, currencyMode) => {
    if (UIConstants.isModeCash(currencyMode)) {
      if (pickType === PickType.CONST_81_STRAIGHT) {
        return CashAmountFormatters.toFliffCash(
          config.fliff_cash_straight__max_payout_in_cents,
        );
      }
      if (
        pickType === PickType.CONST_82_PARLAY ||
        pickType === PickType.CONST_83_SAME_GAME_PARLAY
      ) {
        return CashAmountFormatters.toFliffCash(
          config.fliff_cash_parlay__max_payout_in_cents,
        );
      }
    } else {
      if (pickType === PickType.CONST_81_STRAIGHT) {
        return CoinsAmountFormatters.toGoldCoins(
          config.credits_straight__max_payout_in_cents,
        );
      }
      if (
        pickType === PickType.CONST_82_PARLAY ||
        pickType === PickType.CONST_83_SAME_GAME_PARLAY
      ) {
        return CoinsAmountFormatters.toGoldCoins(
          config.credits_parlay__max_payout_in_cents,
        );
      }
    }
    return "[unknown]";
  },
);

export const getMaxNumberOfSelectionsByBPickType = createSelector(
  getSportsBookConfig,
  getCartPickType,
  (config, pickType) => {
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      return config.max_number_of_selections_in_sgp_mode;
    }
    if (pickType === PickType.CONST_82_PARLAY) {
      return config.max_number_of_selections_in_parlay_mode;
    }
    return config.max_number_of_selections_in_straight_mode;
  },
);

export const getIsMaxNumberOfSelectionsExceededByPickType = createSelector(
  getMaxNumberOfSelectionsByBPickType,
  getCartItemsCount,
  (maxNumberOfSelections, cartItemsCount) =>
    cartItemsCount > maxNumberOfSelections,
);

export const getMaxNumberOfSelectionsExceededDisclaimer = createSelector(
  getMaxNumberOfSelectionsByBPickType,
  getCartPickType,
  (maxNumberOfSelections, pickType) => {
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      return `Max number of selections in SGP mode is ${maxNumberOfSelections}`;
    }
    if (pickType === PickType.CONST_82_PARLAY) {
      return `Max number of selections in Parlay mode is ${maxNumberOfSelections}`;
    }
    return `Max number of selections in Straight mode is ${maxNumberOfSelections}`;
  },
);

export const getIsPlacedBettingEventsAvailable = createSelector(
  CoreSelectors.events,
  getCartItems,
  (events, cartItems) =>
    PickUtils.isPlacedBettingEventsAvailable(cartItems, events),
);

export const getIsMinFCPlacedAmountExceededByPickType = createSelector(
  getCartItems,
  getFCMinPickAmountByPickType,
  getCartPickType,
  getParlayPickTotalRiskAmount,
  (cartItems, minFCRiskAmount, pickType, parlayTotalRiskAmount) => {
    if (pickType === PickType.CONST_81_STRAIGHT) {
      return Object.values(cartItems).some(cartItem => {
        return cartItem.placed_amount__fliff_cash < minFCRiskAmount;
      });
    }
    return parlayTotalRiskAmount < minFCRiskAmount;
  },
);

export const getMaxAmountPointsForPlacePicks =
  s.amount_max_v3_xpoints_for_place_pick_s;

export const getInsufficientBalanceAmount = createSelector(
  getBalanceInSelectedCurrency,
  getCartRawTotalByPickType,
  (balance, rawTotalRisk) => rawTotalRisk - balance,
);

export const getIsSGPAllowedToSubmit = createSelector(
  getCartItems,
  PickUtils.isSGPAllowed,
);

export const getShouldLoadRequestNewCoeff = (state: TGlobalAppState) =>
  s.getShoppingCartUIS(state).sgpStatusData.shouldRequestNewCoeff;

export const getSGPCoeff = (state: TGlobalAppState) =>
  s.getShoppingCartUIS(state).sgpStatusData.totalCoeff;

export const getDidCoeffOnSubmitChange = (state: TGlobalAppState) =>
  s.getShoppingCartUIS(state).sgpStatusData.coeffOnSubmitDidChange;

export const getSGPCoeffRequestFatalErrorCodes = (state: TGlobalAppState) =>
  getSportsBookConfig(state).error_codes_for_fatal_sqp_quote_error;

export const getDismissErrorCodesOnGetSGPCoeff = (state: TGlobalAppState) =>
  getSportsBookConfig(state).error_codes_for_hide_in_ticket_sqp_error;

export const getShouldUpdateSGPCoeff = createSelector(
  getShouldLoadRequestNewCoeff,
  getCartPickType,
  getCartItems,
  getIsMaxNumberOfSelectionsExceededByPickType,
  (
    shouldRequestNewCoeff,
    pickType,
    cartItems,
    isMaxNumberOfSelectionsExceeded,
  ) => {
    if (!shouldRequestNewCoeff) {
      return false;
    }
    if (pickType !== PickType.CONST_83_SAME_GAME_PARLAY) {
      return false;
    }
    if (isMaxNumberOfSelectionsExceeded) {
      return false;
    }
    if (Object.keys(cartItems).length <= 1) {
      return false;
    }
    if (!PickUtils.isSGPAllowed(cartItems)) {
      return false;
    }
    return true;
  },
);

export const getHaveOddsChangedState = createSelector(
  getCartItems,
  getProposalsMap,
  CoreSelectors.events,
  getIsPlacedBettingEventsAvailable,
  getDidCoeffOnSubmitChange,
  getCartPickType,
  getBetSupermarket,
  (
    cartItems,
    proposalsMap,
    events,
    placedBettingAvailable,
    coeffOnSubmitDidChange,
    pickType,
    betSupermarket,
  ) => {
    const shouldAcceptChangesBeforeSubmit =
      ProposalUtils.detectChanges(
        cartItems,
        proposalsMap,
        betSupermarket,
        events,
        pickType,
      ) || !placedBettingAvailable;

    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      return coeffOnSubmitDidChange || shouldAcceptChangesBeforeSubmit;
    }

    return shouldAcceptChangesBeforeSubmit;
  },
);

export const getParlayCartTotalPayout = createSelector(
  getCurrencyMode,
  getShoppingCart,
  getCartPickType,
  getSGPCoeff,
  getParlayPickTotalRiskAmount,
  getIsParlayAllowedToSubmit,
  (
    currencyMode,
    shoppingCart,
    pickType,
    sgpCoeff,
    parlayPickTotalRiskAmount,
    isParlayAllowedToSubmit,
  ) => {
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      if (sgpCoeff === 0) {
        return 0;
      }
      return PickUtils.calcRawPayoutAmountForSGPPickType(
        parlayPickTotalRiskAmount,
        sgpCoeff,
      );
    }

    if (!isParlayAllowedToSubmit) {
      return 0;
    }
    return PickUtils.calcRawPayoutAmountForWholeCart(
      shoppingCart,
      currencyMode,
    );
  },
);

export const getIsParlayPayoutAmountExceeded = createSelector(
  getParlayCartTotalPayout,
  getSportsBookConfig,
  getCurrencyMode,
  (payoutAmount, config, currencyMode) => {
    if (UIConstants.isModeCash(currencyMode)) {
      return payoutAmount > config.fliff_cash_parlay__max_payout_in_cents;
    }
    return payoutAmount > config.credits_parlay__max_payout_in_cents;
  },
);

export const getIsCartPaxPayoutExceeded = createSelector(
  getCartPickType,
  getIsStraightAnyPickPayoutExceeded,
  getIsParlayPayoutAmountExceeded,
  (pickType, isStraightPayoutExceeded, isParlayPayoutExceeded) => {
    if (pickType === PickType.CONST_81_STRAIGHT) {
      return isStraightPayoutExceeded;
    }
    return isParlayPayoutExceeded;
  },
);

export const getParlayTotalCoeff = createSelector(
  getCartPickType,
  getSGPCoeff,
  getTotalParlayOddsCount,
  (pickType, totalSGPCoeff, totalParlayCoeff) => {
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      return totalSGPCoeff;
    }
    return totalParlayCoeff;
  },
);

export const getIdentityVerificationStatus = (state: TGlobalAppState) =>
  getCustomerProfile(state).identity_verification_status;

export const getIsTicketParlayTotalCoeffVisible = createSelector(
  getParlayTotalCoeff,
  getCartPickType,
  getIsSGPAllowedToSubmit,
  getIsParlayAllowedToSubmit,
  getCartItemsCount,
  (totalCoeff, pickType, isSGPAllowed, isParlayAllowed, cartItemsCount) => {
    if (totalCoeff === 0) {
      return false;
    }
    if (cartItemsCount <= 1) {
      return false;
    }
    if (pickType === PickType.CONST_82_PARLAY) {
      return isParlayAllowed;
    }
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      return isSGPAllowed;
    }
    return false;
  },
);

export const getIsRecentSelectionsBelongToSameGame = createSelector(
  getCartItems,
  cartItems => {
    const values = Object.values(cartItems);
    if (values.length === 0) {
      return false;
    }
    // we consider as "recent" first 2 from the list.
    return values
      .slice(0, 2)
      .every(
        cartItem =>
          cartItem.event.conflict_fkey === values[0].event.conflict_fkey,
      );
  },
);

export const getIsSGPDisclaimerVisible = createSelector(
  getIsSGPAllowedToSubmit,
  getCartItemsCount,
  getCartPickType,
  (isSGPAllowedToSubmit, cartItemsCount, pickType) =>
    !isSGPAllowedToSubmit &&
    cartItemsCount > 1 &&
    pickType === PickType.CONST_83_SAME_GAME_PARLAY,
);

export const getPermissionsAndData = s.sports_book_pdata_s;

export const getProfilePersonalDetails = (state: TGlobalAppState) =>
  getCustomerProfile(state).personal_info;

export const getAmountRequiredForSecondLevelVerification = (
  state: TGlobalAppState,
) => getSportsBookConfig(state).amount_which_requires_level_2_verification;

export const getAvailablePaymentMethods = (state: TGlobalAppState) =>
  getCustomerProfile(state).avalable_payment_vendors;

export const getReferralBonusReceivedAmountPerFriendPurchase = createSelector(
  getSportsBookConfig,
  config => config.referral_bonus__percent_from_child_purchase,
);

export const getReferralMaxBonusReceivedAmount = createSelector(
  getSportsBookConfig,
  config => config.xp_bonus_referral__max_amount_per_child,
);

export const getInviteFriendsDescriptions = createSelector(
  getSportsBookConfig,
  config => ({
    title: config.referral_bonus__text_1,
    disclaimer: config.referral_bonus__text_2,
  }),
);

export const getBonusCode = createSelector(getCustomerProfile, profile => {
  return profile.own_bonus_code;
});

export const getDoubleBonusVisible = createSelector(
  getPermissionsAndData,
  getCurrencyMode,
  pdata => {
    return pdata.cashier_cash_banner_skin > 0;
  },
);

export const getDoubleBonusBannerTitleNew = createSelector(
  getPermissionsAndData,
  getCurrencyMode,
  pdata => {
    return pdata.cashier_cash_banner_title;
  },
);

export const getDoubleBonusBannerTextNew = createSelector(
  getPermissionsAndData,
  getCurrencyMode,
  pdata => {
    return pdata.cashier_cash_banner_text;
  },
);

export const getXPRequiredForBonus = (state: TGlobalAppState) =>
  getSportsBookConfig(state).xp_bonus_progress__amount_required_for_bonus;

export const getNextBonusXPAmount = (state: TGlobalAppState) =>
  getSportsBookConfig(state).xp_bonus_progress__amount_bonus;

export const getIsPurchaseProcessing = (state: TGlobalAppState) =>
  getCustomerProfile(state).open_orders_count !== 0;

export const getEarnedBonusXP = (state: TGlobalAppState) =>
  getCustomerProfile(state).amount_xp_bonus_progress_tokens;

export const getXPEarningProgress = createDeepEqualSelector(
  getXPRequiredForBonus,
  getEarnedBonusXP,
  (xpRequiredForBonus, earnedBonusXP) =>
    ((earnedBonusXP % xpRequiredForBonus) / xpRequiredForBonus) * 100,
);

export const getRewardEarnItemDescs = createDeepEqualSelector(
  getSportsBookConfig,
  config => ({
    text1: config.server_config__text_11,
    text2: config.server_config__text_21,
  }),
);
export const getPersonalDetailsVerificationStatus = (state: TGlobalAppState) =>
  getCustomerProfile(state).account_details_status;

export const getAppUIMode = createSelector(
  getPermissionsAndData,
  AppSelectors.isAuthenticated,
  (pdata, isAuthenticated) => {
    if (!isAuthenticated) {
      return pdata.ui_mode;
    }
    return pdata.ui_mode;
  },
);
export const getIsFullUIModeEnabled = createSelector(getAppUIMode, appUIMode =>
  AppUIModesManager.isServerFullUIModeEnabled(appUIMode),
);

export const getIsLightUIModeEnabled = createSelector(getAppUIMode, appUIMode =>
  AppUIModesManager.isServerLightModeEnabled(appUIMode),
);

export const getIsIOSLightUIModeEnabled = createSelector(
  getAppUIMode,
  appUIMode => AppUIModesManager.isServerIOSLightUIMode(appUIMode),
);

export const getTotalMoneyPurchaseCount = (state: TGlobalAppState) =>
  getCustomerProfile(state).s_3_total_money_in_purchase_count;

export const getCoinsPurchaseDisclaimer = (state: TGlobalAppState) =>
  getSportsBookConfig(state).server_config__text_41;
