import { IFCMTransaction } from "server/core/data/objects";
import {
  BetProposalTypes,
  ChannelType,
  ConflictType,
  GiftCardRedemptionConfigType,
  HeraldProtocolVersion,
  IdentityVerificationStatus,
  MarketOfferSelectionChangeType,
  OwnershipAttributeCode,
  PickSelectionState,
  PickType,
  PlacePickPlayThroughMode,
  PlacePicksOperationStatus,
  PublicChallengeType,
  RawPeriodType,
  ServerUIMode,
  TDiffUpdateType,
  TEventStatus,
  UpdatePersonalDataMode,
  UserAccountDetailsStatus,
  WithdrawalBankAccountType,
  WithdrawalMethod,
} from "server/legacyCore/data/constants";
import { Data__SB_Unfinished_Order } from "server/sharedCore/data/objects";
import { StakeCategory } from "server/social/data/constants";
import { IStringMap, TCurrencyMode, TUSAStatesValues } from "src/types";
import { IBetMarketLocal, TMainProposalLocal } from "./objects_local";
import {
  BundleBonusSkin,
  BundleOfferSkin,
  PaymentVendor,
} from "server/core/data/constants";

export class DataReq__SB_Profile_Meta {
  id_51202: number;
  id_51203: number;
  id_51204: number;
  id_51207: number;
  id_51206: number;
  id_51221: number;
  id_51231: number;
  id_51232: number;
  id_51241: number;
  id_51250: number;
  id_51251: number;
  id_51252: number;
  id_51253: number;
  id_51254: number;
  id_51255: number;
}

export class DataReq__SB_RateUsInfo {
  account_id: number;
  display_stamp: number;
  action_stamp: number;
  action_code: number;
  nag_debug_reason: string;
}

// 2021-07-17 / Ivan / abstract parent is no longer needed
// 2020-09-26 / Ivan / we are switching to new kind of identifiers
// this is the base class, we will be extended it from both old and new data classes
export class DataReq__SB_Proposal_PickSelection {
  // export class DataReq__SB_Abstract_PickSelection {
  // coeff on which user has agreed to place bet
  coeff: number;
  // }
  //
  // export class DataReq__SB_Proposal_PickSelection extends DataReq__SB_Abstract_PickSelection {
  // 2020-10-26 / Ivan / switching from id + hash to single identifier - proposal_fkey
  proposal_fkey: string;
}

export class DataReq__SB_Pick {
  // straight vs parlay
  type: number;
  // what currency was used
  currency_code: number;
  // the amount
  risk_amount: number;
  // 2022-04-05 / Ivan / introduce server side control over combined coeff
  confirmed_server_quote_coeff: number;
  // re-calculated on client side, we will use it to verify that client and server perform identical calculation operations
  expected_payout_amount: number;
  // list of selections (one for straight, many for parlay)
  // 2020-09-26 / Ivan / we are switching to new kind of identifiers
  selections: DataReq__SB_Proposal_PickSelection[];
  //  selections: DataReq__SB_PickSelection[];
  copied_from_pick_fkey?: string;
}

export class DataReq__SB_PlacePickRequest {
  // Unique id of the cart - in order to track 'double submits'. Is updated on EVERY cart change.
  // One exception – doesn't update on selection amount change.
  unique_shopping_cart_id: string;
  // allow user to enforce 'double submit' / enforce_create_when_shopping_cart_id_exists
  verify_mode: number;
  // single group in case of parlay, many groups in case of straight
  picks: DataReq__SB_Pick[];
}

// data for withdraw request
export class DataReq__SB_WithdrawRequest {
  address: string;
  address2: string;
  city: string;
  country_state: TUSAStatesValues;
  country: string;
  zip_code: string;
  // 2021-10-21 / Ivan / introduce date_of_birth to all requests
  // example - "1980-12-31"
  date_of_birth: string;
  // 2021-12-16 / Ivan / introduce first_name and last_name as parameters to all requests
  first_name: string;
  last_name: string;

  amount_in_cents: number;
  // 2020-09-23 / Ivan / tax percent applied when calculating net value (usually 0% or 20%)
  tax_in_percents: number;
  // 2020-09-23 / Ivan / net amount to be received by user
  net_amount_in_cents: number;

  // 2021-06-02 / Ivan / added new attributes for payment method
  payment_method: WithdrawalMethod;
  bank_wire__routing_number: string | null;
  bank_wire__account_number: string | null;
  bank_wire__account_type: WithdrawalBankAccountType | null;
  cash_app__cashtag: string | null;
  bitcoin__btcaddress: string | null;
  skrill__email_address: string | null;

  // 2021-10-31 / Ivan / introduce general concept for payment tokens
  payment_token: string | null;
}

// 2021-06-18 / Ivan / data for request exchange of XP
export class DataReq__SB_XP_Exchange {
  offer_id: number;
}

// 2020-08-26 / Ivan / switching to universal concept for client side controlled period
export class DataReq__SB_ClientControlledPeriod {
  // unique identifier supplied by client which is returned by server as part of the response
  request_id: string;
  // stamps for period defined by client, -1 means that client wants 'recent' records (usually last 24 hours, or last 5 items)
  start_stamp_seconds_utc: number;
  end_stamp_seconds_utc: number;
}

// 2021-07-13 / Ivan / introduce separate request object for picks history - support for other user profile / stats
export class DataReq__SB_Picks_Period_Report extends DataReq__SB_ClientControlledPeriod {
  // > 0 means other user, == 0 means this user
  other_user_id: number;
}

// 2020-08-26 / Ivan / switching to universal concept for server side controlled period
// 2020-07-30 / Ivan / data request for leaderboard
export class DataReq__SB_ServerControlledPeriod {
  // base stamp - logical meaning is 'today', implementation uses 'stamp for first second of (today)' - whatever today is
  // -1 on first call (allow server to calculate it), send server value along with offset on subsequent calls
  anchor_stamp_seconds_utc: number;

  // offset from base stamp - in days, weeks or months (depending on request)
  offset: number;
}

export class DataReq__SB_Validate_Register {
  username: string;
  email: string;
  is_background_check: boolean;
}

export class Data__SB_Base_Report_for_Client_Controlled_Period {
  period_request_id: string; // id supplied by client, for calendar requests - usually in universal string format: 2020-02-28
  period_start_stamp_seconds_utc: number;
  period_end_stamp_seconds_utc: number;
}

export class Data__SB_Base_Report_for_Server_Controlled_Period {
  // type of the period - usually daily
  period_type: RawPeriodType;
  // stamp for start of the period - not actually used by mobile app
  period_start_stamp_seconds_utc: number;
  // start/end dates in unified string format (example: 2020-07-21) - not actually used by mobile app
  period_visual_start_date: string;
  period_visual_end_date: string;

  // base stamp - logical meaning is 'today', implementation uses 'stamp for first second of (today)' - whatever today is
  // calculated from server on first call, should be send back to server / along with offset / on following calls
  report_anchor_stamp_seconds_utc: number;

  // offset from anchor_stamp - in days, weeks or months (depending on report type)
  report_anchor_offset: number;

  // if false - there is no more data in this direction,
  // if true - app can resend the request with offset +/- 1 and expect data for the next period in that direction
  has_prev: boolean;
  has_next: boolean;
}

export interface IArenaDef {
  arena_code: string;
  arena_name: string;
  arena_icon_url: string;
  arena_icon_v2_url: string;
}

export interface IPlayThroughBundleBonus {
  amount_fliff_cash: number;
  bundle_code: string;
  level_2_skin: BundleBonusSkin;
  multiplier_in_percents: number;
  serialized_goods: string;
}

export interface IPlayThroughBundle {
  price_in_cents: number;
  v_bonus_banner: string;
  amount_social_coins: number;
  amount_fliff_cash: number;
  level_1_skin: BundleOfferSkin;
  level_2_data: IPlayThroughBundleBonus[] | null;
  bundle_code: string;
  serialized_goods: string;
  min_next_obtain_stamp_sec: number | undefined;
}

export interface IPlayThroughData {
  data: IPlayThroughBundle[];
}

// 2022-01-15 / Ivan / introduce concept for default + per user permissions
export class Data__SB_Permissions_And_Common_Data {
  // classic UI mode: 100, 200, 300
  ui_mode: ServerUIMode;
  // control if user is allowed to post orders
  enable_gift_cards: boolean;
  // filter over conflict permission categories - what subset of conflicts should be displayed
  conflict_pcats: Set<number>;

  // 2022-01-16 / Ivan / moved to common permissions and data section
  // 2021-12-23 / Ivan / introducing zmq support
  herald_endpoint: string;
  herald_protocol_version: HeraldProtocolVersion;
  // 2021-12-23 / Ivan / pass last known ip address from server
  caller_ip_address: string;

  // 2022-12-05 / Ivan / new server side config texts
  cashier_social_banner_skin: number;
  cashier_social_banner_title: string;
  cashier_social_banner_text: string;
  cashier_cash_banner_skin: number;
  cashier_cash_banner_title: string;
  cashier_cash_banner_text: string;
  cashier_allow_fc_mode: boolean;
  cashier_social_banner_url: string;
  cashier_cash_banner_url: string;
  active_feed_arenas: IArenaDef[];
  leaderboard_arenas: IArenaDef[];
  leaderboard_disabled: boolean;
  leaderboard_disabled_dialog_title: string;
  leaderboard_disabled_dialog_text: string;
  core_sever_public_endpoint: string;
  core_sever_private_endpoint: string;
  proposals_feed_endpoint: string;
  we_are_under_construction_skin: number;
  config__personalized__20021: number;
  config__personalized__20022: number;
  config__personalized__20031: number;
  config__personalized__20032: number;
  config__personalized__20041: number;
  config__personalized__20042: number;
  play_through_data: IPlayThroughData;

  // 2022-12-22 / Ivan / added concept for global banner
  global_banner_image_url: string;
  global_banner_click_url: string;

  claim_v8_free_available_after_stamp_millis: number;

  static readonly EMPTY: Data__SB_Permissions_And_Common_Data = {
    ui_mode: 100,
    enable_gift_cards: false,
    conflict_pcats: new Set(),
    leaderboard_disabled: false,
    leaderboard_disabled_dialog_title: "",
    leaderboard_disabled_dialog_text: "",

    herald_endpoint: "",
    herald_protocol_version: 0,
    caller_ip_address: "",
    proposals_feed_endpoint: "",
    // 2022-12-05 / Ivan / new server side config texts
    cashier_social_banner_skin: 0,
    cashier_social_banner_title: "",
    cashier_social_banner_text: "",

    cashier_cash_banner_skin: 0,
    cashier_cash_banner_title: "",
    cashier_cash_banner_text: "",

    // 2022-12-22 / Ivan / added concept for global banner
    global_banner_image_url: "",
    global_banner_click_url: "",

    claim_v8_free_available_after_stamp_millis: -1,

    active_feed_arenas: [],
    leaderboard_arenas: [],
    cashier_allow_fc_mode: false,
    cashier_social_banner_url: "",
    cashier_cash_banner_url: "",
    core_sever_private_endpoint: "",
    core_sever_public_endpoint: "",

    we_are_under_construction_skin: 0,
    config__personalized__20041: 0,
    config__personalized__20021: 0,
    config__personalized__20022: 0,
    config__personalized__20031: 0,
    config__personalized__20032: 0,
    config__personalized__20042: 0,
    play_through_data: { data: [] },
  };
}

export class Data__SB_Config {
  obj_version: number;
  // 2020-05-01 / Ivan / do we want to introduce special meaning for <= 0 ? no referral program ?
  reward_cash_per_referral_in_cents: number;
  min_user_profile_id_which_requires_kyc_before_place_pick: number;
  // 2021-12-16 / Ivan / no longer used
  //  purchase_v2_bundle__max_tokens_allowed: number;
  //  claim_free_bundle__min_coins_amount: number;
  //  free_bundle_selector_in_cents: number;
  min_amount_for_withdraw_in_cents: number;

  // 2021-06-15 / Ivan / introduce server control on limits per payment type
  // 2021-12-16 / Ivan / no longer used
  //  withdraw_7302__BANK_WIRE__min_amount: number;
  //  withdraw_7302__BANK_WIRE__max_amount: number;
  // 2021-12-16 / Ivan / moving to user profile
  /*
  withdraw_7303__CASH_APP__min_amount: number;
  withdraw_7303__CASH_APP__max_amount: number;
  withdraw_7304__SKRILL__min_amount: number;
  withdraw_7304__SKRILL__max_amount: number;
  withdraw_7305__BITCOIN__min_amount: number;
  withdraw_7305__BITCOIN__max_amount: number;
  withdraw_7306__MAZOOMA_VERIFIED__min_amount: number;
  withdraw_7306__MAZOOMA_VERIFIED__max_amount: number;
  withdraw_7307__MAZOOMA_INSTANT__min_amount: number;
  withdraw_7307__MAZOOMA_INSTANT__max_amount: number;
  // 2021-03-19 / Ivan / introduce config constant for min days
  withdraw_min_days_between_withdraws: number;
  // 2021-10-19 / Ivan / introduce config constant for max days to process
  withdraw_max_days_to_process_withdraw: number;
// 2021-12-16 / Ivan / no longer used
  withdraw_tax_level_1_in_percents: number;
// 2021-12-16 / Ivan / moving to user profile
  withdraw_tax_level_2_in_percents: number;
*/

  max_number_of_selections_total: number;
  max_number_of_selections_in_straight_mode: number;
  max_number_of_selections_in_parlay_mode: number;
  max_number_of_selections_in_sgp_mode: number;

  fliff_cash_straight__min_pick_amount_in_cents: number;
  fliff_cash_straight__max_pick_amount_in_cents: number;
  fliff_cash_straight__max_payout_in_cents: number;
  fliff_cash_parlay__min_pick_amount_in_cents: number;
  fliff_cash_parlay__max_pick_amount_in_cents: number;
  fliff_cash_parlay__max_payout_in_cents: number;

  // 2020-12-21 / Ivan / moving to v2 currencies
  credits_straight__min_pick_amount_in_cents: number;
  credits_straight__max_pick_amount_in_cents: number;
  credits_straight__max_payout_in_cents: number;
  credits_parlay__min_pick_amount_in_cents: number;
  credits_parlay__max_pick_amount_in_cents: number;
  credits_parlay__max_payout_in_cents: number;

  // 2022-06-13 / Ivan / switching from RC to XP, tracking XP progress
  xp_bonus_progress__amount_required_for_bonus: number;
  xp_bonus_progress__amount_bonus: number;

  //  xp_bonus_initial_purchases__max_amount_social_coins: number;
  //  xp_bonus_initial_purchases__max_amount_fliff_cash: number;

  xp_bonus_referral__max_amount_per_child: number;
  xp_bonus_referral__percent_from_child_purchase: number;

  //  iap_promo_tokens_convert__amount_consume_tokens: number;
  //  iap_promo_tokens_convert__amount_gift_rewards_cash: number;

  channels: Array<Data__SB_Channel>;

  //2021-06-25 / Ivan / no longer used
  //  user_levels: Array<Data__SB_UserLevelDef>;
  //
  //  user_v2_goat_sublevel_points: number;
  //  user_v2_goat_sublevel_rc_bonus: number;

  // 2021-06-25 / Ivan / switch to XP
  //  v2_points_for_complete_single_challenge: number;
  //  v2_points_for_complete_all_daily_challenges: number;

  v3_xpoints_for_complete_single_challenge: number;
  v3_xpoints_for_complete_all_daily_challenges: number;

  // 2021-01-19 / Ivan / introduce list of error codes, for which mobile app should not display modal dialog
  error_codes_without_modal_error: number[];
  // 2022-04-27 / Ivan / add support for SGP quote fatal error
  error_codes_for_fatal_sqp_quote_error: number[];
  error_codes_for_hide_in_ticket_sqp_error: number[];

  // 2021-03-11 / Ivan / add separate config for onboarding screen ad
  onboarding_ad_amount_bonus: number;
  onboarding_ad_amount_purchase: number;

  // 2021-03-29 / Ivan / add config for Slide 'up to xx%' info labels
  max_cashback_in_promiles_for_slide: number;

  // 2021-05-19 / Ivan / introduce per-event configuration, mostly for 'More Markets' screen layout
  conflict_classes: Array<Data__SB_Conflict_Class_Def>;

  // 2022-01-15 / Ivan / introduce concept for default + per user permissions
  default_pdata: Data__SB_Permissions_And_Common_Data;

  // 2022-01-31 / Ivan / introduce support for persona v2
  amount_which_requires_level_2_verification: number;

  // 2022-02-10 / Ivan / introduce server control on config/promo messages for referral program
  referral_bonus__percent_from_child_purchase: number;
  referral_bonus__text_1: string;
  referral_bonus__text_2: string;
  server_config__text_11: string;
  server_config__text_21: string;

  // 2022-12-05 / Ivan / new server side config texts
  server_config__text_31: string;
  server_config__text_32: string;
  server_config__text_33: string;
  server_config__text_41: string;

  // 2022-12-20 / Ivan / add global flag which controls how users can register / login
  // 101 => disable sign-in via email
  auth_mode: number;

  // 2021-01-05 / Ivan / introduce default_ui_mode, personalized_ui_mode
  // TDB discussed, but something like
  // 100 => android minimal (startup), 200 => android standart (former light ui), 300 => ios (full ui)
  //  default_ui_mode: TServerUIMode;

  //  // 2021-12-23 / Ivan / introducing zmq support
  //  default_herald_endpoint: string;
  //
  //  // 2021-12-23 / Ivan / pass last known ip address from server
  //  caller_ip_address: string;

  // 2021-12-16 / Ivan / moving to user profile
  //  enable_gift_cards: boolean;

  rpm_status_check_url: string;
  rpm_status_check_substring: string;
}

export type TProposalsMap = IStringMap<TMainProposalLocal | undefined>;

export class Data__SB_SportEvent {
  // 2020-06-26 / Ivan / we are moving to new kind of identifiers
  // matching by event id should be replaced by matching via touple: 'conflict_type' (source) and 'conflict_id' (source identifier)
  //  conflict_type: number;
  //  conflict_id: number;
  // 2020-09-29 / Ivan / should we switch to string identifiers ?
  // 2020-09-29 / Ivan / unique string based on the above pair of identifiers
  conflict_fkey: string;

  //  x_id: number;
  channelId: number;
  // can be formatted to human readable format using sports_book/utils/visual_time_utils
  event_start_timestamp_utc: number;

  homeTeamName: string;
  awayTeamName: string;
  isLive: boolean;
  // 2020-08-18 / Ivan / also track live state
  live_state_desc: string;
  live_score_home: number;
  live_score_away: number;
  // 2022-01-07 / Ivan / introduce extended score - at the moment used only for Tennis
  // example: "4_1_15"
  live_x_score_home: string;
  live_x_score_away: string;

  // 2020-06-01 / Ivan / added three new fields
  event_note: string;
  event_watch_url: string;
  filter_ids: number[];
  // 2020-11-12 / Ivan / attach estimated markets count (new model) to conflict
  estimated_markets_count: number;

  // 2021-01-29 / Ivan / introduce flag which indicates if pre-match event will be converted to live event
  // 0 - no, 1 - yes
  expect_live_betting: number;
  // 2021-01-29 / Ivan / introduce active / OTB (temporary not available) status
  // 733 = active, 748 = otb, see constants
  live_status: TEventStatus;

  // 2021-05-19 / Ivan / introduce concept for conflict class
  conflict_class_code: number;

  // 2022-01-15 / Ivan / introduce permission categories
  // mobile app will display this event only if user has appropriate permissions
  pcats: number[];

  // 2022-01-20 / Ivan / introduce display mode per event - will be used to fine tune the game card
  displayMode: number;

  // 2022-10-01 / Ivan / introduce sort order weight
  weight: number;
}

export class Data__SB_Channel {
  id: number;
  skin_id: number;
  dash_pos: number;
  inplay_pos: number;
  allsports_pos: number;
  visualName: string;
  name_type_2: string;
  long_name: string;
  type: ChannelType;
  fc_limit_code: number;
  // 2021-06-06 / Ivan / introduce convept for two levels for all sports screen
  all_sports_level: number;
}

// 2021-01-28 / Ivan / introduce stats for group social picks (usually weekly)
export class Data__SB_Social_Picks_Stats {
  s_picks_count: number;
  s_5005_v2_tokens: number;
  // 2021-06-25 / Ivan / moving to xpoints stats
  //  s_5006_v2_points: number;
  s_5007_v2_credits: number;
  // 2021-06-25 / Ivan / moving to xpoints stats
  s_5009_v3_xpoints: number;
  // 2021-07-11 / Ivan / introduce new user profile stats
  s_6011_best_win: number;
  // 2021-07-11 / Ivan / this field was initially called 'Profit' in mockups, but its semantic is different (reset to zero on huge loss),
  // new term - skill coefficient
  s_6021_skill_coeff: number;
}

// 2021-04-16 / Ivan / introduce user attributes
export class Data__SB_UserProfile_Attribute {
  code: OwnershipAttributeCode;
  value: string;
  /*
    < 0   => auto filled
    = 0   => claimed (entered) by user, not yet verified (owned)
    = 1   => verified (owned) - confirmed by user
    > 1   => verified (owned) - confirmed in some other way
  */
  state: number;
  /*
    min timestamp in sec when next claim will be allowed
    should be compared against (recalculated) server time
  */
  min_claim_stamp_seconds_utc: number;
}

export class Data__SB_UserProfile {
  // 2020-12-24 / Ivan / adding user_id - we'd like to deprecate Data_UserAccount
  user_id: number;

  obj_version: number;
  // 2020-12-18 / Ivan / introduce v2 resources
  amount_v2_tokens: number;
  // 2021-06-26 / Ivan / no longer needed
  //  amount_v2_points: number;
  // 2021-07-09 / Ivan / remove support for d_5007_v2_credits
  //  amount_v2_credits: number;
  // 2021-06-19 / Ivan / introduce XP
  amount_v3_xpoints: number;
  // 2021-07-02 / Ivan / rename field
  amount_max_v3_xpoints_for_place_pick: number;

  r_5011_v5_playable_fliff_cash: number;
  r_5013_v5_redeemable_fliff_cash: number;
  place_pick_play_through_mode: PlacePickPlayThroughMode;
  amount_rewards_cash_in_cents: number;
  // 2022-06-13 / Ivan / switching from RC to XP, tracking XP progress
  amount_xp_bonus_progress_tokens: number;
  // 2020-07-31 / Ivan / amount that can be used for convertion to rewards cash
  //  amount_iap_promo_tokens: number;

  // 2022-01-15 / Ivan / info about next 'claim free bundle' is moved Data__SB_X_Delta_Profile/Data__SB_Permissions_And_Common_Data
  // since it depends on time and not on profile version
  // > 0 - means that free bundle is available for claim only after specified time
  // 0 - means that free bundle is available for claim
  // we need better name
  //  free_bundle_available_after_timestamp_utc: number;

  // 2020-12-25 / Ivan / moving the free bundle code selection to server
  //  free_v2_current_bundle_id: number;

  sb_affiliate_hash: string;

  // 2020-06-23 / Ivan / number of 'open' iap purchase - app should not allow initiate of new iap if this number is > 0
  open_iap_purchases_count: number;

  // 2021-03-25 / Ivan / add monitoring for open orders
  open_orders_count: number;

  // 2021-06-30 / Ivan / no longer used
  //  user_v2_points_level: number;

  // 2020-09-20 / Ivan / initial integration with backend support for rate us
  may_display_rate_us_nag: boolean;
  may_display_rate_us_reason: string;

  days_left_until_withdraw_tax_level_1: number;

  // 2020-12-11 / Ivan / introduce support for per user
  fc_limits: Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def[];
  // 2021-10-09 / Ivan / introduce general concept for limits - fliff cash vs social coins, inplay vs prematch
  v3_limits: Data__SB_v3_Straight_Pick_Selection_Limit_Def[];

  // 2021-01-11 / Ivan / introduce v2 withdraw limits
  // all values are -1 if there are no limits imposed
  v2_withdraw_limits_allowed_amount: number;
  v2_withdraw_limits_allowed_fliff_cash_amount: number;
  v2_withdraw_limits_allowed_rewards_cash_amount: number;
  //  v2_withdraw_limits_progress: number;
  //  v2_withdraw_limits_max: number;

  //  v2_withdraw_v_limit_min: string;
  //  v2_withdraw_v_limit_max: string;

  // 2021-03-11 / Ivan / introduce support for 'make max 1 withdraw in 5 days'
  last_withdraw_visual_date: string;
  next_withdraw_min_visual_date: string;
  next_withdraw_days_left: number;

  this_week_stats: Data__SB_Social_Picks_Stats;
  prev_week_stats: Data__SB_Social_Picks_Stats;

  // 2021-03-29 / Ivan / introduce flag for 'sumbit orders' vs 'coming soon'
  allowed_to_submit_orders: boolean;

  // 2021-04-16 / Ivan / introduce user attributes
  attributes: Data__SB_UserProfile_Attribute[];

  // 2021-08-16 / Ivan / introduce referrer info
  referrer_id: number;
  referrer_username: string;
  own_bonus_code: string;

  is_user_id_verified: boolean;
  // 2021-12-17 / Ivan / move survey config to server
  //  enable_survey: boolean;
  survey_title: string;
  survey_logo_url: string;
  survey_description: string;
  survey_button_label: string;
  survey_url: string;
  survey_webview_title: string;

  // 2021-10-27 / Ivan / add protocol support for pending withdraws
  d_51071_withdraw__locked_amount: number;
  d_51072_withdraw__locked_count: number;

  // 2022-01-31 / Ivan / introduce support for persona v2
  d_51073_withdraw__total_approved_amount: number;
  identity_verification_status: IdentityVerificationStatus;

  // 2021-10-27 / Ivan / add protocol support for pending picks
  d_51001_tokens_locked_amount: number;
  d_51002_tokens_active_picks_count: number;
  d_51003_fliff_cash_locked_amount: number;
  d_51004_fliff_cash_active_picks_count: number;

  // 2022-01-16 / Ivan / moved to Data__SB_Permissions_And_Common_Data
  // 2021-01-05 / Ivan / introduce default_ui_mode, personalized_ui_mode
  // TDB discussed, but something like
  // 100 => android minimal (startup), 200 => android standart (former light ui), 300 => ios (full ui)
  //  personalized_ui_mode: TServerUIMode;

  // 2021-12-04 / Ivan / introduce delay per user
  pick_delay_acceptance_timeout_millis: number;

  // 2021-12-16 / Ivan / move withdraw limits to use profile
  withdraw_7303__CASH_APP__max_amount: number;
  withdraw_7304__SKRILL__max_amount: number;
  withdraw_7305__BITCOIN__max_amount: number;
  withdraw_7306__MAZOOMA_VERIFIED__max_amount: number;
  withdraw_7307__MAZOOMA_INSTANT__max_amount: number;
  withdraw_7308__FISERV_VERIFIED__max_amount: number;
  withdraw_min_days_between_withdraws: number;
  withdraw_max_days_to_process_withdraw: number;
  withdraw_tax_level_2_in_percents: number;

  // 2022-01-29 / Ivan / introduce concept for personal info kept on server
  personal_info: Data__SB_User_Personal_Info;

  // 2022-01-31 / Ivan / lets try to get rid of ServerObj_FliffApp_UserAccount
  avatar_id: string;
  username: string;

  // 2022-08-05 / Ivan / introduce server side control over available methods for bundle purchase
  avalable_payment_vendors: PaymentVendor[];
  // 2022-10-01 / Ivan / provide information which mechanics was used when account was created
  account_created_mechanics_version: number;
  // 2022-10-26 / Ivan / added some stats in order to help mobile app to track first pick/purchase
  s_1_total_picks: number;
  s_3_total_money_in_purchase_count: number;
  // 2022-12-20 / Ivan / introduce server side control over account details verification
  account_details_status: UserAccountDetailsStatus;
}

export class Data__SB_Pick_Selection {
  id: number;

  // 2020-08-20 / Ivan /
  is_conflict_in_play: boolean;
  in_play_state_desc: string;

  // meta data about event (league / category)
  channel_id: number;
  channel_name?: string;
  // settled time or 0 if pending
  settled_timestamp_utc: number;
  // state
  state: PickSelectionState;
  // data about event
  //  event_id: number;
  event_start_timestamp_utc: number;

  // american coeff
  coeff: number;

  // 2020-10-25 / Ivan / moving to new set of visual texts
  t_121_event_info: string;
  t_131_market_name: string;
  t_132_market_note: string;
  t_141_selection_name: string;
  t_142_selection_param_1: string;

  // 2021-07-08 / Ivan / also add conflict/market fkeys so that mobile app can match the selection
  // against current offers and create ticket entries
  conflict_fkey: string;
  market_fkey: string;
  // 2021-07-06 / Ivan / introduce support for activity feed
  proposal_fkey: string;

  // 2021-07-17 / Ivan / add server control over copy operation
  is_copy_available: boolean;
}
export class Data__SB_Pick {
  id: number;
  // created > 0
  created_timestamp_utc: number;
  // settled time or 0 if pending
  settled_timestamp_utc: number;
  // state
  state: PickSelectionState;
  // type
  type: PickType;
  // currency
  currency: TCurrencyMode;
  // amounts - placed and expected payout
  risk_amount: number;
  payout_amount: number;
  coeff: number;
  // list of selections
  selections: Data__SB_Pick_Selection[];

  play_through_data: {
    pods: { pod_fkey: string; risk_amount: number; v_info: string }[];
  };
  // 2021-07-06 / Ivan / introduce support for activity feed
  userId: number;
  username: string;
  avatar_id: string;
  stake_category: StakeCategory;
  // LOCAL field, added to My Picks from Social BE
  copy_count: number;
}

// 2020-08-27 / Ivan / moving to universal concept for reports
export class Data__SB_Pick_PeriodReport extends Data__SB_Base_Report_for_Client_Controlled_Period {
  data: Data__SB_Pick[];

  //  pending_picks_stats: Data__SB_Social_Picks_Stats;

  // 2020-07-13 / Ivan / add support for v3 profile summary
  v3_profile_summary: Data__SB_v3_User_Profile_Summary;

  // 2021-07-13 / Ivan / introduce support for 'other user profile/stats'
  other_user_id: number;
}

export class Data__SB_v2_Place_Picks_Operation_Status {
  status: PlacePicksOperationStatus;

  // available (> 0) when operation_status = CONST__8301_REQUIRES_DELAYED_RESUBMIT
  // mobile app is supposed to resend same request 'after delay millis'
  // which is actually same as 'after timestamp millis'
  required_resubmit_delay_millis: number;
  required_resubmit_after_timestamp_millis: number;
  // available when operation_status = CONST__8302_REQUIRES_CONFIRMATION_FOR_SERVER_QUOTE_COEFF
  // if != 0, mobile app must display it and ask user for confirmation (submit/accept)
  // if == 0, mobile app must display N/A + 'Re-request Quote' button
  required_confirmed_server_quote_coeff: number;

  // available when operation_status = CONST__8300_SUCCESS
  picks: Data__SB_Pick[];
}

// 2021-03-25 / Ivan / separate points transactions report from transactions report
export class Data__SB_Points_Transaction_PeriodReport extends Data__SB_Base_Report_for_Client_Controlled_Period {
  data: Array<IFCMTransaction>;
}

export interface IDataSBIAPTransaction
  extends Pick<
    IPlayThroughBundle,
    "price_in_cents" | "level_1_skin" | "amount_social_coins"
  > {
  // own identifiers
  own_transaction_id: number;
  own_invoice_id: number;

  // native layer identifiers
  iap_productId: string;
  iap_transactionDate: number;
  // also used to finish payment on ios
  iap_transactionId: string;
  // used to finish payment on android
  iap_android_purchaseToken: string;
}

export class Data__SB_IAP_PaymentsToFinish {
  data: IDataSBIAPTransaction[];
}

export class Data__SB_Pick_Badge {
  // 2020-08-06 / Ivan / also add id for easier tracking
  id: number;

  pick: Data__SB_Pick;
}

export class Data__SB_Points_Leaderboard_Badge {
  // 2020-08-06 / Ivan / also add id for easier tracking
  id: number;
  // amount won - for old leaderboard
  // 2021-02-12 / Ivan / we no longer support gold coins
  //  amount_gold_coins: number;
  // amount won - for new leaderboard
  amount_v2_credits: number;
  // 2021-02-12 / Ivan / introduce bonus tokens
  amount_v2_bonus_tokens: number;
  // position (at the moment can be extracted from type, but it may be possibly to have different badges for same 'reason')
  position: number;

  // period type
  period_type: RawPeriodType;
  // period base stamp - not used by mobile app, but lets keep it for reference
  period_start_timestamp_utc: number;

  // period start / end date in standard string format: 2020-07-23
  start_date: string;
  end_date: string;
}

export class Data__SB_Logros_Report {
  pick_badges: Data__SB_Pick_Badge[];
  leaderboard_badges: Data__SB_Points_Leaderboard_Badge[];
}
export class Data__SB_Public_Challenges_Report extends Data__SB_Base_Report_for_Server_Controlled_Period {
  is_today: boolean;
  error_message: string;
  data: TDataSBPublicChallengesReportItem[];
}

export class ISharedData__SB_Public_Challenges_Report_Item {
  id: number;
  position: number;
  is_completed: boolean;
}

export type TDataSBPublicChallengesReportItem =
  | Data__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE
  | Data__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT
  | Data__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT;

export class Data__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE extends ISharedData__SB_Public_Challenges_Report_Item {
  type: PublicChallengeType.CONST_17001_PLACE_PICK_TYPE;
  details: {
    challenge_type: PublicChallengeType.CONST_17001_PLACE_PICK_TYPE;
    // describes challenge for specific pick type (straight vs parlay)
    // null means - no restriction for pick type
    pick_type: PickType | null;
    // used only for parley - min number of selections
    min_selections_count: number;
  };
}

export class Data__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT extends ISharedData__SB_Public_Challenges_Report_Item {
  type: PublicChallengeType.CONST_17002_PLACE_PICK_SELECTION_NO_CONFLICT;
  details: {
    challenge_type: PublicChallengeType.CONST_17002_PLACE_PICK_SELECTION_NO_CONFLICT;
    // null means - no restriction for type
    conflict_type: ConflictType | null;
  };
}

export class Data__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT extends ISharedData__SB_Public_Challenges_Report_Item {
  type: PublicChallengeType.CONST_17003_PLACE_PICK_SELECTION_FOR_CONFLICT;
  details: {
    challenge_type: PublicChallengeType.CONST_17003_PLACE_PICK_SELECTION_FOR_CONFLICT;
    conflict_type: ConflictType;
    conflict_id: number;
    channel_id: number;
    home_team_name: string;
    away_team_name: string;
    related_conflict_fkeys: string[];
  };
}

// 2020-10-02 / Ivan / introducing headers
export class Data__SB_BetMarketVisualColumn {
  // type of proposals that should be rendered in this column
  proposal_type: number;

  // name of the column (subheader) - may be empty string
  column_header_name: string;
}

// 2020-09-26 / Ivan / introducing new objects for BetProposal / BetGroup  / BetMarket
export class Data__SB_BetProposal {
  // 2020-10-26 / Ivan / switching from id + hash to single identifier - proposal_fkey
  proposal_fkey: string;

  // 2020-01-23 / Ivan / also add version info, will be used for ticket validation
  revision_code: string;
  revision_id: number;

  // 2021-01-21 / Ivan / add market fkey in order to allow ticket to search in redux store for 'best neighbour'
  market_fkey: string;

  // 2020-10-25 / Ivan / also allow server to provide additional options
  t_121_event_info: string;
  t_131_market_name: string;
  t_132_market_note: string;
  // 2020-10-21 / Ivan / introduce new visual name for the string that need to be displayed in ticket + pick history
  t_141_selection_name: string;
  t_142_selection_param_1: string;

  // 2020-10-21 / Ivan / introduce new visual name for the string that need to be displayed in ticket + pick history
  t_151_cell_text_1: string;

  // 2020-12-17 / Ivan / introduce two additional fields for boosted offers
  t_201_sponsor_name: string;
  t_202_sponsor_logo_url: string;

  // active / OTB (temporary suspended)
  status: number;

  // type of the bet proposal (Home, Draw, Over, Yes, Exact etc)
  // used for grid grouping ?
  type: BetProposalTypes;

  // american coeff
  coeff: number;

  // stamp in utc when this coeff was last set
  coeff_updated_utc: number;

  // prev coeff - last different coeff,
  // but may be also zero (just created) or same as coeff (several updates for same value after created)
  prev_coeff: number;

  // stamp in utc when prev coeff was set
  prev_coeff_updated_utc: number;

  // type of last selection change
  change_type: MarketOfferSelectionChangeType;

  // 2020-10-07 / Ivan / globally unique identifier - 'only one proposal with this key can be selected'
  global_radio_set_check__fkey: string;

  // 2020-10-07 / Ivan / globally unique identifier - 'only one proposal with this key can be included in parlay pick'
  global_parlay_set_check__fkey: string;

  // 2021-01-19 / Ivan / add leadership_flag directly to proposal
  leadership_flag: number;

  // 2021-01-25 / Ivan / this is the original proposal type,
  // will be used by mobile app when it tries to detect 'best candidate for replacement'
  market_proposal_type: number;

  // 2021-04-28 / Ivan / quick hack - allow server to enforce that this selection cannot participate in parlays
  parlays_not_allowed: number;

  // 2021-09-12 / Ivan / additional param to control parlay checks - see Trello 1599
  parlay_calibrate: number;

  is_usable_for_sgp: boolean;

  t_301_user_profile_fkey: string;

  t_302_boosted_note: string;
}

export class Data__SB_BetGroup {
  group_tag: string;

  leadership_flag: number;

  // visual name used as row header
  row_header_name: string;

  proposals: Data__SB_BetProposal[];
}

// 2020-09-23 / Ivan / introducing new objects for BetMarketGroup / BetProposal
export class Data__SB_BetMarket {
  // 2020-09-29 / Ivan / unique string based on the above pair of identifiers
  conflict_fkey: string;

  market_fkey: string;

  // visual name to be displayed
  visual_name: string;

  note: string;

  // active / suspended
  // at the moment server always provides Active
  status: number;

  visual_columns: Data__SB_BetMarketVisualColumn[];

  groups: Data__SB_BetGroup[];
}

// 2020-10-13 / Ivan / TypeScript does not allow multiple inheritance

export interface IDataSBFeedDiffItemBetMarketAbstract {
  // version of this diff (revision_code is kept globally)
  revision_id: number;
  // type of the update record
  item_type: number;
  // path / identifier of the update record
  //  object_id: string;
  // 0 means 'available' and there is additional info
  // !=0 means 'deactivated' and there is no additional info
  deactivate_reason: number;
  conflict_fkey: string;
  market_fkey: string;
  subfeed_code: number;
}

// info for 'deactivated' market
export class Data__SB_FeedDiffItem_BetMarket_Deactivated
  implements IDataSBFeedDiffItemBetMarketAbstract {
  // 2020-11-07 / Ivan / introduce separation of 'known revision for showcase markets only' vs 'known revision for all markets'
  //  showcases_revision_id: number;
  // version of this diff (revision_code is kept globally)
  revision_id: number;
  // type of the update record
  item_type: number;
  // !=0 means 'deactivated' and there is no additional info
  // -1 is only for TS reasons.
  deactivate_reason: -1;
  conflict_fkey: string;
  market_fkey: string;
  subfeed_code: number;
}

// info for 'available' market
export class Data__SB_FeedDiffItem_BetMarket_Available
  extends Data__SB_BetMarket
  implements IDataSBFeedDiffItemBetMarketAbstract {
  // 2020-11-07 / Ivan / introduce separation of 'known revision for showcase markets only' vs 'known revision for all markets'
  //  showcases_revision_id: number;
  // version of this diff (revision_code is kept globally)
  revision_id: number;
  // type of the update record
  item_type: number;
  // will be 0 - means 'available' and there is additional info
  deactivate_reason: 0;
  // 2020-10-21 / Ivan / introduce filter ids for grouping of markets
  filters_ids: number[];
  // 2021-11-07 / Ivan / introduce formal key for favourites
  // example: fkey__xmarket__42__64
  xmarket_fkey: string;

  // 2020-10-19 / Ivan / inroduce weight - for local sorting
  weight: number;
  // and also all data fields from extends Data__SB_BetMarket

  // 2021-05-19 / Ivan / introduce concept for conflict market classes
  market_class_codes: number[];

  subfeed_code: number;

  is_usable_for_sgp: boolean;
}

export type TBetMarket =
  | Data__SB_FeedDiffItem_BetMarket_Available
  | Data__SB_FeedDiffItem_BetMarket_Deactivated;

export class Data__SB_FeedDiff_Shelf_Update<
  DiffData extends TBetMarket | IBetMarketLocal = TBetMarket,
> {
  conflict_fkey: string;
  // 2022-03-22 / Ivan / switching to versioning per subfeed
  subfeed_code: number;
  update_type: TDiffUpdateType;
  revision_code: string;
  revision_id: number;
  diff_data: DiffData[];
}

// 2020-10-19 / Ivan / helper class for optimized transfer
export class Data__SB_FeedDiff_Packed_Shelf_Update<
  DiffData extends TBetMarket | IBetMarketLocal = TBetMarket,
> {
  conflict_fkeys: string[];
  // 2022-03-22 / Ivan / switching to versioning per subfeed
  subfeed_code: number;
  update_type: TDiffUpdateType;
  revision_code: string;
  revision_id: number;
  diff_data: DiffData[];
}

export class Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def {
  limit_code: number;
  max_amount_in_cents: number;
}

// 2021-10-09 / Ivan / introduce general concept for limits - fliff cash vs social coins, inplay vs prematch
export class Data__SB_v3_Straight_Pick_Selection_Limit_Def {
  currency: TCurrencyMode;
  is_inplay: boolean;
  limit_code: number;
  max_amount: number; // in cents or coins
}

// 2021-01-04 / Ivan / add data for notification for 'all challenges completed'
export class Data__SB_Logro_v2_All_Daily_Challenges_Completed {
  id: number;

  type: number; // usually 34004 ?

  // 2021-06-25 / Ivan / moving to XP
  // usually > 0, possibly zero for backward compatibility
  //  total_amount_v2_points: number;
  total_amount_v3_xpoints: number;
}

// 2020-12-28 / Ivan / introducing new object which
// will receive given set of user properties only if there are updates
export class Data__SB_X_Delta_Profile {
  // 2021-02-12 / Ivan / added additional user properties that may also depend on time
  user_pdata: Data__SB_Permissions_And_Common_Data;

  // 2021-02-12 / Ivan / add alt value for leaderboard bonus - actually d_51202 and d_51203 are same
  d_51202_last_v2_weekly_leaderboard_bonus: Data__SB_Points_Leaderboard_Badge | null;
  d_51203_last_v2_weekly_leaderboard_thropy: Data__SB_Points_Leaderboard_Badge | null;
  d_51204_last_v2_won_number_of_picks_badge: Data__SB_Pick_Badge | null;
  d_51207_last_v4_xpoints_bonus: IFCMTransaction | null;
  d_51206_last_v2_completed_daily_challenge: Data__SB_Logro_v2_All_Daily_Challenges_Completed | null;
  d_51221_last_v2_transaction_id: number;

  d_51231_last_v3_purchase_error_order: Data__SB_Unfinished_Order | null;
  d_51232_last_v3_purchase_success_transaction: IFCMTransaction | null;
  d_51251_last_transaction_4116_pending_fc_credit: IFCMTransaction | null;
  d_51250_last_transaction_4115_mail_in_entry: IFCMTransaction | null;
  d_51252_last_transaction_4117_cleared_fc_credit: IFCMTransaction | null;
  d_51241_last_v3_order_updates_tracker: number;
}

// 2020-01-05 / Ivan / keep dummy for the time being
export class Data__SB_Validate_Register_Result {}

export class Data__Slide_Gift_Card_Brand {
  b_uuid: string;
  b_name: string;
  b_icon_url: string;
  // b_legal_terms may be empty string
  b_legal_terms: string;
  // b_description may be empty string
  b_description: string;

  v_category: string;
  v_pos: number;

  // cvc => card_value_config
  cvc_min_value: number;
  cvc_max_value: number;
  // if 0 - user need to select gift card denomination from cvc_denominations
  // if > 0 - user can select incremental amount between cvc_min_value and cvc_max_value
  cvc_increment: number;
  // if non-empty - list of fixed card denominations
  cvc_denominations: number[];

  rc_kind: GiftCardRedemptionConfigType;
  rc_disclaimer: string;
  rc_methods: Data__Slide_Gift_Card_Redemption_Method[];

  is_popular: boolean;
  // 40 means 4%, 3 means 0.3%
  // for the time being we assume that this will be enough
  cashback_in_promiles: number;
}

export class Data__Slide_Gift_Card_Redemption_Method {
  m_info: string;
}

export class Data__Slide_All_Gift_Card_Brands {
  brands: Data__Slide_Gift_Card_Brand[];
}

export class Data__Slide_User_Gift_Card {
  amount_denomination: number; // 4000,
  amount_paid_via_credit_card: number; // 2500,
  amount_paid_via_points: number; // 1500,
  brand_name: string; // "1-800Flowers.com"
  card_barcode_kind: string; // "code128",
  card_barcode_value: string; // "C3E3WDG4ZQZ",
  card_csc: string; // "7235",
  card_number: string; // "C3E3 WDG4 ZQZ",

  amount_cashback: number;
  amount_balance: number;
  //  state: TUserGiftCardState;
  // anonymized credit card number used during purchase
  credit_card_number: string;

  brand: Data__Slide_Gift_Card_Brand;
}

export class DataReq__SB_Credit_Card_Info {
  card_number: string; // "4242424242424242",
  expiration_year: string; // "2028",
  expiration_month: string; // "12",
  cvv: string; // "123",
  holder_name: string; // "John Smith",
  first_name: string; // "John",
  last_name: string; // "Smith",
  country_code: string; // "USA",
  street_address: string; // "1111 N Fake Street",
  extended_address: string; // "Unit 201",
  postal_code: string; // "60602",
  city: string; // "Chicago",
  state: string; // "IL"
}

export class DataReq__SB_Order_v2_Slide_Gift_Card {
  slide_brand_uuid: string;
  amount_xp: number;
  //  amount_denomination: number;
  //  amount_rewards_cash: number;
  //  amount_credit_card: number;
  purcase_test_scenario: number;
  //  credit_card_info: DataReq__SB_Credit_Card_Info | null;
}

// 2021-04-16 / Ivan / add support for new 'claim' / 'verify' solution
export class DataReq__SB_Claim_Primary_Email_Address {
  email_address: string;
}

export class DataReq__SB_Claim_Primary_Phone_Number {
  phone_number: string;
  dont_send_sms: boolean;
}

export class DataReq__SB_Confirm_Primary_Phone_Number {
  verification_code: string;
}

// 2022-01-29 / Ivan / introduce persona verification
export class DataReq__SB_Initiate_Persona_Verification {
  // 1 => L1, 2 => L2
  verification_mode: 1 | 2 | 3;
}

export class Data__SB_Initiated_Persona_Verification_Response {
  verification_url: string;
  persona_template_id: string;
  persona_reference_id: string;
}

// 2021-07-15 / Ivan / introduce helper sync data for 'Copy' from activity feed
export class DataReq__SB_Sync_Feed_For_Proposals_Request {
  proposal_fkeys: string[];
}

// 2021-11-02 / Ivan / merging with standard withdrawal workflow
// // 2021-10-19 / Ivan / initial support for mazooma integration
// // MVBA = Mazooma verified bank account
// export class DataReq__SB_Initiate_MVBA_Verification {
//   // 2021-10-31 / Ivan / also added all other standard properties for withdrawal request
//   country: string;
//   country_state: TUSAStatesValues;
//   zip_code: string;
//   city: string;
//   address1: string;
//   address2: string;
//   // example - "1980-12-31"
//   date_of_birth: string;
// }

// 2021-05-19 / Ivan / introduce per-event configuration, mostly for 'More Markets' screen layout
export class Data__SB_Conflict_Class_Def {
  code: number;
  market_classes: Data__SB_Conlict_Market_Class_Def[];
}

export class Data__SB_Conlict_Market_Class_Def {
  // code used for grouping of markets (mostly in tabs), same code will be provided via Data__SB_BetMarket property
  code: number;
  // sort order in 'More Markets' tabs, not visible if < 0
  tab_pos: number;
  // name in 'More Markets' tabs
  tab_name: string;
}

// 2021-06-18 / Ivan / introduce XP + offers
export class Data__SB_XP_ExchangeOffer {
  offer_id: number;

  xp_amount: number; // XP amount - in cents

  rc_amount: number; // rewards cash - in cents

  visual_banner: string;
}

// 2021-07-07 / Ivan / introduce general object for 'current global state' which is not tied to single account
export class Data__SB_WorldState {
  max_pick_id: number;
}

// 2021-07-12 / Ivan / initial support for v3 user profile info
export class Data__SB_v3_User_Profile_Summary {
  user_id: number;
  user_name: string;
  avatar_id: string;
  this_week_stats: Data__SB_v3_User_Weekly_Stats;
  prev_week_stats: Data__SB_v3_User_Weekly_Stats;
}

// 2021-07-12 / Ivan / initial support for v3 user profile info stats
export class Data__SB_v3_User_Weekly_Stats {
  s_picks_count: number;
  s_6011_best_win: number;
  // 2021-07-11 / Ivan / this field was initially called 'Profit' in mockups, but its semantic is different (reset to zero on huge loss),
  // new term - skill coefficient
  s_6021_skill_coeff: number;

  // <= 0 means N/A
  s_global_rank: number;
}
export class Data__SB_Initiated_Withdrawal_Response {
  verification_url: string;
}

export class DataReq__SB_cancel_withdrawal_data {
  amount_in_cents: number;
}

// 2021-10-27 / Ivan / for the time being - no data is passed back
export class DataResponse__SB_cancel_withdrawal_data {}

// 2021-11-29 / Ivan / switching to DataReq__SB_v2_Complete_Profile_Data
export class DataReq__SB_v2_Complete_Profile_Data {
  username: string;
  referrer_hash: string;
  bonus_code: string;
}

// 2022-01-29 / Ivan / introducing concept for personal info kept on server
// maybe later we need to unify this with other places where personal info is used
export class Data__SB_User_Personal_Info {
  static readonly EMPTY: Data__SB_User_Personal_Info = {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    country_code: "",
    country_state: "",
    zip_code: "",
    city: "",
    address_1: "",
    address_2: "",
  };

  first_name: string;
  last_name: string;
  // example - "1980-12-31"
  date_of_birth: string;

  country_code: string;
  country_state: TUSAStatesValues;

  zip_code: string;
  city: string;
  address_1: string;
  address_2: string;
}

// 2022-01-29 / Ivan / lets separate the request from the data object, this will allow us to reuse it for other purposes
export class DataReq__SB_Update_Personal_Data {
  mode: UpdatePersonalDataMode;

  info: Data__SB_User_Personal_Info | null;

  is_locally_verified_with_persona: boolean | null;

  avatar_id: string | null;

  // 2022-01-29 / Ivan / in future we may want to pass some
  //  username: string;
  static readonly EMPTY: DataReq__SB_Update_Personal_Data = {
    mode: -1,
    info: null,
    is_locally_verified_with_persona: null,
    avatar_id: null,
  };
}
