import { CoreHeraldSubfeedsUpdateAction } from "reduxLocal/core/core.actions";
import { staticGetState } from "reduxLocal/store";
import { HeraldBackendOnIncomingMessageCallBack } from "server/herald/HeraldBackendOnIncomingMessageCallBack";
import { THealthState } from "server/herald/HeraldHealthMonitor";
import HeraldHealthMonitor from "server/herald/HeraldHealthMonitor";
import HeraldUtils from "server/herald/HeraldUtils";
import {
  IDataHeraldFeedDiffUpdate,
  IDataHeraldMessageAbstract,
} from "server/herald/data/objects";
import { FliffYSub } from "server/herald/fliffSocketImpl/FliffYSub";
import { FliffException } from "server/legacyCore/FliffException";
import { HeraldProtocolVersion } from "server/legacyCore/data/constants";
import { Data__SB_ShoppingCartProposalItem } from "server/legacyCore/data/objects_local";
import { IStringMap, TAnyAlias } from "src/types";
import AppStateManager from "utils/AppStateManager";
import Logger from "utils/Logger";
import { MemoryStorage } from "utils/MemoryStorage";
import { DevConstants } from "src/DevConstants";
import { AppUtils } from "utils/AppUtils";

export class HeraldBackend {
  // 2022-01-11 / Ivan / quick hack - in order to avoid anon subscribes upgraded to non-anon subscribes
  // we want to delay actual subscription until we are ready to go home screen
  // this will be probably reworked in future
  private static _initIsSubscribeEnabled = false;
  private static _initSavedHeraldEndpoint = "";
  private static _initSavedProtocolVersion = 0;
  private static _defaultTopics = [
    "/v3/heartbeat",
    HeraldBackend.toChannelTopic(DevConstants.getHomeChannelId),
  ];
  private static _pendingTopicsOnDisconnect: string[] = [];
  private static _currentProtocolVersion = 0;
  private static _currentHeraldEndpoint = "";
  private static _socketXsub: null | FliffYSub = null;
  private static _heartbeatOutInterval = 10000;
  private static _heartbeatOutLastStamp = 0;

  public static get isGzipEnabled() {
    return (
      HeraldBackend._currentProtocolVersion ===
      HeraldProtocolVersion.CONST_701_GZIP_SERIALIZED_JSON
    );
  }

  public static get currentHeraldEndpoint() {
    return HeraldBackend._currentHeraldEndpoint;
  }

  public static get activeSubscriptionsTopics(): string[] {
    if (HeraldBackend._socketXsub !== null) {
      return HeraldBackend._socketXsub.activeSubscriptionsTopics;
    }

    return [];
  }

  private static get _assertSocketXsub(): FliffYSub | null {
    return HeraldBackend._socketXsub;
  }

  // 2022-01-11 / Ivan / introduce delayed startup
  public static enableSubscribe() {
    // 2022-01-20 / Ivan / seems that app is 'reinitialized' on android after hardware back + click on icon
    // but all data is still in memory
    if (HeraldBackend._initIsSubscribeEnabled) {
      return;
    }

    HeraldBackend._initIsSubscribeEnabled = true;
    HeraldBackend._reconnect(
      HeraldBackend._initSavedHeraldEndpoint,
      HeraldBackend._initSavedProtocolVersion,
    );
    AppStateManager.runOnAppActivation(() => this.enableSubscribe());
    AppStateManager.runOnAppDeactivation(() => this._disconnectOnStateChange());
  }

  public static onNewAuthToken(authToken: string) {
    if (HeraldHealthMonitor.lastAuthToken === authToken) {
      return;
    }

    // if data is not the same - force reconnect to same endpoint (will report the new data via new subscribe)
    HeraldHealthMonitor.setLastAuthToken(authToken);

    // 2022-01-11 / Ivan / introduce delayed startup
    if (!HeraldBackend._initIsSubscribeEnabled) {
      return;
    }

    HeraldBackend._reconnect(
      HeraldBackend._currentHeraldEndpoint,
      HeraldBackend._currentProtocolVersion,
    );
  }

  public static onDefaultHeraldEndpoint(
    endpoint: string,
    ipAddress: string,
    protocolVersion: HeraldProtocolVersion,
  ) {
    // 2022-01-05 / Ivan / no need to reconnect on IP address change - just keep it
    HeraldHealthMonitor.setLastIpAddress(ipAddress);

    // endpoint is same - no need to do anything
    if (
      HeraldBackend._currentHeraldEndpoint === endpoint &&
      HeraldBackend._currentProtocolVersion === protocolVersion
    ) {
      return;
    }

    // 2022-01-11 / Ivan / introduce delayed startup
    if (!HeraldBackend._initIsSubscribeEnabled) {
      HeraldBackend._initSavedHeraldEndpoint = endpoint;
      HeraldBackend._initSavedProtocolVersion = protocolVersion;
      return;
    }

    // connect will handle well reset of endpoint
    HeraldBackend._reconnect(endpoint, protocolVersion);
  }

  public static subscribe(topic: string): void {
    try {
      const socketXsub = HeraldBackend._assertSocketXsub;
      if (!socketXsub) {
        return;
      }
      socketXsub.subscribe(topic);
    } catch (error: TAnyAlias) {
      Logger.warnAny(
        `x in HeraldBackend/fliff_subscribe ${topic}`,
        error.message,
      );
    }
  }

  public static unsubscribe(topic: string): void {
    try {
      const socketXsub = HeraldBackend._assertSocketXsub;
      if (!socketXsub) {
        return;
      }
      socketXsub.unsubscribe(topic);
    } catch (error: TAnyAlias) {
      Logger.warnAny(
        `x in HeraldBackend/fliff_unsubscribe ${topic}`,
        error.message,
      );
    }
  }

  public static heartbeat(): void {
    try {
      const socketXsub = HeraldBackend._assertSocketXsub;
      if (!socketXsub) {
        return;
      }
      socketXsub.heartbeat();
    } catch (error) {
      Logger.warnAny("  x in HeraldBackend/fliff_heartbeat", error);
    }
  }

  public static onIncomingMessage(serializedMessageData: string): void {
    try {
      const message = HeraldUtils.decodeSerializedMessage(
        serializedMessageData,
      );
      if (message !== null) {
        this._processMessage(message);
      }
      HeraldHealthMonitor.handleIncomeMessage();
    } catch (error) {
      Logger.warnAny("  x in HeraldBackend/on_incoming_message", error);
    }
  }

  public static onTick(): void {
    // this won't work well if user changes the settings of the device clock
    const now = new Date().getTime();
    if (HeraldBackend._heartbeatOutLastStamp > now) {
      HeraldBackend._heartbeatOutLastStamp = now;
    }

    if (
      now - HeraldBackend._heartbeatOutLastStamp <
      HeraldBackend._heartbeatOutInterval
    ) {
      return;
    }
    HeraldBackend._heartbeatOutLastStamp = now;
    HeraldBackend.heartbeat();
  }

  public static onCartItemsSubscriptionChange(
    prev: IStringMap<Data__SB_ShoppingCartProposalItem>,
    next: IStringMap<Data__SB_ShoppingCartProposalItem> = {},
  ): void {
    const topicsToSubscribe = [
      ...new Set(
        Object.values(next).map(cartItem => cartItem.event.conflict_fkey),
      ),
    ];
    const topicsToUnsubscribe = [
      ...new Set(
        Object.values(prev).map(cartItem => cartItem.event.conflict_fkey),
      ),
    ];

    topicsToUnsubscribe.forEach(topic => {
      if (topic === MemoryStorage.focusedConflictFkey) {
        return;
      }
      if (topicsToSubscribe.includes(topic)) {
        return;
      }
      if (
        !this.activeSubscriptionsTopics.includes(
          this.toConflictFkeyTopic(topic),
        )
      ) {
        return;
      }
      this.unsubscribeForConflictFkey(topic);
    });
    topicsToSubscribe.forEach(topic => {
      if (topic === MemoryStorage.focusedConflictFkey) {
        return;
      }
      if (topicsToUnsubscribe.includes(topic)) {
        return;
      }
      if (
        this.activeSubscriptionsTopics.includes(this.toConflictFkeyTopic(topic))
      ) {
        return;
      }
      this.subscribeForConflictFkey(topic);
    });
  }

  public static subscribeForChannelId = (channelId: number): void => {
    if (AppUtils.isNullable(HeraldBackend._socketXsub)) {
      return;
    }
    if (!channelId) {
      Logger.warnHandledAny(
        "[HeraldBackend.subscribeForChannelId] channel id is false",
        channelId,
      );
      return;
    }
    this.subscribe(HeraldBackend.toChannelTopic(channelId));
  };

  public static unsubscribeForConflictFkeyFromFeed = (
    conflictFkey: string,
  ): void => {
    const doesAnyProposalContainUnsubChannelId = Object.values(
      staticGetState().sportsBook.shopping_cart.items,
    ).find(item => item.event.conflict_fkey === conflictFkey);
    // If in ticket we have the channel id from which we want to unsubscribe – we don't to unsubscribe from it.
    if (doesAnyProposalContainUnsubChannelId) {
      return;
    }
    this.unsubscribeForConflictFkey(conflictFkey);
  };

  public static unsubscribeForChannelId = (channelId: number): void => {
    if (!channelId) {
      Logger.warnHandledAny(
        "[HeraldBackend.unsubscribeForChannelId] channel id is false",
        channelId,
      );
      return;
    }
    this.unsubscribe(HeraldBackend.toChannelTopic(channelId));
  };

  public static subscribeForConflictFkey(conflictFkey: string) {
    if (!conflictFkey) {
      Logger.warnHandledAny(
        "[HeraldBackend.subscribeForConflictFkey] conflictFkey is false",
        conflictFkey,
      );
      return;
    }
    this.subscribe(HeraldBackend.toConflictFkeyTopic(conflictFkey));
  }

  public static unsubscribeForConflictFkey(conflictFkey: string) {
    if (!conflictFkey) {
      Logger.warnHandledAny(
        "[HeraldBackend.unsubscribeForConflictFkey] conflictFkey is false",
        conflictFkey,
      );
      return;
    }
    this.unsubscribe(HeraldBackend.toConflictFkeyTopic(conflictFkey));
  }

  public static toConflictFkeyTopic(conflictFkey: string): string {
    return `/v3/conflict/${conflictFkey}`;
  }

  public static toChannelTopic(channelId: number): string {
    return `/v3/channel/${channelId}`;
  }

  private static _disconnectOnStateChange() {
    HeraldBackend._initIsSubscribeEnabled = false;
    HeraldBackend._disconnect();
  }

  private static _disconnect() {
    try {
      if (HeraldBackend._currentHeraldEndpoint === "") {
        return;
      }

      if (HeraldBackend._socketXsub !== null) {
        const topics = HeraldBackend._socketXsub.activeSubscriptionsTopics;
        this._saveTopicsOnDisconnect(topics);
        HeraldBackend._socketXsub.fliffMassUnsubscribe(topics);
        HeraldBackend._socketXsub.disconnect(
          HeraldBackend._currentHeraldEndpoint,
        );
      }
    } catch (error) {
      Logger.warnAny("  x in HeraldBackend/disconnect", error);
    }

    HeraldBackend._currentHeraldEndpoint = "";
    HeraldBackend._currentProtocolVersion = 0;
    HeraldBackend._socketXsub = null;
  }

  private static _saveTopicsOnDisconnect(topics: string[]): void {
    this._pendingTopicsOnDisconnect = topics;
  }

  private static _reconnect(
    endpoint: string,
    protocolVersion: HeraldProtocolVersion,
  ) {
    try {
      console.log("[herald][reconnect]", endpoint);
      let topics = HeraldBackend.activeSubscriptionsTopics;

      // 2022-01-05 / Ivan - we have two cases here:
      // 1. reconnect because of change of credentials (which affects low level subscriptions' protocol)
      // 2. reconnect because of change of endpoint
      if (
        HeraldBackend._socketXsub !== null &&
        HeraldBackend._currentHeraldEndpoint === endpoint
      ) {
        // same endpoint with new credentials - just unsubscribe and resubscribe
        // (topic names will stay the same, but meta info will be different)
        // may happen when user enters as anon and then logs in
        HeraldBackend._socketXsub.fliffMassUnsubscribe(topics);

        // should never happen
        if (topics.length === 0) {
          if (HeraldBackend._pendingTopicsOnDisconnect.length !== 0) {
            topics = HeraldBackend._pendingTopicsOnDisconnect;
          } else {
            topics = HeraldBackend._defaultTopics;
          }
        }

        HeraldBackend._socketXsub.fliffMassSubscribe(topics);
        return;
      }

      // disconnect from current socket if exist
      HeraldBackend._disconnect();

      if (endpoint === "") {
        console.log("[herald][reconnect] detected empty endpoint");
        return;
      }

      // we will save the endpoint at the very beginning - this will allow any crash to clean up the socket via disconnect
      HeraldBackend._currentHeraldEndpoint = endpoint;
      HeraldBackend._currentProtocolVersion = protocolVersion;
      HeraldBackend._socketXsub = new FliffYSub(
        new HeraldBackendOnIncomingMessageCallBack(),
      );
      HeraldBackend._socketXsub.connect(HeraldBackend._currentHeraldEndpoint);

      HeraldHealthMonitor.handleHealthChange(
        THealthState.CONST_CONNECTING,
        null,
      );

      if (topics.length === 0) {
        if (HeraldBackend._pendingTopicsOnDisconnect.length !== 0) {
          topics = HeraldBackend._pendingTopicsOnDisconnect;
        } else {
          topics = HeraldBackend._defaultTopics;
        }
      }

      console.log("[reconnect] phase_91");
      HeraldBackend._socketXsub.fliffMassSubscribe(topics);
      console.log("[reconnect] phase_92");
      console.log("topics to subscribe", topics);
    } catch (error) {
      Logger.warnAny("  x in HeraldBackend/connect", error);
      // try to do some cleanup here - disconnect will handle well situation where socket is set
      HeraldBackend._disconnect();
    }
  }

  private static _processMessage(message: IDataHeraldMessageAbstract): void {
    switch (message.message_name) {
      case "Message_FeedDiffUpdate":
        CoreHeraldSubfeedsUpdateAction.dispatchHeraldSubfeedsUpdate(
          message as IDataHeraldFeedDiffUpdate,
        );
        break;
      default:
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "unknown messages: " + message.message_name,
        );
    }
  }
}
