import { staticGetState } from "reduxLocal/store";
import { IStringMap } from "src/types";
import { MemoryStorage } from "utils/MemoryStorage";
import { IFeedKnownSubfeedRevision, IFeedMeta } from "./data/objects";
import { ILocalAxiosRequestConfig } from "server/legacyCore/objectsLegacyCore";
import { AxiosError, AxiosResponse } from "axios";

class SharedCoreUtils {
  public attachRequestCodeInterceptors = () => {
    let requestCode = "";

    return () => ({
      onRequest: (config: ILocalAxiosRequestConfig) => {
        if (config.headers?.["x-dd-request-code"]) {
          requestCode = config.headers?.["x-dd-request-code"];
          delete config.headers["x-dd-request-code"];
        }
        return config;
      },
      onResponse: (response: AxiosResponse) => {
        if (requestCode) {
          response.config.headers["x-dd-request-code"] = requestCode;
        }
        return response;
      },
      onError: (error: AxiosError) => {
        if (requestCode && error.config) {
          error.config.headers["x-dd-request-code"] = requestCode;
        }
        return error;
      },
      requestCode,
    });
  };

  public buildFeedMeta(): IFeedMeta {
    const supermarket = staticGetState().sportsBook.bet_supermarket;

    const tempmap: IStringMap<IFeedKnownSubfeedRevision> = {};

    Object.values(supermarket.shelves_by_conflict_fkey).forEach(shelf => {
      Object.values(shelf.subfeeds).forEach(subfeed => {
        // 2022-03-20 / Ivan / introduce concept for multi subfeeds
        const key: string =
          "" +
          subfeed.subfeed_code +
          "__" +
          subfeed.revision_code +
          "__" +
          subfeed.max_revision_id;
        if (!(key in tempmap)) {
          tempmap[key] = {
            subfeed_code: subfeed.subfeed_code,
            revision_code: subfeed.revision_code,
            revision_id: subfeed.max_revision_id,
            conflict_fkeys: [],
          };
        }
        tempmap[key].conflict_fkeys.push(shelf.conflict_fkey);
      });
    });

    const revisions: IFeedKnownSubfeedRevision[] = Object.values(tempmap);

    const cartItems = staticGetState().sportsBook.shopping_cart.items;
    const cartItemsValues = Object.values(cartItems);
    const mapped_conflict_fkeys = cartItemsValues.map(
      ({ event: { conflict_fkey } }) => conflict_fkey,
    );
    const focused_ticket_conflict_fkeys = [...new Set(mapped_conflict_fkeys)];
    const mapped_proposal_fkeys = cartItemsValues.map(
      ({ proposal_fkey }) => proposal_fkey,
    );
    const focused_ticket_proposal_fkeys = [...new Set(mapped_proposal_fkeys)];
    return {
      revisions: [],
      packed_subfeed_revisions: revisions,
      focused_channel_id: MemoryStorage.focusedChannelId,
      focused_conflict_fkey: MemoryStorage.focusedConflictFkey,
      focused_ticket_conflict_fkeys,
      focused_ticket_proposal_fkeys,
    };
  }
}

export default new SharedCoreUtils();
