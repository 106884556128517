import PickUtils from "utils/PickUtils";
import {
  Data__SB_BetGroup,
  Data__SB_BetProposal,
  Data__SB_FeedDiffItem_BetMarket_Available,
  Data__SB_SportEvent,
  TBetMarket,
} from "./objects";
import { PickType, UIConstants } from "./constants";
import ChannelUtils from "utils/ChannelUtils";
import { staticGetState } from "reduxLocal/store";
import { IStringMap } from "src/types";

export class Data__CellParamsHolder {
  public event: Data__SB_SportEvent | null;
  public proposal: TCellRenderProposalLocal;

  constructor(
    event: Data__SB_SportEvent | null,
    proposal: TCellRenderProposalLocal,
  ) {
    this.event = event;
    this.proposal = proposal;
  }
}

export class Market_Row_Title_Type {
  type = 0 as const;
  title: string;
  changeID: string;
  xmarket_fkey: string;
  isSGPSupported: boolean;
  constructor(
    title: string,
    changeID: string,
    xmarket_fkey: string,
    isSGPSupported: boolean,
  ) {
    this.title = title;
    this.changeID = changeID;
    this.xmarket_fkey = xmarket_fkey;
    this.isSGPSupported = isSGPSupported;
  }

  getType() {
    return this.type;
  }
}

export class Market_Row_Note_Type {
  type = 1 as const;
  note: string;
  changeID: string;
  xmarket_fkey: string;
  constructor(note: string, changeID: string, xmarket_fkey: string) {
    this.note = note;
    this.type = 1;
    this.changeID = changeID;
    this.xmarket_fkey = xmarket_fkey;
  }

  getType() {
    return this.type;
  }
}

export class Market_Row_Proposals_Type {
  type = 3 as const;
  cell_params_holders: (Data__CellParamsHolder | null)[];
  market_fkey: string;
  changeID: string;
  xmarket_fkey: string;
  constructor(
    acell_params_holders: (Data__CellParamsHolder | null)[],
    market_fkey: string,
    changeID: string,
    xmarket_fkey: string,
  ) {
    this.cell_params_holders = acell_params_holders;
    this.market_fkey = market_fkey;
    this.changeID = changeID;
    this.xmarket_fkey = xmarket_fkey;
  }

  getType() {
    return this.type;
  }
}

export class Market_Row_SingleProposal_Type {
  type = 4 as const;
  cell_params_holder: Data__CellParamsHolder;
  market_fkey: string;
  changeID: string;
  xmarket_fkey: string;
  constructor(
    cell_params_holder: Data__CellParamsHolder,
    market_fkey: string,
    changeID: string,
    xmarket_fkey: string,
  ) {
    this.cell_params_holder = cell_params_holder;
    this.market_fkey = market_fkey;
    this.changeID = changeID;
    this.xmarket_fkey = xmarket_fkey;
  }

  getType() {
    return this.type;
  }
}

export class Market_Text_Row_Proposals_Type {
  type = 5 as const;
  cell_params_holders: (Data__CellParamsHolder | null)[];
  market_fkey: string;
  changeID: string;
  row_text: string;
  columns_count: number;
  xmarket_fkey: string;
  constructor(
    cell_params_holders: (Data__CellParamsHolder | null)[],
    market_fkey: string,
    changeID: string,
    row_text: string,
    columns_count: number,
    xmarket_fkey: string,
  ) {
    this.cell_params_holders = cell_params_holders;
    this.market_fkey = market_fkey;
    this.changeID = changeID;
    this.row_text = row_text;
    this.columns_count = columns_count;
    this.xmarket_fkey = xmarket_fkey;
  }

  getType() {
    return this.type;
  }
}

export class Data__SB_ShoppingCartProposalItem {
  // 2021-07-08 / Ivan / this is the main identifier of the bet offer (proposal)
  // 2020-10-26 / Ivan / switching from id + hash to single identifier - proposal_fkey
  public proposal_fkey: string;
  // 2021-07-08 / Ivan / cleanup of concept, those are the values displayed in ticket
  // 2020-10-25 / Ivan / moving to new naming convention for visual data
  public t_121_event_info: string;
  public t_131_market_name: string;
  public t_141_selection_name: string;
  public t_142_selection_param_1: string;

  // 2021-07-08 / Ivan / those two values are used only for 'find best nearest offer of same type'
  public market_fkey: string;
  public proposal_type: number;

  public group_id: string;
  public parlay_id: string;
  // 2021-07-08 / Ivan / now this is the main option that controls if parlays are allowed
  // 2021-04-28 / Ivan / quick hack - allow server to enforce that this selection cannot participate in parlays
  public parlays_not_allowed: number;

  // 2021-07-08 / Ivan / the odds to which user agreed to
  public coeff: number;

  public event: Data__SB_SportEvent;

  public placed_amount__fliff_cash = 0;
  public placed_amount__gold_coins = 0;
  public place_pick_update_utc: number;
  public createdStamp: number;
  public parlayCalibrate: number;

  public max_risk_limit: number | undefined;
  public isReplaced: boolean;
  public isSGPSupported: boolean;
  public revisionId: number;

  constructor(
    aevent: Data__SB_SportEvent,
    proposal: TMainProposalLocal,
    isReplaced = false,
    replacedProposalStamp?: number,
    placedAmount?: { coins: number; cash: number },
  ) {
    this.event = aevent;
    if (replacedProposalStamp) {
      this.createdStamp = replacedProposalStamp;
    } else {
      this.createdStamp = Date.now();
    }

    this.placed_amount__fliff_cash =
      PickUtils.getStraightMinBetAmountInCurrencies()[
        UIConstants.CONST__331__CASH
      ];
    this.placed_amount__gold_coins =
      PickUtils.getStraightMinBetAmountInCurrencies()[
        UIConstants.CONST__333__FLIFF_COINS
      ];

    if (placedAmount) {
      if (placedAmount.coins) {
        this.placed_amount__gold_coins = placedAmount.coins;
      }
      if (placedAmount.cash) {
        this.placed_amount__fliff_cash = placedAmount.cash;
      }
    }

    this.max_risk_limit = ChannelUtils.getChannelById(
      staticGetState().sportsBook.config.channels,
      aevent.channelId,
    )?.fc_limit_code;
    this.isReplaced = isReplaced;
    this.revisionId = proposal.revision_id;
    // 2020-10-26 / Ivan / switching from id + hash to single identifier - proposal_fkey
    this.proposal_fkey = proposal.proposal_fkey;
    this.parlayCalibrate = proposal.parlay_calibrate;
    // 2021-01-25 / Ivan / we need to keep original proposal type (market independant),
    // will be used by mobile app when it tries to detect 'best candidate for replacement'
    this.proposal_type = proposal.market_proposal_type;
    this.coeff = proposal.coeff;
    this.group_id = proposal.global_radio_set_check__fkey;
    this.parlay_id = proposal.global_parlay_set_check__fkey;
    this.parlays_not_allowed = proposal.parlays_not_allowed;
    this.place_pick_update_utc = proposal.prev_coeff_updated_utc;
    this.market_fkey = proposal.market_fkey;
    // 2021-07-08 / Ivan / just take those fields directly from proposal object
    this.t_131_market_name = proposal.t_131_market_name;
    this.t_121_event_info = proposal.t_121_event_info;
    // 2020-10-25 / Ivan / introducing new approach for visual data
    this.t_141_selection_name = proposal.t_141_selection_name;
    this.t_142_selection_param_1 = proposal.t_142_selection_param_1;
    this.isSGPSupported = proposal.is_usable_for_sgp;
  }
}

export class Data__SB_ShoppingCart {
  // 2020-04-18 / Ivan / cart items - bet selections selected by user
  items: IStringMap<Data__SB_ShoppingCartProposalItem>;

  // Unique id of the cart - in order to track 'double submits'. Is updated on EVERY cart change.
  // One exception – doesn't update on selection amount change.
  unique_shopping_cart_id: string;

  // 2020-05-29 / removing Data__SB_Amounts concept
  totalPlacedGoldCoinsAmount: number;
  totalPlacedFliffCashAmount: number;

  pickType: PickType;
  sourcePick: null | {
    pickFKey: string;
    proposalFKeys: string[];
  };
}

export const betProposalLocalDiff = [
  "t_151_cell_text_1",
  "type",
  "t_301_user_profile_fkey",
  "t_132_market_note",
  "t_201_sponsor_name",
  "t_302_boosted_note",
  "t_202_sponsor_logo_url",
  "prev_coeff",
] as const;

type TBetProposalLocalDiff = (typeof betProposalLocalDiff)[number];

export type TCellRenderProposalLocal = Pick<
  Data__SB_BetProposal,
  | TBetProposalLocalDiff
  | "proposal_fkey"
  | "t_131_market_name"
  | "t_141_selection_name"
>;

export type TMainProposalLocal = Omit<
  Data__SB_BetProposal,
  TBetProposalLocalDiff
>;

export interface IBetMarketGroupLocal
  extends Omit<Data__SB_BetGroup, "proposals"> {
  proposals: TCellRenderProposalLocal[];
}

export interface IBetMarketLocal
  extends Omit<Data__SB_FeedDiffItem_BetMarket_Available, "groups"> {
  groups: IBetMarketGroupLocal[];
}

export class Data__SB_SuperMarketConflictSubfeed<
  Markets extends TBetMarket | IBetMarketLocal = TBetMarket,
> {
  revision_code: string;
  max_revision_id: number;
  subfeed_code: number;
  markets: Markets[];
}

export class Data__SB_SuperMarketConflictShelf<
  Market extends TBetMarket | IBetMarketLocal = TBetMarket,
> {
  conflict_fkey: string;
  subfeeds: IStringMap<Data__SB_SuperMarketConflictSubfeed<Market>>;
  markets: Market[];
}

export class Data__SB_SuperMarket {
  shelves_by_conflict_fkey: IStringMap<
    Data__SB_SuperMarketConflictShelf<IBetMarketLocal>
  >;
}
