import { ErrorHelper } from "server/legacyCore/ErrorHelper";
import { FliffException } from "server/legacyCore/FliffException";
import CommonLocationManager from "utils/LocationManagers/Common";
import Logger from "utils/Logger";
import { MemoryStorage } from "utils/MemoryStorage";
import { PersistentStorage } from "utils/PersistentStorage";

import {
  ServerObj_Temp_Data_For_Complete_Profile,
  XCommonServerRequest,
  XCommonServerRequestHeader,
  XCommonServerResponse,
} from "server/legacyCore/objectsLegacyCore";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";

import {
  Data__SB_Config,
  Data__SB_FeedDiff_Shelf_Update,
  Data__SB_IAP_PaymentsToFinish,
  Data__SB_Initiated_Persona_Verification_Response,
  Data__SB_Initiated_Withdrawal_Response,
  Data__SB_Pick_PeriodReport,
  Data__SB_Points_Transaction_PeriodReport,
  Data__SB_Public_Challenges_Report,
  Data__SB_SportEvent,
  Data__SB_UserProfile,
  Data__SB_v2_Place_Picks_Operation_Status,
  Data__SB_X_Delta_Profile,
  Data__Slide_All_Gift_Card_Brands,
  DataReq__SB_cancel_withdrawal_data,
  DataReq__SB_Claim_Primary_Email_Address,
  DataReq__SB_Claim_Primary_Phone_Number,
  DataReq__SB_ClientControlledPeriod,
  DataReq__SB_Confirm_Primary_Phone_Number,
  DataReq__SB_Initiate_Persona_Verification,
  DataReq__SB_Order_v2_Slide_Gift_Card,
  DataReq__SB_Picks_Period_Report,
  DataReq__SB_PlacePickRequest,
  DataReq__SB_Profile_Meta,
  DataReq__SB_RateUsInfo,
  DataReq__SB_ServerControlledPeriod,
  DataReq__SB_Sync_Feed_For_Proposals_Request,
  DataReq__SB_Update_Personal_Data,
  DataReq__SB_v2_Complete_Profile_Data,
  DataReq__SB_Validate_Register,
  DataReq__SB_WithdrawRequest,
  DataReq__SB_XP_Exchange,
} from "server/legacyCore/data/objects";
import {
  Adapter__SB_Config,
  Adapter__SB_IAP_PaymentsToFinish,
  Adapter__SB_Initiated_Persona_Verification_Response,
  Adapter__SB_Initiated_Withdrawal_Response,
  Adapter__SB_Pick_PeriodReport,
  Adapter__SB_Points_Transaction_PeriodReport,
  Adapter__SB_Public_Challenges_Report,
  Adapter__SB_SportEvent,
  Adapter__SB_Unfinished_Order,
  Adapter__SB_UserProfile,
  Adapter__SB_v2_Place_Picks_Operation_Status,
  Adapter__SB_X_Delta_Profile,
  Adapter__Slide_All_Gift_Card_Brands,
  AdapterHelper,
} from "server/legacyCore/data/server_adapters";
import {
  Data__SB_Unfinished_Order,
  IFeedMeta,
} from "server/sharedCore/data/objects";
import { AdapterFeedDiffUpdate } from "server/sharedCore/data/serverAdapters";
import SharedCoreUtils from "server/sharedCore/SharedCoreUtils";
import { TAnyAlias } from "src/types";
import DeviceInfo from "utils/DeviceInfo";
import { DevConstants } from "src/DevConstants";
import { AppUtils } from "utils/AppUtils";

const SPORTS_BOOK_REQUEST: string = "SPORTS_BOOK_REQUEST";
const OPERATION__1__SYNC_RAW: number = 1;
const OPERATION__3__SYNC_LOAD_REWARDS_CASH_REPORT: number = 3;
const OPERATION__5__SYNC_LOAD_PICKS_REPORT: number = 5;
const OPERATION__6__SYNC_LOAD_LOGROS_REPORT: number = 6;
const OPERATION__7__SYNC_LOAD_GOLD_COINS_LEADERBOARD_REPORT: number = 7;
const OPERATION__9__SYNC_PUBLIC_CHALLENGES_REPORT: number = 9;
const OPERATION__10__SYNC_LOAD_POINTS_LEADERBOARD_REPORT: number = 10;
const OPERATION__11__SYNC_LOAD_POINTS_TRANSACTIONS_REPORT: number = 11;
// 2021-07-11 / introduce v3 skill_coeff report
const OPERATION__12__SYNC_LOAD_SKILL_COEFF_REPORT: number = 12;
const OPERATION__13__SYNC_LOAD_ACTIVITY_FEED: number = 13;

const OPERATION__21__PLACE_PICK: number = 21;
const OPERATION__31__OBTAIN_COINS_BUNDLE: number = 31;
const OPERATION__32__CLAIM_PRIMARY_EMAIL_ADDRESS: number = 32;
const OPERATION__33__CLAIM_PRIMARY_PHONE_NUMBER: number = 33;
const OPERATION__34__CONFIRM_PRIMARY_PHONE_NUMBER: number = 34;
const OPERATION__36__REQUEST_WITHDRAW: number = 36;
const OPERATION__37__EXECUTE_XPOINTS_OFFER: number = 37;
const OPERATION__38__INITIATE_PERSONA_VERIFICATION: number = 38;
const OPERATION__39__CANCEL_WITHDRAW: number = 39;
const OPERATION__40__INITIATE_MAZOOMA_PAY_IN: number = 40;
const OPERATION__41__REQUEST_INVOICE_FOR_IAP: number = 41;
const OPERATION__42__UPDATE_PERSONAL_DATA: number = 42;
const OPERATION__43__RATE_US_DATA: number = 43;

// 2021-02-28 / Ivan / added API call for purchase gift card
const OPERATION__46__CREATE_ORDER_FOR_SLIDE_GIFT_CARD: number = 46;
// 2021-11-29 / Ivan / introduce alternative API for complete profile
const OPERATION__48__V2_COMPLETE_USER_PROFILE: number = 48;

const OPERATION__49__PRIVATE_UPLOAD_RAW_IAP_NATIVE_FEED: number = 49;
const OPERATION__50__PARTIALLY_VALID_USER__SYNC_ON_LOGIN: number = 50;
const OPERATION__57__PUBLIC_LOAD_GOLD_COINS_LEADERBOARD_REPORT: number = 57;
const OPERATION__58__V3_UNIVERSAL_FEED: number = 58;
const OPERATION__59__SYNC_PUBLIC_CHALLENGES_REPORT: number = 59;
const OPERATION__60__PUBLIC_LOAD_POINTS_LEADERBOARD_REPORT: number = 60;
const OPERATION__61__PUBLIC_VALIDATE_REGISTRATION_DATA: number = 61;
// 2021-07-11 / introduce v3 skill_coeff report
const OPERATION__62__PUBLIC_SYNC_LOAD_SKILL_COEFF_REPORT: number = 62;

// 2021-02-23 / Initial integration with slide
const OPERATION__70__PUBLIC_SLIDE_CARD_BRANDS: number = 70;
// 2021-07-06 / introduce support for activity feed
const OPERATION__80__PUBLIC_LOAD_ACTIVITY_FEED: number = 80;
// 2021-07-15 / Ivan / introduce helper sync data for 'Copy' from activity feed
const OPERATION__81__SYNC_FEED_FOR_PROPOSALS: number = 81;

const DATA__profile = "profile";
const DATA__fliff_account = "fliff_account";
const DATA__prematch_feed = "prematch_feed";
const DATA__inplay_feed = "inplay_feed";
const DATA__rewards_cash_report = "rewards_cash_report";

const DATA__public_challenges_report = "public_challenges_report";
const DATA__today_challenges_report = "today_challenges_report";
const DATA__x_picks_report = "x_picks_report";
const DATA__points_transaction_report = "points_transaction_report";

const DATA__slide_card_brands = "slide_card_brands";

// 2021-07-11 / introduce v3 skill_coeff report

// 2020-05-02 / Ivan / initial attempt to introduce some kind of incremental protocol
// still not sure if this is the proper place to implement it, a higher level logic may be more appropriate ?
// (example - higher level crashes and new config is lost, lower level will never re-request config)
class IncrementalProtocolVersions {
  public static config_version: number = 0;
}

export class SportsBookOperation {
  code: number;
  config_version: number;
}

export class SportsBook_OPERATION__with__profile_meta extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
}

export class SportsBook_OPERATION__1__SYNC_RAW extends SportsBookOperation {
  feed_meta: IFeedMeta;
  profile_meta: DataReq__SB_Profile_Meta;
}

export class SportsBook_OPERATION__2__SYNC_LOAD_TRANSACTIONS_REPORT extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  report_client_period: DataReq__SB_ClientControlledPeriod;
}

export class SportsBook_OPERATION__5__SYNC_LOAD_PICKS_REPORT extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  report_client_period: DataReq__SB_ClientControlledPeriod;
}

export class SportsBook_OPERATION__9__SYNC_PUBLIC_CHALLENGES_REPORT extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  report_server_period: DataReq__SB_ServerControlledPeriod;
}

export class SportsBook_OPERATION__11__SYNC_LOAD_POINTS_TRANSACTIONS_REPORT extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  report_client_period: DataReq__SB_ClientControlledPeriod;
}

export class SportsBook_OPERATION__12__SYNC_LOAD_SKILL_COEFF_REPORT extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  report_server_period: DataReq__SB_ServerControlledPeriod;
}

export class SportsBook_OPERATION__21__PLACE_PICK extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  place_pick_request_data: DataReq__SB_PlacePickRequest;
}

export class SportsBook_OPERATION__31__OBTAIN_COINS_BUNDLE extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  bundle_id: number;
}

export class SportsBook_OPERATION__32__CLAIM_PRIMARY_EMAIL_ADDRESS extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  primary_email_address_data: DataReq__SB_Claim_Primary_Email_Address;
}

export class SportsBook_OPERATION__33__CLAIM_PRIMARY_PHONE_NUMBER extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  primary_phone_number_data: DataReq__SB_Claim_Primary_Phone_Number;
}

export class SportsBook_OPERATION__34__CONFIRM_PRIMARY_PHONE_NUMBER extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  confirm_primary_phone_number_data: DataReq__SB_Confirm_Primary_Phone_Number;
}

export class SportsBook_OPERATION__36__REQUEST_WITHDRAW extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  withdraw_request_data: DataReq__SB_WithdrawRequest;
}

export class SportsBook_OPERATION__37__EXECUTE_XPOINTS_OFFER extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  execute_xpoints_offer_data: DataReq__SB_XP_Exchange;
}

export class SportsBook_OPERATION__38__INITIATE_PERSONA_VERIFICATION extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  initiate_verification_data: DataReq__SB_Initiate_Persona_Verification;
}

export class SportsBook_OPERATION__48__V2_COMPLETE_USER_PROFILE extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  complete_profile_data: DataReq__SB_v2_Complete_Profile_Data;
}

export class SportsBook_OPERATION__39__CANCEL_WITHDRAW extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  cancel_withdraw_data: DataReq__SB_cancel_withdrawal_data;
}

export class SportsBook_OPERATION__42__UPDATE_PERSONAL_DATA extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  personal_data: DataReq__SB_Update_Personal_Data;
}

export class SportsBook_OPERATION__43__RATE_US_DATA extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  rateus_data: DataReq__SB_RateUsInfo;
}

export class SportsBook_OPERATION__50__PARTIALLY_VALID_USER__SYNC_ON_LOGIN extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  feed_meta: IFeedMeta;
  rateus_data: DataReq__SB_RateUsInfo;
}

export class SportsBook_OPERATION__46__CREATE_ORDER_FOR_SLIDE_GIFT_CARD extends SportsBookOperation {
  profile_meta: DataReq__SB_Profile_Meta;
  purchase_v2_order_data: DataReq__SB_Order_v2_Slide_Gift_Card;
}

export class SportsBook_OPERATION__59__SYNC_PUBLIC_CHALLENGES_REPORT extends SportsBookOperation {
  report_server_period: DataReq__SB_ServerControlledPeriod;
}

export class SportsBook_OPERATION__61__PUBLIC_VALIDATE_REGISTRATION_DATA extends SportsBookOperation {
  validate_register_data: DataReq__SB_Validate_Register;
}

export class SportsBook_OPERATION__70__PUBLIC_SLIDE_CARD_BRANDS extends SportsBookOperation {}

export class SportsBook_OPERATION__81__SYNC_FEED_FOR_PROPOSALS extends SportsBookOperation {
  sync_feed_for_proposals_request_data: DataReq__SB_Sync_Feed_For_Proposals_Request;
}

export class SportsBookOperationFactory {
  public static debug_info(request: SportsBookRequest): string {
    if (!request.operation) {
      return "uknown - (!request.operation)";
    }

    const operation: SportsBookOperation = request.operation;

    const res = "" + operation.code + "/";
    switch (operation.code) {
      case OPERATION__1__SYNC_RAW:
        return res + "sync";
      case OPERATION__3__SYNC_LOAD_REWARDS_CASH_REPORT:
        return res + "load_rewards_cash_report";
      case OPERATION__5__SYNC_LOAD_PICKS_REPORT:
        return res + "load_picks_report";
      case OPERATION__6__SYNC_LOAD_LOGROS_REPORT:
        return res + "load_logros_report";
      case OPERATION__7__SYNC_LOAD_GOLD_COINS_LEADERBOARD_REPORT:
        return res + "load_gold_coins_leaderboard_report";
      case OPERATION__9__SYNC_PUBLIC_CHALLENGES_REPORT:
        return res + "load_public_challenges_report";
      case OPERATION__10__SYNC_LOAD_POINTS_LEADERBOARD_REPORT:
        return res + "load_points_leaderboard_report";
      case OPERATION__11__SYNC_LOAD_POINTS_TRANSACTIONS_REPORT:
        return res + "load_points_transactions";
      case OPERATION__12__SYNC_LOAD_SKILL_COEFF_REPORT:
        return res + "load_skill_coeff_report";
      case OPERATION__13__SYNC_LOAD_ACTIVITY_FEED:
        return res + "load_activity_feed";
      case OPERATION__21__PLACE_PICK:
        return res + "place_pick";
      case OPERATION__31__OBTAIN_COINS_BUNDLE:
        return res + "claim_coins_bundle";
      case OPERATION__32__CLAIM_PRIMARY_EMAIL_ADDRESS:
        return res + "claim_primary_email_address";
      case OPERATION__33__CLAIM_PRIMARY_PHONE_NUMBER:
        return res + "claim_primary_phone_number";
      case OPERATION__34__CONFIRM_PRIMARY_PHONE_NUMBER:
        return res + "confirm_primary_phone_number";
      case OPERATION__36__REQUEST_WITHDRAW:
        return res + "request_withdraw";
      case OPERATION__37__EXECUTE_XPOINTS_OFFER:
        return res + "execute_xpoints_offer";
      case OPERATION__38__INITIATE_PERSONA_VERIFICATION:
        return res + "initiate_persona_verify";
      case OPERATION__48__V2_COMPLETE_USER_PROFILE:
        return res + "v2_complete_user_profile";
      case OPERATION__39__CANCEL_WITHDRAW:
        return res + "cancel_withdraw";
      case OPERATION__40__INITIATE_MAZOOMA_PAY_IN:
        return res + "initiate_mazooma_pay_in";
      case OPERATION__41__REQUEST_INVOICE_FOR_IAP:
        return res + "initiate_iap_for_bundle";
      case OPERATION__42__UPDATE_PERSONAL_DATA:
        return res + "save_personal_info";
      case OPERATION__43__RATE_US_DATA:
        return res + "rate_us_data";
      case OPERATION__46__CREATE_ORDER_FOR_SLIDE_GIFT_CARD:
        return res + "create_order_for_slide_gift_card";
      case OPERATION__49__PRIVATE_UPLOAD_RAW_IAP_NATIVE_FEED:
        return res + "upload_private_iap_feed";
      case OPERATION__50__PARTIALLY_VALID_USER__SYNC_ON_LOGIN:
        return res + "sync_on_login";
      case OPERATION__57__PUBLIC_LOAD_GOLD_COINS_LEADERBOARD_REPORT:
        return res + "load_gold_coins_leaderboard_report";
      case OPERATION__58__V3_UNIVERSAL_FEED:
        return res + "universal_feed";
      case OPERATION__59__SYNC_PUBLIC_CHALLENGES_REPORT:
        return res + "load_public_challenges_report";
      case OPERATION__60__PUBLIC_LOAD_POINTS_LEADERBOARD_REPORT:
        return res + "load_points_leaderboard_report";
      case OPERATION__61__PUBLIC_VALIDATE_REGISTRATION_DATA:
        return res + "validate_registration_data";
      case OPERATION__62__PUBLIC_SYNC_LOAD_SKILL_COEFF_REPORT:
        return res + "load_skill_coeff_report";
      case OPERATION__70__PUBLIC_SLIDE_CARD_BRANDS:
        return res + "load_slide_card_brands";
      case OPERATION__80__PUBLIC_LOAD_ACTIVITY_FEED:
        return res + "load_activity_feed";
      case OPERATION__81__SYNC_FEED_FOR_PROPOSALS:
        return res + "sync_feed_for_proposals";

      default:
        return res + "unknown";
    }
  }
}

export class SportsBookRequest extends XCommonServerRequest {
  operation: SportsBookOperation;
  load_data_requests: string[];
  load_data_requests_on_error: string[];
}

export class SportsBookRequestFactory {
  public static createCommonHeader(): XCommonServerRequestHeader {
    return {
      api_version: 1,
      os: DeviceInfo.OS,
      version: DevConstants.appVersion,
      build: DevConstants.capabilityNumber,
      lang: "en",
      install_token: PersistentStorage.cachedInstallToken,
      device_id: PersistentStorage.cachedDeviceId,
      usa_state_code: CommonLocationManager.lastSetRegionCode,
      usa_state_code_source: CommonLocationManager.debugSourceInfo,
      platform: DevConstants.isDevMode ? "dev" : "prod",
    };
  }

  public static createSportsBookRequest_for__OPERATION__50__PARTIALLY_VALID_USER__SYNC_ON_LOGIN(
    data_rateus: DataReq__SB_RateUsInfo,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__50__PARTIALLY_VALID_USER__SYNC_ON_LOGIN =
      {
        code: OPERATION__50__PARTIALLY_VALID_USER__SYNC_ON_LOGIN,
        // 2020-05-18 / Ivan / temporary hack - reload all config on login - especially important for complete profile
        // we should move config_version to caller
        config_version: 0,
        //      sync_data: data,
        feed_meta: SharedCoreUtils.buildFeedMeta(),
        rateus_data: data_rateus,
        profile_meta: SportsBookRequestFactory._buildProfileMeta(),
      };

    const data_requests: string[] = [
      DATA__fliff_account,
      DATA__profile,
      // 2020-08-31 / Ivan / also load challenges_report data on login (needed for dashboard)
      // 2020-08-12 / Ivan / also load rewards report on splash - since it is accessible from home screen tab
      DATA__rewards_cash_report,
      // 2020-08-31 / Ivan / also load challenges_report data on login (needed for dashboard)
      DATA__prematch_feed,
      DATA__inplay_feed,
      DATA__today_challenges_report,
    ];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__1__SYNC_RAW(): SportsBookRequest {
    const operation: SportsBook_OPERATION__1__SYNC_RAW = {
      code: OPERATION__1__SYNC_RAW,
      config_version: IncrementalProtocolVersions.config_version,
      feed_meta: SharedCoreUtils.buildFeedMeta(),
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile, DATA__fliff_account];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__21__PLACE_PICK(
    place_pick_request_data: DataReq__SB_PlacePickRequest,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__21__PLACE_PICK = {
      code: OPERATION__21__PLACE_PICK,
      config_version: IncrementalProtocolVersions.config_version,
      place_pick_request_data: place_pick_request_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [
      DATA__profile,
      // 2020-08-31 / Ivan / also refresh challenges_report data upon place pick (needed for dashboard)
      DATA__today_challenges_report,
    ];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__31__OBTAIN_COINS_BUNDLE(
    bundle_id: number,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__31__OBTAIN_COINS_BUNDLE = {
      code: OPERATION__31__OBTAIN_COINS_BUNDLE,
      config_version: IncrementalProtocolVersions.config_version,
      bundle_id: bundle_id,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__32__CLAIM_PRIMARY_EMAIL_ADDRESS(
    primary_email_address_data: DataReq__SB_Claim_Primary_Email_Address,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__32__CLAIM_PRIMARY_EMAIL_ADDRESS = {
      code: OPERATION__32__CLAIM_PRIMARY_EMAIL_ADDRESS,
      config_version: IncrementalProtocolVersions.config_version,
      primary_email_address_data: primary_email_address_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile, DATA__fliff_account];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__33__CLAIM_PRIMARY_PHONE_NUMBER(
    primary_phone_number_data: DataReq__SB_Claim_Primary_Phone_Number,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__33__CLAIM_PRIMARY_PHONE_NUMBER = {
      code: OPERATION__33__CLAIM_PRIMARY_PHONE_NUMBER,
      config_version: IncrementalProtocolVersions.config_version,
      primary_phone_number_data: primary_phone_number_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile, DATA__fliff_account];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__34__CONFIRM_PRIMARY_PHONE_NUMBER(
    confirm_primary_phone_number_data: DataReq__SB_Confirm_Primary_Phone_Number,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__34__CONFIRM_PRIMARY_PHONE_NUMBER = {
      code: OPERATION__34__CONFIRM_PRIMARY_PHONE_NUMBER,
      config_version: IncrementalProtocolVersions.config_version,
      confirm_primary_phone_number_data: confirm_primary_phone_number_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile, DATA__fliff_account];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__36__REQUEST_WITHDRAW(
    withdraw_request_data: DataReq__SB_WithdrawRequest,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__36__REQUEST_WITHDRAW = {
      code: OPERATION__36__REQUEST_WITHDRAW,
      config_version: IncrementalProtocolVersions.config_version,
      withdraw_request_data: withdraw_request_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__37__EXECUTE_XPOINTS_OFFER(
    execute_xpoints_offer_data: DataReq__SB_XP_Exchange,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__37__EXECUTE_XPOINTS_OFFER = {
      code: OPERATION__37__EXECUTE_XPOINTS_OFFER,
      config_version: IncrementalProtocolVersions.config_version,
      execute_xpoints_offer_data: execute_xpoints_offer_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__38__INITIATE_PERSONA_VERIFICATION(
    initiate_verification_data: DataReq__SB_Initiate_Persona_Verification,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__38__INITIATE_PERSONA_VERIFICATION = {
      code: OPERATION__38__INITIATE_PERSONA_VERIFICATION,
      config_version: IncrementalProtocolVersions.config_version,
      initiate_verification_data: initiate_verification_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__48__V2_COMPLETE_USER_PROFILE(
    complete_profile_data: DataReq__SB_v2_Complete_Profile_Data,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__48__V2_COMPLETE_USER_PROFILE = {
      code: OPERATION__48__V2_COMPLETE_USER_PROFILE,
      config_version: IncrementalProtocolVersions.config_version,
      complete_profile_data: complete_profile_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile, DATA__fliff_account];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__39__CANCEL_WITHDRAW(
    cancel_withdraw_data: DataReq__SB_cancel_withdrawal_data,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__39__CANCEL_WITHDRAW = {
      code: OPERATION__39__CANCEL_WITHDRAW,
      config_version: IncrementalProtocolVersions.config_version,
      cancel_withdraw_data: cancel_withdraw_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile, DATA__fliff_account];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_operation_42__UPDATE_PERSONAL_DATA(
    personal_data: DataReq__SB_Update_Personal_Data,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__42__UPDATE_PERSONAL_DATA = {
      code: OPERATION__42__UPDATE_PERSONAL_DATA,
      config_version: IncrementalProtocolVersions.config_version,
      personal_data: personal_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__43__RATE_US_DATA(
    rateus_data: DataReq__SB_RateUsInfo,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__43__RATE_US_DATA = {
      code: OPERATION__43__RATE_US_DATA,
      config_version: IncrementalProtocolVersions.config_version,
      rateus_data: rateus_data,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static create_for_OPERATION__46__CREATE_ORDER_FOR_SLIDE_GIFT_CARD(
    purchase_v2_order_data: DataReq__SB_Order_v2_Slide_Gift_Card,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__46__CREATE_ORDER_FOR_SLIDE_GIFT_CARD =
      {
        code: OPERATION__46__CREATE_ORDER_FOR_SLIDE_GIFT_CARD,
        config_version: IncrementalProtocolVersions.config_version,
        purchase_v2_order_data: purchase_v2_order_data,
        profile_meta: SportsBookRequestFactory._buildProfileMeta(),
      };

    const data_requests: string[] = [DATA__profile];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static createSportsBookRequest_for__OPERATION__5__SYNC_LOAD_PICKS_REPORT(
    report_client_period: DataReq__SB_Picks_Period_Report,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__5__SYNC_LOAD_PICKS_REPORT = {
      code: OPERATION__5__SYNC_LOAD_PICKS_REPORT,
      config_version: IncrementalProtocolVersions.config_version,
      report_client_period: report_client_period,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [DATA__profile, DATA__x_picks_report];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static createSportsBookRequest_for__OPERATION__9__SYNC_PUBLIC_CHALLENGES_REPORT(
    report_server_period: DataReq__SB_ServerControlledPeriod,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__9__SYNC_PUBLIC_CHALLENGES_REPORT = {
      code: OPERATION__9__SYNC_PUBLIC_CHALLENGES_REPORT,
      config_version: IncrementalProtocolVersions.config_version,
      report_server_period: report_server_period,
      profile_meta: SportsBookRequestFactory._buildProfileMeta(),
    };

    const data_requests: string[] = [
      DATA__profile,
      DATA__public_challenges_report,
    ];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static createSportsBookRequest_for__OPERATION__11__SYNC_LOAD_POINTS_TRANSACTIONS_REPORT(
    report_client_period: DataReq__SB_ClientControlledPeriod,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__11__SYNC_LOAD_POINTS_TRANSACTIONS_REPORT =
      {
        code: OPERATION__11__SYNC_LOAD_POINTS_TRANSACTIONS_REPORT,
        config_version: IncrementalProtocolVersions.config_version,
        report_client_period: report_client_period,
        profile_meta: SportsBookRequestFactory._buildProfileMeta(),
      };

    const data_requests: string[] = [
      DATA__profile,
      DATA__points_transaction_report,
    ];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static createSportsBookRequest_for__OPERATION__59__SYNC_PUBLIC_CHALLENGES_REPORT(
    report_server_period: DataReq__SB_ServerControlledPeriod,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__59__SYNC_PUBLIC_CHALLENGES_REPORT = {
      code: OPERATION__59__SYNC_PUBLIC_CHALLENGES_REPORT,
      config_version: IncrementalProtocolVersions.config_version,
      report_server_period: report_server_period,
    };

    const data_requests: string[] = [DATA__public_challenges_report];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static createSportsBookRequest_for__OPERATION__61__PUBLIC_VALIDATE_REGISTRATION_DATA(
    validate_register_data: DataReq__SB_Validate_Register,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__61__PUBLIC_VALIDATE_REGISTRATION_DATA =
      {
        code: OPERATION__61__PUBLIC_VALIDATE_REGISTRATION_DATA,
        config_version: IncrementalProtocolVersions.config_version,
        validate_register_data: validate_register_data,
      };

    const data_requests: string[] = [];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static createSportsBookRequest_for__OPERATION__70__PUBLIC_SLIDE_CARD_BRANDS(): SportsBookRequest {
    const operation: SportsBook_OPERATION__70__PUBLIC_SLIDE_CARD_BRANDS = {
      code: OPERATION__70__PUBLIC_SLIDE_CARD_BRANDS,
      config_version: IncrementalProtocolVersions.config_version,
    };

    const data_requests: string[] = [DATA__slide_card_brands];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  public static createSportsBookRequest_for__OPERATION__81__SYNC_FEED_FOR_PROPOSALS(
    sync_feed_for_proposals_request_data: DataReq__SB_Sync_Feed_For_Proposals_Request,
  ): SportsBookRequest {
    const operation: SportsBook_OPERATION__81__SYNC_FEED_FOR_PROPOSALS = {
      code: OPERATION__81__SYNC_FEED_FOR_PROPOSALS,
      config_version: IncrementalProtocolVersions.config_version,
      sync_feed_for_proposals_request_data:
        sync_feed_for_proposals_request_data,
    };

    const data_requests: string[] = [];

    return {
      header: SportsBookRequestFactory.createCommonHeader(),
      message_name: SPORTS_BOOK_REQUEST,
      operation: operation,
      load_data_requests: data_requests,
      load_data_requests_on_error: data_requests,
    };
  }

  private static _buildProfileMeta(): DataReq__SB_Profile_Meta {
    // profile_meta
    return {
      //TODO: When Ivan is ready with server fix – remove it. Non-used in app
      // eslint-disable-next-line
      //@ts-ignore
      id_51201: 0,
      id_51202: MemoryStorage.mem_cache__id_51202,
      id_51203: MemoryStorage.mem_cache__id_51203,
      id_51204: MemoryStorage.mem_cache__id_51204,
      id_51206: MemoryStorage.mem_cache__id_51206,
      id_51221: MemoryStorage.mem_cache__id_51221,
      id_51231: MemoryStorage.mem_cache__id_51231,
      id_51232: MemoryStorage.mem_cache__id_51232,
      id_51241: MemoryStorage.mem_cache__id_51241,
      id_51250: MemoryStorage.mem_cache__id_51250,
      id_51251: MemoryStorage.mem_cache__id_51251,
      id_51252: MemoryStorage.mem_cache__id_51252,
      id_51253: MemoryStorage.mem_cache__id_51253,
      id_51254: MemoryStorage.mem_cache__id_51254,
      id_51255: MemoryStorage.mem_cache__id_51255,
    };
  }
}

export class SportsBookOperationResult {
  code: number;
  message: string;

  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): SportsBookOperationResult {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      code: ValidationUtils.assert_positive_integer(
        jsondata.code,
        debugInfo + ".code",
      ),
      message: ValidationUtils.assert_non_empty_string(
        jsondata.message,
        debugInfo + ".message",
      ),
    };
  }
}

export class SportsBookResponse extends XCommonServerResponse {
  operation_result: SportsBookOperationResult;

  config: Data__SB_Config | null = null;

  temp_fliff_account: ServerObj_Temp_Data_For_Complete_Profile | null = null;

  profile: Data__SB_UserProfile | null = null;

  // 2020-12-19 / Ivan / separating feeds
  prematch_conflicts: Data__SB_SportEvent[] | null = null;
  prematch_subfeeds_update: Data__SB_FeedDiff_Shelf_Update[] | null = null;
  inplay_conflicts: Data__SB_SportEvent[] | null = null;
  inplay_subfeeds_update: Data__SB_FeedDiff_Shelf_Update[] | null = null;

  new_order: Data__SB_Unfinished_Order | null = null;

  initiated_withdrawal_response: Data__SB_Initiated_Withdrawal_Response | null =
    null;

  picks_period_report: Data__SB_Pick_PeriodReport | null = null;

  points_transaction_report: Data__SB_Points_Transaction_PeriodReport | null =
    null;

  iap_payments_to_finish: Data__SB_IAP_PaymentsToFinish | null = null;

  public_challenges_report: Data__SB_Public_Challenges_Report | null = null;

  today_challenges_report: Data__SB_Public_Challenges_Report | null = null;

  x_delta_profile: Data__SB_X_Delta_Profile | null = null;
  place_picks_operation_status: Data__SB_v2_Place_Picks_Operation_Status | null =
    null;

  // 2021-02-23 / Ivan / initial support for Slide
  slide_card_brands: Data__Slide_All_Gift_Card_Brands | null = null;

  // 2019-11-18 / Ivan / new protocol allows server to pass back both data (to update state) and error message
  operationError: FliffException | null = null;

  initiated_persona_verification_response: Data__SB_Initiated_Persona_Verification_Response | null =
    null;

  public static from_server_response(
    serverresponse: TAnyAlias,
  ): SportsBookResponse {
    ValidationUtils.assert_non_null_data(serverresponse, "serverresponse");
    ValidationUtils.assert_non_null_data(
      serverresponse.data,
      "serverresponse.data",
    );
    const jsondata = serverresponse.data;
    return SportsBookResponse.decode(jsondata, "data");
  }

  public static decode(
    jsondata: TAnyAlias,
    indebugInfo: string,
  ): SportsBookResponse {
    ValidationUtils.assert_non_null_data(jsondata, indebugInfo);

    const baseres: XCommonServerResponse = XCommonServerResponse.decode(
      jsondata,
      indebugInfo,
    );

    ValidationUtils.assert_non_null_data(
      jsondata.response,
      indebugInfo + ".response",
    );
    const operation_result: SportsBookOperationResult =
      SportsBookOperationResult.decode(
        jsondata.response.operation_result,
        indebugInfo + ".response.operation_result",
      );
    const operation_error: FliffException | null =
      SportsBookResponse.decodeOperationResultError(
        operation_result,
        jsondata.response.errors,
      );

    ValidationUtils.assert_non_null_data(
      jsondata.response.data,
      indebugInfo + ".response.data",
    );
    const rawdata = jsondata.response.data;
    const debugInfo: string = "data";

    let temp_fliff_account: ServerObj_Temp_Data_For_Complete_Profile | null =
      null;
    if (rawdata.temp_fliff_account) {
      temp_fliff_account = ServerObj_Temp_Data_For_Complete_Profile.create(
        rawdata.temp_fliff_account,
      );
    }

    const res: SportsBookResponse = {
      ...baseres,
      config: AdapterHelper.null_or_decode<Data__SB_Config>(
        Adapter__SB_Config.decode,
        rawdata.config,
        debugInfo + ".config",
      ),
      profile: AdapterHelper.null_or_decode<Data__SB_UserProfile>(
        Adapter__SB_UserProfile.decode,
        rawdata.profile,
        debugInfo + ".profile",
      ),
      new_order: AdapterHelper.null_or_decode<Data__SB_Unfinished_Order>(
        Adapter__SB_Unfinished_Order.decode,
        rawdata.new_order,
        debugInfo + ".new_order",
      ),
      prematch_conflicts:
        AdapterHelper.null_or_decode_array<Data__SB_SportEvent>(
          Adapter__SB_SportEvent.decode,
          rawdata.prematch_conflicts,
          debugInfo + ".prematch_conflicts",
        ),

      prematch_subfeeds_update: AdapterHelper.null_or_decode<
        Data__SB_FeedDiff_Shelf_Update[]
      >(
        AdapterFeedDiffUpdate.decode,
        rawdata.prematch_subfeeds_update?.packed_subfeed_updates,
        debugInfo + ".prematch_subfeeds_update",
      ),

      inplay_conflicts: AdapterHelper.null_or_decode_array<Data__SB_SportEvent>(
        Adapter__SB_SportEvent.decode,
        rawdata.inplay_conflicts,
        debugInfo + ".inplay_conflicts",
      ),

      inplay_subfeeds_update: AdapterHelper.null_or_decode<
        Data__SB_FeedDiff_Shelf_Update[]
      >(
        AdapterFeedDiffUpdate.decode,
        rawdata.inplay_subfeeds_update?.packed_subfeed_updates,
        debugInfo + ".inplay_subfeeds_update",
      ),
      picks_period_report:
        AdapterHelper.null_or_decode<Data__SB_Pick_PeriodReport>(
          Adapter__SB_Pick_PeriodReport.decode,
          rawdata.x_picks_report,
          debugInfo + ".x_picks_report",
        ),

      points_transaction_report:
        AdapterHelper.null_or_decode<Data__SB_Points_Transaction_PeriodReport>(
          Adapter__SB_Points_Transaction_PeriodReport.decode,
          rawdata.points_transaction_report,
          debugInfo + ".points_transaction_report",
        ),

      iap_payments_to_finish:
        AdapterHelper.null_or_decode<Data__SB_IAP_PaymentsToFinish>(
          Adapter__SB_IAP_PaymentsToFinish.decode,
          rawdata.iap_payments_to_finish,
          debugInfo + ".iap_payments_to_finish",
        ),

      place_picks_operation_status:
        AdapterHelper.null_or_decode<Data__SB_v2_Place_Picks_Operation_Status>(
          Adapter__SB_v2_Place_Picks_Operation_Status.decode,
          rawdata.place_picks_operation_status,
          debugInfo + ".place_picks_operation_status",
        ),

      public_challenges_report:
        AdapterHelper.null_or_decode<Data__SB_Public_Challenges_Report>(
          Adapter__SB_Public_Challenges_Report.decode,
          rawdata.public_challenges_report,
          debugInfo + ".public_challenges_report",
        ),

      today_challenges_report:
        AdapterHelper.null_or_decode<Data__SB_Public_Challenges_Report>(
          Adapter__SB_Public_Challenges_Report.decode,
          rawdata.today_challenges_report,
          debugInfo + ".today_challenges_report",
        ),

      // 2020-12-28 / Ivan / introduce delta protocol for profile related info
      x_delta_profile: AdapterHelper.null_or_decode<Data__SB_X_Delta_Profile>(
        Adapter__SB_X_Delta_Profile.decode,
        rawdata.x_delta_profile,
        debugInfo + ".x_delta_profile",
      ),

      slide_card_brands:
        AdapterHelper.null_or_decode<Data__Slide_All_Gift_Card_Brands>(
          Adapter__Slide_All_Gift_Card_Brands.decode,
          rawdata.slide_card_brands,
          debugInfo + ".slide_card_brands",
        ),

      initiated_withdrawal_response:
        AdapterHelper.null_or_decode<Data__SB_Initiated_Withdrawal_Response>(
          Adapter__SB_Initiated_Withdrawal_Response.decode,
          rawdata.initiated_withdrawal_response,
          debugInfo + ".initiated_withdrawal_response",
        ),

      initiated_persona_verification_response:
        AdapterHelper.null_or_decode<Data__SB_Initiated_Persona_Verification_Response>(
          Adapter__SB_Initiated_Persona_Verification_Response.decode,
          rawdata.initiated_persona_verification_response,
          debugInfo + ".initiated_persona_verification_response",
        ),

      temp_fliff_account: temp_fliff_account,
      operation_result: operation_result,
      operationError: operation_error,
    };

    SportsBookResponse._postProcessIncrementalProtocolVersions(res);

    return res;
  }

  public static decodeOperationResultError(
    operation_result: TAnyAlias,
    errors: TAnyAlias,
  ): FliffException | null {
    // validate only operation_result.code and .errors
    if (operation_result.code % 1000 === 0) {
      return null;
    }

    return ErrorHelper.operationResultCodeToDDException(
      operation_result.code,
      operation_result.message,
      errors,
    );
  }

  private static _postProcessIncrementalProtocolVersions(
    res: SportsBookResponse,
  ) {
    if (AppUtils.isNullable(res.config)) {
      return;
    }

    if (res.config.obj_version < IncrementalProtocolVersions.config_version) {
      // if new config object is older version - ignore the new incoming config
      // this may happen if we have several parallel requests - the slower one comes with smaller version
      res.config = null;
    } else {
      // we got new config - save the version
      IncrementalProtocolVersions.config_version = res.config.obj_version;
    }
  }
}

export class Hack_DecoderSerializedException {
  public static decode_serialized_operation_error(
    serialized_data: string,
  ): FliffException | null {
    try {
      const PREFIX = "serialized_operation_error:";
      const indebugInfo = "serialized_data";

      if (!serialized_data.startsWith(PREFIX)) {
        return null;
      }

      serialized_data = serialized_data.substring(PREFIX.length).trim();
      console.log("");
      console.log("");
      console.log("serialized_data");
      console.log(serialized_data);
      console.log("");
      console.log("");

      const jsondata = JSON.parse(serialized_data);

      const operation_result: SportsBookOperationResult =
        SportsBookOperationResult.decode(
          jsondata.operation_result,
          indebugInfo + ".operation_result",
        );

      return SportsBookResponse.decodeOperationResultError(
        operation_result,
        jsondata.errors,
      );
    } catch (error) {
      Logger.warnAny(" x in decode_serialized_operation_error", error);
      return null;
    }
  }
}
