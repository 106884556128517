import * as I from "server/core/data/objects";
import ApiCore from "server/core/server/ApiCore";
import CoreApiUrlManager from "server/sharedCore/CoreApiUrlManager";
import { TFliffResult } from "server/sharedCore/data/objects";
import CommonLocationManager from "utils/LocationManagers/Common";
import { DeviceUptimeClock } from "utils/DeviceUptimeClock";
import { FeedSyncCode } from "server/core/data/constants";
import { EnvUtils } from "utils/EnvUtils";
import SharedCoreUtils from "server/sharedCore/SharedCoreUtils";
import { ServerClock } from "utils/ServerClock";
import { TimeUtils } from "utils/TimeUtils";
import { TAnyAlias } from "src/types";

class Public {
  private readonly _localRequestMeta = { isPrivate: false } as const;
  private readonly _proposalsSyncLocalRequestMeta = {
    ...this._localRequestMeta,
    forceAttemptToHerald: true,
  } as const;

  public executeSyncTimeWithServerRequest = async (): Promise<void> => {
    let res1 = await this.executeNonBlockingPingRequest();
    if (res1[0] > 500) {
      // assume that we hit new lambda server, lets try to outsmart it by sending quick second request
      const res2 = await this.executeNonBlockingPingRequest();
      if (res1[0] > res2[0]) {
        res1 = res2;
      }
    }

    if (!ServerClock.isInitialized) {
      // also pass start_device_time_millis
      await ServerClock.init(res1[2], res1[1]);
    }
  };

  public async safeBackgroundPrematchFeedUpdate(): Promise<
    TFliffResult<I.IFCMPublicFeedSyncResponse>
  > {
    return await ApiCore.safeExecuteBackgroundRequest<
      I.IFCMPublicFeedSyncRequest,
      I.IFCMPublicFeedSyncResponse
    >({
      __object_class_name: "FCM__Public_Feed_Sync__Request",
      localMeta: this._proposalsSyncLocalRequestMeta,
      subfeed_meta: SharedCoreUtils.buildFeedMeta(),
      code: FeedSyncCode.CONST_3055_PREMATCH,
    });
  }

  public async safeBackgroundInplayFeedUpdate(): Promise<
    TFliffResult<I.IFCMPublicFeedSyncResponse>
  > {
    return await ApiCore.safeExecuteBackgroundRequest<
      I.IFCMPublicFeedSyncRequest,
      I.IFCMPublicFeedSyncResponse
    >({
      __object_class_name: "FCM__Public_Feed_Sync__Request",
      localMeta: this._proposalsSyncLocalRequestMeta,
      subfeed_meta: SharedCoreUtils.buildFeedMeta(),
      code: FeedSyncCode.CONST_3056_INPLAY,
    });
  }

  public nonBlockingNoErrorModalInitPublicData(): Promise<I.IFCMPublicFeedSyncResponse> {
    return ApiCore.executeNonBlockingNoErrorModalRequest<
      I.IFCMPublicFeedSyncRequest,
      I.IFCMPublicFeedSyncResponse
    >({
      __object_class_name: "FCM__Public_Feed_Sync__Request",
      localMeta: this._proposalsSyncLocalRequestMeta,
      code: FeedSyncCode.CONST_3054_PREMATCH_INPLAY,
      subfeed_meta: SharedCoreUtils.buildFeedMeta(),
    });
  }

  public async safeBlockingSyncPublicDataOnChannelSelect(): Promise<
    TFliffResult<I.IFCMPublicFeedSyncResponse>
  > {
    return await ApiCore.safeExecuteBlockingRequest<
      I.IFCMPublicFeedSyncRequest,
      I.IFCMPublicFeedSyncResponse
    >({
      __object_class_name: "FCM__Public_Feed_Sync__Request",
      localMeta: this._proposalsSyncLocalRequestMeta,
      subfeed_meta: SharedCoreUtils.buildFeedMeta(),
      code: FeedSyncCode.CONST_3062_SELECT_CHANNEL,
    });
  }

  public safeBackgroundLoadEventMarkets(): Promise<
    TFliffResult<I.IFCMPublicFeedSyncResponse>
  > {
    return ApiCore.safeExecuteBackgroundRequest<
      I.IFCMPublicFeedSyncRequest,
      I.IFCMPublicFeedSyncResponse
    >({
      __object_class_name: "FCM__Public_Feed_Sync__Request",
      localMeta: this._proposalsSyncLocalRequestMeta,
      subfeed_meta: SharedCoreUtils.buildFeedMeta(),
      code: FeedSyncCode.CONST_3061_ENTER_MORE_MARKETS,
    });
  }

  public safeNonBlockingNoErrorModalInitPublicData(): Promise<I.IFCMPublicFeedSyncResponse> {
    return ApiCore.executeNonBlockingNoErrorModalRequest<
      I.IFCMPublicFeedSyncRequest,
      I.IFCMPublicFeedSyncResponse
    >({
      __object_class_name: "FCM__Public_Feed_Sync__Request",
      localMeta: this._proposalsSyncLocalRequestMeta,
      code: FeedSyncCode.CONST_3054_PREMATCH_INPLAY,
      subfeed_meta: SharedCoreUtils.buildFeedMeta(),
    });
  }

  public async safeBackgroundPingRequest(): Promise<
    [number, number, number, string, number] | null
  > {
    const startDeviceTimeMillis = TimeUtils.now().valueOf();

    const { response } = await ApiCore.safeExecuteBackgroundRequest<
      I.IFCMPingRequest,
      I.IFCMPingResponse
    >({
      __object_class_name: "FCM__Ping__Request",
      message: `ping ${startDeviceTimeMillis}`,
      localMeta: this._localRequestMeta,
    });

    if (!response) {
      return null;
    }

    return this._processPingResponse(response, startDeviceTimeMillis);
  }

  public async executeNonBlockingPingRequest(): Promise<
    [number, number, number, string, number]
  > {
    const startDeviceTimeMillis = TimeUtils.now().valueOf();

    const response = await ApiCore.executeNonBlockingNoErrorModalRequest<
      I.IFCMPingRequest,
      I.IFCMPingResponse
    >({
      __object_class_name: "FCM__Ping__Request",
      message: `ping ${startDeviceTimeMillis}`,
      localMeta: this._localRequestMeta,
    });

    return this._processPingResponse(response, startDeviceTimeMillis);
  }

  public async safeBlockingRegisterRadarSDKCallBackData(
    radarResponse: TAnyAlias,
  ): Promise<TFliffResult<I.IFCMRegisterRadarSDKCallBackDataResponse>> {
    return await ApiCore.safeExecuteBlockingRequest<
      I.IFCMRegisterRadarSDKCallBackDataRequest,
      I.IFCMRegisterRadarSDKCallBackDataResponse
    >({
      __object_class_name: "FCM__Register_Radar_SDK_CallBack_Data__Request",
      localMeta: this._localRequestMeta,
      raw_sdk_data_encoded_as_string: JSON.stringify(radarResponse),
    });
  }

  private _processPingResponse(
    response: I.IFCMPingResponse,
    startDeviceTimeMillis: number,
  ): [number, number, number, string, number] {
    const startUptimeMillis = DeviceUptimeClock.nativeDeviceUptimeMillis();

    CommonLocationManager.setCachedLocalUSAStatesRestrictions(
      response.data.usa_state_restrictions,
    );
    CommonLocationManager.setLocationVendor(
      response.data.device_location_vendor,
    );

    CoreApiUrlManager.setLastKnownUrls(
      response.data.core_sever_public_endpoint,
      response.data.core_sever_private_endpoint,
      response.data.proposals_feed_endpoint,
    );

    const endUptimeMillis = DeviceUptimeClock.nativeDeviceUptimeMillis();
    const diff = endUptimeMillis - startUptimeMillis;

    return [
      diff,
      startUptimeMillis,
      response.data.server_timestamp,
      response.data.server_instance_info,
      startDeviceTimeMillis,
    ];
  }

  public async safeBackgroundUploadMobileLog(
    loggSnippet: string,
  ): Promise<TFliffResult<I.IFCMUploadMobileLogResponse>> {
    return await ApiCore.safeExecuteBackgroundRequest<
      I.IFCMUploadMobileLogRequest,
      I.IFCMUploadMobileLogResponse
    >({
      __object_class_name: "FCM__Upload_Mobile_Log__Request",
      localMeta: this._localRequestMeta,
      einfo: await EnvUtils.extractEnvData(),
      winfo: loggSnippet,
    });
  }
}

export default new Public();
