import Logger from "utils/Logger";
import { DevConstants } from "src/DevConstants";
import { AppProfileUtils } from "utils/AppProfileUtils";

class CoreApiUrlManager {
  private _nextPublicBaseUrl = "";
  private _nextPrivateBaseUrl = "";
  private _nextHeraldPublicEndpoint = "";
  public get defaultBaseUrl(): string {
    return AppProfileUtils.coreServerBaseUrl;
  }

  public get nextHeraldPublicEndpoint() {
    return this._nextHeraldPublicEndpoint;
  }

  public setLastKnownUrls(
    publicEndpoint: string,
    privateEndpoint: string,
    heraldPublicEndpoint: string,
  ): void {
    if (publicEndpoint !== "default") {
      this._nextPublicBaseUrl = publicEndpoint;
    }
    if (privateEndpoint !== "default") {
      this._nextPrivateBaseUrl = privateEndpoint;
    }
    this._nextHeraldPublicEndpoint = heraldPublicEndpoint;
  }

  public getBaseUrl = (
    prevBaseUrl: string | undefined,
    isPublic: boolean | undefined,
  ): string => {
    if (!prevBaseUrl) {
      Logger.logMessage("getBaseUrl => initialUrl is not defined.");
      return "";
    }
    if (typeof isPublic === "undefined") {
      Logger.logMessage("getBaseUrl => isPublic is not defined.");
      return prevBaseUrl;
    }

    if (isPublic) {
      if (DevConstants.isDevMode) {
        return this._replaceDefaultPublicUrl(prevBaseUrl);
      }
      if (this._nextPublicBaseUrl.length > 0) {
        return this._nextPublicBaseUrl;
      }
      return this._replaceDefaultPublicUrl(prevBaseUrl);
    }

    if (DevConstants.isDevMode) {
      return this._replaceDefaultPrivateUrl(prevBaseUrl);
    }

    if (this._nextPrivateBaseUrl.length > 0) {
      return this._nextPrivateBaseUrl;
    }
    return this._replaceDefaultPrivateUrl(prevBaseUrl);
  };

  private _replaceDefaultPublicUrl(prevBaseUrl: string): string {
    let result = prevBaseUrl;
    result = result.replace("/app.getfliff.com", "/m-c2.app.getfliff.com");
    result = result.replace("/dev.getfliff.com", "/m-c2.dev.getfliff.com");

    return result;
  }

  private _replaceDefaultPrivateUrl(prevBaseUrl: string): string {
    let result = prevBaseUrl;
    result = result.replace("/app.getfliff.com", "/m-c1.app.getfliff.com");
    result = result.replace("/dev.getfliff.com", "/m-c1.dev.getfliff.com");

    return result;
  }
}

export default new CoreApiUrlManager();
